import {classNames} from 'aida-components';
import styles from './table.module.scss';

const Table = ({className, head, children}) => {
  return (
    <table className={classNames(styles.table, className)}>
      {head && <thead className={styles.head}>{head}</thead>}
      <tbody className={styles.body}>{children}</tbody>
    </table>
  );
};

export const Cell = ({className, children, align = 'left', ...rest}) => (
  <td {...rest} className={classNames(styles[align], className)}>
    {children}
  </td>
);

export default Table;
