/*eslint-disable*/
import {
  Avatar,
  Button,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  DotLoading,
  Dropdown,
  Header,
  Icon,
  IconNames,
  toast,
  useAuthentication,
  useHistory,
  useRequest,
  classNames,
} from 'aida-components';
import styles from './header.module.scss';
import logo from 'images/aia-white.png';
import { useEffect, useMemo, useState } from 'react';
import useURL from 'hooks/useURL';
import { APPEAR_AT, getApprovalPermission, getMenu } from 'helper/routers';
import Notification from 'components/notification';
import moment from 'moment';

const useStyles = createUseStyles((theme) => ({
  header: {
    boxShadow: '0px 1px 5px 0px #ccc',
    position: 'relative',
    zIndex: 1,
  },
  menu: {
    ...theme.typography.h2,
    display: 'flex',
    alignItems: 'center',
    '& > li': {
      // marginLeft: theme.default.size.spacing,
      color: theme.default.color.primary,
      lineHeight: '65px',
      cursor: 'pointer',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  functions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  avatar: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: `2px solid ${theme.default.color.line}`,
    marginLeft: theme.default.size.spacing,
  },
  icon: {
    marginRight: 10,
    '& > svg': {
      width: 30,
      height: 30,
    },
  },
  logo: {
    width: 150,
    textAlign: 'center',
    '&>img': {
      width: 130,
      padding: 10,
      scale: 1,
    },
  },
  dropdownWrapper: {
    minWidth: 150,
    marginRight: 20,
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    paddingBottom: 20,
    borderBottom: `1px dashed ${theme.default.color.line}`,
  },
  functionsList: {
    marginBottom: 20,
    borderBottom: `1px dashed ${theme.default.color.line}`,
    paddingBottom: 10,
    '&>li': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: [10, 0],
      '&:last-child': {
        borderBottom: 'none',
      },
      '&> svg': {
        marginRight: theme.default.size.spacing,
      },
    },
  },
  userInfo: {
    width: 'calc(100% - 80px)',
    marginLeft: theme.default.size.spacing,
    '&>h4': {
      ...theme.typography.h4,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      marginBottom: 5,
    },
    '&>span': {
      display: 'inline-block',
      lineHeight: '16px',
      fontSize: 13,
      fontWeight: 300,
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  // avatar: {
  //   borderLeft: [1, 'solid', theme.default.color.line],
  //   paddingLeft: theme.default.size.spacing,
  //   marginLeft: theme.default.size.spacing,
  // },
}));

const AvatarMenuItem = ({ icon, text, link, onClick }) => {
  const history = useHistory();
  const { authUserInfo } = useAuthentication();
  const onItemClick = () => {
    onClick && onClick();
    history.push(link.replace(':type', authUserInfo.userType));
  };
  return <li onClick={onItemClick} >
    <Icon name={icon} /> <span>{text}</span>
  </li>;
};
const HeadIcon = ({ icon, link }) => {
  const history = useHistory();
  const { authUserInfo } = useAuthentication();
  const classes = useStyles();
  const onClick = () => history.push(link.replace(':type', authUserInfo.userType));
  return <Button
    className={classes.icon}
    type={BUTTON_TYPE.ICON}
    icon={<Icon name={icon} />}
    onClick={onClick}
  />;
};

const HeaderComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { authUserInfo, setAuthUserInfo } = useAuthentication();

  const headerMenus = useMemo(
    () => getMenu(authUserInfo.role, APPEAR_AT.HEADER),
    [],
  );
  const avatarMenus = useMemo(
    () => getMenu(authUserInfo.role, APPEAR_AT.AVATAR_MENU),
    [],
  );

  const API_URLS = useURL();
  //
  const [{ status, response }, makeRequest, { FETCHING, SUCCESS, ERROR }] =
    useRequest(API_URLS.LOGOUT);

  const onMenuClick = () => setIsDropdownOpened(false);

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success('See you again!');
      setAuthUserInfo();
      history.push('/failure');
    } else if (status === ERROR) {
      if (response.status === 404) {
        toast.error('Can\'t connect to the server!');
      } else if (response.data) {
        toast.error(response.data && response.data.header.message);
      } else {
        toast.error(response);
      }
      setAuthUserInfo();
      history.push('/failure');
    }
  }, [status, response]);

  return (
    <Header className={classNames(styles.header, styles.doctor)}>
      {/* <h2 className={styles.title}>
        <span title='Logo'>
          <img src={logo} alt='AIA Logo'/>
        </span>
      </h2> */}

      <div className={styles.menus}>
        <span className={styles.active}>Pre Authorization</span>
      </div>
    </Header>
  );
};

export default HeaderComponent;
