import {Card, createUseStyles, Grid, GridItem, useTheme} from 'aida-components';
import {Fragment} from 'react';
import {UserTypeConfig} from 'helper/user-type-config';
import LabelValue from 'components/label-value';
import {useStyles} from 'views/claims/styles';
import {convertDateString, DBDateFormat, roundNumber, UIOutputFormat} from 'helper/utils';

const useLocalStyles = createUseStyles({
  ...useStyles,
  value: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '65%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
});

const leftItemsList = ({userType, type, reqType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const CLAIM_TYPE_LEFT_LIST = {
    IGL: [
      {
        label: 'Case Number',
        id: CLAIM_360_IDS.CLAIM_NUMBER,
        isMandatory: false,
      }, {
        label: 'Request Type',
        id: CLAIM_360_IDS.SUBMISSION_TYPE,
        isMandatory: false,
      }, {
        label: 'Provider Name',
        id: CLAIM_360_IDS.PROVIDER_NAME,
      }, {
        label: 'MRN Number',
        id: CLAIM_360_IDS.MRN_NUMBER,
        isMandatory: false,
      }, {
        label: 'Admission Date',
        id: CLAIM_360_IDS.ADMISSION_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.ADMISSION_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        label: 'Length of Stay',
        id: CLAIM_360_IDS.ADMISSION_DURATION,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{roundNumber({
          number: data[CLAIM_360_IDS.ADMISSION_DURATION],
          roundTo: 2,
        })}</span>,
      }, {
        label: 'Admitting Doctor',
        id: CLAIM_360_IDS.PRINCIPAL_DOCTOR,
      }, {
        label: 'ePAF Filled by MO?',
        id: CLAIM_360_IDS.EPAF_FILLED,
      }, {
        label: 'Diagnosis 1',
        id: CLAIM_360_IDS.DIAGNOSIS_1,
      }, {
        label: 'Diagnosis 2',
        id: CLAIM_360_IDS.DIAGNOSIS_2,
      }, {
        label: 'Diagnosis 3',
        id: CLAIM_360_IDS.DIAGNOSIS_3,
      }, {
        label: 'Diagnosis 4',
        id: CLAIM_360_IDS.DIAGNOSIS_4,
      }, {
        label: 'Diagnosis 5',
        id: CLAIM_360_IDS.DIAGNOSIS_5,
      }, {
        label: 'Is this accident?',
        id: CLAIM_360_IDS.ACCIDENT_FLAG,
      }, {
        label: 'Is this emergency?',
        id: CLAIM_360_IDS.EMERGENCY_FLAG,
        isMandatory: false,
      },
    ],
    AGL: {
      ADDITIONAL: [
        {
          label: 'Case Number',
          id: CLAIM_360_IDS.CLAIM_NUMBER,
          isMandatory: false,
        }, {
          label: 'Request Type',
          id: CLAIM_360_IDS.SUBMISSION_TYPE,
          isMandatory: false,
        }, {
          label: 'Provider Name',
          id: CLAIM_360_IDS.PROVIDER_NAME,
        }, {
          label: 'Admission Date',
          id: CLAIM_360_IDS.ADMISSION_DATE,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
            data[CLAIM_360_IDS.ADMISSION_DATE],
            DBDateFormat,
            UIOutputFormat,
          )}</span>,
        }, {
          label: 'Length of Stay',
          id: CLAIM_360_IDS.ADMISSION_DURATION,
          valueDisplay: ({length_of_admission}) => <span className={classes.customDisplayValue}>{roundNumber({
            number: length_of_admission,
            roundTo: 2,
          })}</span>,
        }, {
          label: 'Admitting Doctor',
          id: CLAIM_360_IDS.PRINCIPAL_DOCTOR,
        }, {
          label: 'ePAF Filled by MO?',
          id: CLAIM_360_IDS.EPAF_FILLED,
        }, {
          label: 'Diagnosis 1',
          id: CLAIM_360_IDS.DIAGNOSIS_1,
        }, {
          label: 'Diagnosis 2',
          id: CLAIM_360_IDS.DIAGNOSIS_2,
        }, {
          label: 'Diagnosis 3',
          id: CLAIM_360_IDS.DIAGNOSIS_3,
        }, {
          label: 'Diagnosis 4',
          id: CLAIM_360_IDS.DIAGNOSIS_4,
        }, {
          label: 'Diagnosis 5',
          id: CLAIM_360_IDS.DIAGNOSIS_5,
        }, {
          label: 'Is this accident?',
          id: CLAIM_360_IDS.ACCIDENT_FLAG,
        }, {
          label: 'Is this emergency?',
          id: CLAIM_360_IDS.EMERGENCY_FLAG,
          isMandatory: false,
        }, {
          label: 'Current Request Type',
          id: CLAIM_360_IDS.ADD_REQ_TYPE,
        }, {
          label: 'Additional Doctor 1',
          id: CLAIM_360_IDS.ADD_DOCTOR_1,
        }, {
          label: 'Additional Doctor 2',
          id: CLAIM_360_IDS.ADD_DOCTOR_2,
        }, {
          label: 'Additional Doctor 3',
          id: CLAIM_360_IDS.ADD_DOCTOR_3,
        }, {
          label: 'Additional Doctor 4',
          id: CLAIM_360_IDS.ADD_DOCTOR_4,
        }, {
          label: 'Additional Doctor 5',
          id: CLAIM_360_IDS.ADD_DOCTOR_5,
        }, {
          label: 'AGL Amount Req (RM)',
          id: CLAIM_360_IDS.AGL_REQ_AMT,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{
            roundNumber({
              number: data[CLAIM_360_IDS.AGL_REQ_AMT],
              isFormatNumber: true,
              isCheckEmpty: true,
            })}</span>,
        },
      ],
      TOP_UP: [
        {
          label: 'Case Number',
          id: CLAIM_360_IDS.CLAIM_NUMBER,
          isMandatory: false,
        }, {
          label: 'Request Type',
          id: CLAIM_360_IDS.SUBMISSION_TYPE,
          isMandatory: false,
        }, {
          label: 'Provider Name',
          id: CLAIM_360_IDS.PROVIDER_NAME,
        }, {
          label: 'Admission Date',
          id: CLAIM_360_IDS.ADMISSION_DATE,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
            data[CLAIM_360_IDS.ADMISSION_DATE],
            DBDateFormat,
            UIOutputFormat,
          )}</span>,
        }, {
          label: 'Length of Stay',
          id: CLAIM_360_IDS.ADMISSION_DURATION,
          valueDisplay: ({length_of_admission}) => <span className={classes.customDisplayValue}>{roundNumber({
            number: length_of_admission,
            roundTo: 2,
          })}</span>,
        }, {
          label: 'Admitting Doctor',
          id: CLAIM_360_IDS.PRINCIPAL_DOCTOR,
        }, {
          label: 'ePAF Filled by MO?',
          id: CLAIM_360_IDS.EPAF_FILLED,
        }, {
          label: 'Diagnosis 1',
          id: CLAIM_360_IDS.DIAGNOSIS_1,
        }, {
          label: 'Diagnosis 2',
          id: CLAIM_360_IDS.DIAGNOSIS_2,
        }, {
          label: 'Diagnosis 3',
          id: CLAIM_360_IDS.DIAGNOSIS_3,
        }, {
          label: 'Is this accident?',
          id: CLAIM_360_IDS.ACCIDENT_FLAG,
        }, {
          label: 'Top-up Reason',
          id: CLAIM_360_IDS.TOP_UP,
        }, {
          label: 'Is this emergency?',
          id: CLAIM_360_IDS.EMERGENCY_FLAG,
          isMandatory: false,
        }, {
          label: 'Additional Doctor 1',
          id: CLAIM_360_IDS.ADD_DOCTOR_1,
        }, {
          label: 'Additional Doctor 2',
          id: CLAIM_360_IDS.ADD_DOCTOR_2,
        }, {
          label: 'Additional Doctor 3',
          id: CLAIM_360_IDS.ADD_DOCTOR_3,
        }, {
          label: 'Additional Doctor 4',
          id: CLAIM_360_IDS.ADD_DOCTOR_4,
        }, {
          label: 'Additional Doctor 5',
          id: CLAIM_360_IDS.ADD_DOCTOR_5,
        }, {
          label: 'AGL Amount Req (RM)',
          id: CLAIM_360_IDS.AGL_REQ_AMT,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{
            roundNumber({
              number: data[CLAIM_360_IDS.AGL_REQ_AMT],
              isFormatNumber: true,
              isCheckEmpty: true,
            })}</span>,
        },
      ],
    },
    FGL: [
      {
        label: 'Case Number',
        id: CLAIM_360_IDS.CLAIM_NUMBER,
        isMandatory: false,
      }, {
        label: 'Request Type',
        id: CLAIM_360_IDS.SUBMISSION_TYPE,
        isMandatory: false,
      }, {
        label: 'Provider Name',
        id: CLAIM_360_IDS.PROVIDER_NAME,
      }, {
        label: 'MRN Number',
        id: CLAIM_360_IDS.MRN_NUMBER,
        isMandatory: false,
      }, {
        label: 'Admission Date',
        id: CLAIM_360_IDS.ADMISSION_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.ADMISSION_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        label: 'Length of Stay',
        id: CLAIM_360_IDS.ADMISSION_DURATION,
        valueDisplay: ({length_of_admission}) => <span className={classes.customDisplayValue}>{roundNumber({
          number: length_of_admission,
          roundTo: 2,
        })}</span>,
      }, {
        label: 'Admitting Doctor',
        id: CLAIM_360_IDS.PRINCIPAL_DOCTOR,
      }, {
        label: 'ePAF Filled by MO?',
        id: CLAIM_360_IDS.EPAF_FILLED,
      }, {
        label: 'Diagnosis 1',
        id: CLAIM_360_IDS.DIAGNOSIS_1,
      }, {
        label: 'Diagnosis 2',
        id: CLAIM_360_IDS.DIAGNOSIS_2,
      }, {
        label: 'Diagnosis 3',
        id: CLAIM_360_IDS.DIAGNOSIS_3,
      }, {
        label: 'Diagnosis 4',
        id: CLAIM_360_IDS.DIAGNOSIS_4,
      }, {
        label: 'Diagnosis 5',
        id: CLAIM_360_IDS.DIAGNOSIS_5,
      }, {
        label: 'Is this accident?',
        id: CLAIM_360_IDS.ACCIDENT_FLAG,
      }, {
        label: 'Is this emergency?',
        id: CLAIM_360_IDS.EMERGENCY_FLAG,
        isMandatory: false,
      }, {
        label: 'Additional Doctor 1',
        id: CLAIM_360_IDS.ADD_DOCTOR_1,
      }, {
        label: 'Additional Doctor 2',
        id: CLAIM_360_IDS.ADD_DOCTOR_2,
      }, {
        label: 'Additional Doctor 3',
        id: CLAIM_360_IDS.ADD_DOCTOR_3,
      }, {
        label: 'Additional Doctor 4',
        id: CLAIM_360_IDS.ADD_DOCTOR_4,
      }, {
        label: 'Additional Doctor 5',
        id: CLAIM_360_IDS.ADD_DOCTOR_5,
      }, {
        label: 'Final Discharge Date',
        id: CLAIM_360_IDS.DISCHARGE_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.DISCHARGE_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        label: 'Final Bill Number',
        id: CLAIM_360_IDS.BILL_NO,
        isMandatory: false,
      },
    ],
  };

  if (type ==='AGL') {
    if (reqType === 'IP-ADDITIONAL') {
      return CLAIM_TYPE_LEFT_LIST[type]['ADDITIONAL'];
    } else {
      return CLAIM_TYPE_LEFT_LIST[type]['TOP_UP'];
    }
  } else {
    return CLAIM_TYPE_LEFT_LIST[type];
  }
};

const LeftItems = ({data, type, reqType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});
  const userType = 'retail';

  const lListItems = leftItemsList({userType, type, reqType, classes});

  return (
    <Fragment>
      <ul className={classes.listWrapper}>
        {lListItems.map((i, index) => <LabelValue
          key={`${i.label}-key-${index}`}
          item={i}
          data={data}
        />)}
      </ul>
    </Fragment>
  );
};

const rightItemsList = ({userType, type, reqType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const CLAIM_TYPE_RIGHT_LIST = {
    IGL: [
      {
        label: 'GL Number',
        id: CLAIM_360_IDS.GL_NUMBER,
      }, {
        label: 'Admission Type',
        id: CLAIM_360_IDS.EVENT_TYPE,
      }, {
        label: 'Hospital Tier',
        id: CLAIM_360_IDS.HOSPITAL_TIER,
      }, {
        label: 'Visit ID / Reg No',
        id: CLAIM_360_IDS.REG_NO,
        isMandatory: false,
      }, {
        label: 'Est Discharge Date',
        id: CLAIM_360_IDS.EST_DISCHARGE_DATE,
        isMandatory: false,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.EST_DISCHARGE_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        label: 'Room Type',
        id: CLAIM_360_IDS.WARD_TYPE,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.SPECIALITY,
        isMandatory: false,
      }, {
        label: 'MO\'s Name',
        id: CLAIM_360_IDS.MEDICAL_OFFICER,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_1,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_2,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_3,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_4,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_5,
      }, {
        label: 'Accident Details',
        id: CLAIM_360_IDS.ACCIDENT_DETAILS,
      }, {
        label: 'IGL Amount Req (RM)',
        id: CLAIM_360_IDS.IGL_REQ_AMT,
        isMandatory: false,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.IGL_REQ_AMT],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      },
    ],
    AGL: {
      ADDITIONAL: [
        {
          label: 'GL Number',
          id: CLAIM_360_IDS.GL_NUMBER,
        }, {
          label: 'Admission Type',
          id: CLAIM_360_IDS.EVENT_TYPE,
        }, {
          label: 'Hospital Tier',
          id: CLAIM_360_IDS.HOSPITAL_TIER,
        }, {
          label: 'Est Discharge Date',
          id: CLAIM_360_IDS.EST_DISCHARGE_DATE,
          isMandatory: false,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
            data[CLAIM_360_IDS.EST_DISCHARGE_DATE],
            DBDateFormat,
            UIOutputFormat,
          )}</span>,
        }, {
          isBreak: true,
        }, {
          isBreak: true,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.SPECIALITY,
          isMandatory: false,
        }, {
          label: 'MO\'s Name',
          id: CLAIM_360_IDS.MEDICAL_OFFICER,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_1,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_2,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_3,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_4,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_5,
        }, {
          label: 'Accident Details',
          id: CLAIM_360_IDS.ACCIDENT_DETAILS,
        }, {
          label: 'IGL Amount Req (RM)',
          id: CLAIM_360_IDS.IGL_REQ_AMT,
          isMandatory: false,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{
            roundNumber({
              number: data[CLAIM_360_IDS.IGL_REQ_AMT],
              isFormatNumber: true,
              isCheckEmpty: true,
            })}</span>,
        }, {
          label: 'Request Date',
          id: CLAIM_360_IDS.ADD_REQ_DATE,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
            data[CLAIM_360_IDS.ADD_REQ_DATE],
            DBDateFormat,
            UIOutputFormat,
          )}</span>,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_1,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_2,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_3,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_4,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_5,
          isMandatory: false,
        },
      ],
      TOP_UP: [
        {
          label: 'GL Number',
          id: CLAIM_360_IDS.GL_NUMBER,
        }, {
          label: 'Admission Type',
          id: CLAIM_360_IDS.EVENT_TYPE,
        }, {
          label: 'Hospital Tier',
          id: CLAIM_360_IDS.HOSPITAL_TIER,
        }, {
          label: 'Est Discharge Date',
          id: CLAIM_360_IDS.EST_DISCHARGE_DATE,
          isMandatory: false,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
            data[CLAIM_360_IDS.EST_DISCHARGE_DATE],
            DBDateFormat,
            UIOutputFormat,
          )}</span>,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.SPECIALITY,
          isMandatory: false,
        }, {
          label: 'MO\'s Name',
          id: CLAIM_360_IDS.MEDICAL_OFFICER,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_1,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_2,
        }, {
          label: 'Cause & Pathology',
          id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_3,
        }, {
          label: 'Accident Details',
          id: CLAIM_360_IDS.ACCIDENT_DETAILS,
        }, {
          isBreak: true,
        }, {
          isBreak: true,
        }, {
          label: 'IGL Amount Req (RM)',
          id: CLAIM_360_IDS.IGL_REQ_AMT,
          isMandatory: false,
          valueDisplay: (data) => <span className={classes.customDisplayValue}>{
            roundNumber({
              number: data[CLAIM_360_IDS.IGL_REQ_AMT],
              isFormatNumber: true,
              isCheckEmpty: true,
            })}</span>,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_1,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_2,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_3,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_4,
          isMandatory: false,
        }, {
          label: 'Doctor\'s Speciality',
          id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_5,
          isMandatory: false,
        },
      ],
    },
    FGL: [
      {
        label: 'GL Number',
        id: CLAIM_360_IDS.GL_NUMBER,
      }, {
        label: 'Admission Type',
        id: CLAIM_360_IDS.EVENT_TYPE,
      }, {
        label: 'Hospital Tier',
        id: CLAIM_360_IDS.HOSPITAL_TIER,
      }, {
        label: 'Visit ID / Reg No',
        id: CLAIM_360_IDS.REG_NO,
        isMandatory: false,
      }, {
        label: 'Discharge Date',
        id: CLAIM_360_IDS.EST_DISCHARGE_DATE,
        isMandatory: false,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.EST_DISCHARGE_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.SPECIALITY,
        isMandatory: false,
      }, {
        label: 'MO\'s Name',
        id: CLAIM_360_IDS.MEDICAL_OFFICER,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_1,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_2,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_3,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_4,
      }, {
        label: 'Cause & Pathology',
        id: CLAIM_360_IDS.CAUSE_AND_PATHOLOGY_5,
      }, {
        label: 'Accident Details',
        id: CLAIM_360_IDS.ACCIDENT_DETAILS,
      }, {
        label: 'AGL Amount Req (RM)',
        id: CLAIM_360_IDS.AGL_REQ_AMT,
        isMandatory: false,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.AGL_REQ_AMT],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_1,
        isMandatory: false,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_2,
        isMandatory: false,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_3,
        isMandatory: false,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_4,
        isMandatory: false,
      }, {
        label: 'Doctor\'s Speciality',
        id: CLAIM_360_IDS.ADD_DOCTOR_SPECIALITY_5,
        isMandatory: false,
      }, {
        label: 'Next Follow-up Date',
        id: CLAIM_360_IDS.FOLLOW_UP_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.FOLLOW_UP_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      }, {
        label: 'Final Bill Amount (RM)',
        id: CLAIM_360_IDS.INCURRED_AMT,
        isMandatory: false,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.INCURRED_AMT],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      },
    ],
  };

  if (type ==='AGL') {
    if (reqType === 'IP-ADDITIONAL') {
      return CLAIM_TYPE_RIGHT_LIST[type]['ADDITIONAL'];
    } else {
      return CLAIM_TYPE_RIGHT_LIST[type]['TOP_UP'];
    }
  } else {
    return CLAIM_TYPE_RIGHT_LIST[type];
  }
};

const RightItems = ({data, type, reqType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const userType = 'retail';

  const rListItems = rightItemsList({userType, type, reqType, classes});

  return (
    <Fragment>
      <ul className={classes.listWrapper}>
        {rListItems.map((i, index) => <LabelValue
          key={`${i.label}-key-${index}`}
          item={i}
          data={data}
        />)}
      </ul>
    </Fragment>
  );
};

const CaseDetails = ({claimDetails, glType, requestType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  return (
    <Card className={classes.space}>
      <h3 className={classes.cardTitle}>Case Details</h3>
      <div className={classes.policyAndClaimDetails}>
        <Grid gridArea={['c1 c1 c1 c1 c1 c1 c2 c2 c2 c2 c2 c2']}>
          <GridItem
            name='c1'
            component={<div><LeftItems data={claimDetails} type={glType} reqType={requestType}/></div>}
          />
          <GridItem
            name='c2'
            component={<div><RightItems data={claimDetails} type={glType} reqType={requestType}/></div>}
          />
        </Grid>
      </div>
    </Card>
  );
};

export default CaseDetails;
