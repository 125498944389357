import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  Dropdown,
  FilterView,
  Icon,
  IconNames,
  Input,
  INPUT_TYPE,
  Pagination,
  Table,
  toast,
  useAuthentication,
  useTheme,
} from 'aida-components';
import {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import Filter from 'components/filter';
import {DEFAULT_ITEMS_PER_PAGE, OPTIONS_TYPE} from 'helper/constants';
import {UserTypeConfig} from 'helper/user-type-config';
import {checkEmptyString, formatDateDisplay, splitByDelimiter, roundNumber, UIOutputFormat, convertDateString, DateFormat} from 'helper/utils';
import {useAppStyles} from 'helper/app-styles';
import useURL from 'hooks/useURL';
import Search from 'views/claims/claims-listing/search';
import DownloadCSV from 'components/download-csv';
import useRequest from 'hooks/useRequest';
import {CLAIM_LISTING_PREDICTIVE_SUMMARY} from 'views/temp-data';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import {getEditPermission, VIEWS} from 'helper/routers';
import FlagText from 'components/label-value/flag-text';
import FlagColumnText from 'components/flag-text';

const useStyles = createUseStyles({
  ...useAppStyles,
  topContainer: {
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardContainer: ({maxHeight}) => ({
    maxHeight,
  }),
  headDisplay: {
    cursor: 'pointer',
    '&>svg': {
      marginLeft: 5,
      fill: '#2684ff',
    },
  },
  textRed: {
    color: '#F00',
  },
});

const HeadDisplay = ({
  classes,
  title,
  id,
  isSorted,
  isAscending,
  setSortBy,
  setIsAscending,
}) => {
  const onHeadClick = () => {
    setSortBy(id);
    isSorted && setIsAscending(!isAscending);
  };

  return (
    <span className={classes.headDisplay} onClick={onHeadClick}>
      {title}
      {isSorted && (
        <Icon name={isAscending ? IconNames.UpArrow : IconNames.DownArrow} />
      )}
    </span>
  );
};


const getHeads = ({
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  classes,
  userType,
  isProvideLinkToClaim,
}) => {
  const CLAIMS_LISTING_IDS =
    UserTypeConfig['RETAIL']['DATA_IDS']['CLAIMS_LISTING'];

  return [
    {
      title: 'Pre-Auth Received Date',
      id: CLAIMS_LISTING_IDS.RECEIVED_DATE,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 100,
      headDisplay: () => (
        <HeadDisplay
          title='Pre-Auth Received Date'
          id={CLAIMS_LISTING_IDS.RECEIVED_DATE}
          isSorted={sortBy === CLAIMS_LISTING_IDS.RECEIVED_DATE}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <div className={classes.lstingRow}>
          {checkEmptyString(
            record && record[CLAIMS_LISTING_IDS.RECEIVED_DATE] &&
            convertDateString(record[CLAIMS_LISTING_IDS.RECEIVED_DATE], DateFormat, UIOutputFormat),
          )}
        </div>
      ),
    },
    {
      title: 'Pre Auth Ref No',
      id: CLAIMS_LISTING_IDS.CLAIM_NUMBER,
      align: 'center',
      headClass: classes.listingHead,
      headDisplay: () => (
        <HeadDisplay
          title='Pre Auth Ref No'
          id={CLAIMS_LISTING_IDS.CLAIM_NUMBER}
          isSorted={sortBy === CLAIMS_LISTING_IDS.CLAIM_NUMBER}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      width: 150,
      display: (record) =>
        isProvideLinkToClaim ? (
          <Button
            text={checkEmptyString(record[CLAIMS_LISTING_IDS.CLAIM_NUMBER])}
            className={classes.listingRow}
            type={BUTTON_TYPE.LINK}
            size={BUTTON_SIZE.SMALL}
            href={`/claims/${record[CLAIMS_LISTING_IDS.CLAIM_NUMBER]}`}
          />
        ) : (
          <div className={classes.listingRow}>
            {checkEmptyString(
              record && record[CLAIMS_LISTING_IDS.CLAIM_NUMBER],
            )}
          </div>
        ),
    },
    {
      title: 'Policy Holder',
      id: CLAIMS_LISTING_IDS.POLICY_HOLDER,
      align: 'center',
      headClass: classes.listingHead,
      headDisplay: () => (
        <HeadDisplay
          title='Policy Holder'
          id={CLAIMS_LISTING_IDS.POLICY_HOLDER}
          isSorted={sortBy === CLAIMS_LISTING_IDS.POLICY_HOLDER}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      width: 100,
      columnClass: classes.listingRow,
      display: (record) => (
        <div className={classes.listingRow}>
          {checkEmptyString(record && record[CLAIMS_LISTING_IDS.POLICY_HOLDER])}
        </div>
      ),
    },
    {
      title: 'Patient Name',
      id: CLAIMS_LISTING_IDS.PATIENT_NAME,
      align: 'center',
      headClass: classes.listingHead,
      headDisplay: () => (
        <HeadDisplay
          title='Patient Name'
          id={CLAIMS_LISTING_IDS.PATIENT_NAME}
          isSorted={sortBy === CLAIMS_LISTING_IDS.PATIENT_NAME}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      width: 100,
      columnClass: classes.listingRow,
      display: (record) => (
        <div className={classes.listingRow}>
          {checkEmptyString(record && record[CLAIMS_LISTING_IDS.PATIENT_NAME])}
        </div>
      ),
    },
    // {
    //   title: 'Patient DOB',
    //   id: CLAIMS_LISTING_IDS.PATIENT_DOB,
    //   align: 'center',
    //   headClass: classes.listingHead,
    //   headDisplay: () => (
    //     <HeadDisplay
    //       title='Patient DOB'
    //       id={CLAIMS_LISTING_IDS.PATIENT_DOB}
    //       isSorted={sortBy === CLAIMS_LISTING_IDS.PATIENT_DOB}
    //       setSortBy={setSortBy}
    //       isAscending={isAscending}
    //       setIsAscending={setIsAscending}
    //       classes={classes}
    //     />
    //   ),
    //   width: 100,
    //   columnClass: classes.listingRow,
    //   display: (record) => (
    //     <div className={classes.listingRow}>
    //       {checkEmptyString(record && record[CLAIMS_LISTING_IDS.PATIENT_DOB])}
    //     </div>
    //   ),
    // },
    // {
    //   title: 'Patient Gender',
    //   id: CLAIMS_LISTING_IDS.PATIENT_GENDER,
    //   align: 'center',
    //   headClass: classes.listingHead,
    //   headDisplay: () => (
    //     <HeadDisplay
    //       title='Patient Gender'
    //       id={CLAIMS_LISTING_IDS.PATIENT_GENDER}
    //       isSorted={sortBy === CLAIMS_LISTING_IDS.PATIENT_GENDER}
    //       setSortBy={setSortBy}
    //       isAscending={isAscending}
    //       setIsAscending={setIsAscending}
    //       classes={classes}
    //     />
    //   ),
    //   width: 100,
    //   columnClass: classes.listingRow,
    //   display: (record) => (
    //     <div className={classes.listingRow}>
    //       {checkEmptyString(record && record[CLAIMS_LISTING_IDS.PATIENT_GENDER])}
    //     </div>
    //   ),
    // },
    {
      title: 'Policy Number and Name',
      id: CLAIMS_LISTING_IDS.POLICY_NUMBER_NAME,
      headClass: classes.listingHead,
      headDisplay: () => (
        <HeadDisplay
          title='Policy Number and Name'
          id={CLAIMS_LISTING_IDS.POLICY_NUMBER_NAME}
          isSorted={sortBy === CLAIMS_LISTING_IDS.POLICY_NUMBER_NAME}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      width: 250,
      columnClass: classes.listingRow,
      display: (record) => (
        <div className={classes.listingRow}>
          {splitByDelimiter({
            text: record && record[CLAIMS_LISTING_IDS.POLICY_NUMBER_NAME],
          }).map((i, ix) => <div key={`policy-number-name-${ix}`}>{i}</div>)}
        </div>
      ),
    },
    {
      title: 'Diagnosis',
      id: CLAIMS_LISTING_IDS.DIAGNOSIS,
      headClass: classes.listingHead,
      headDisplay: () => (
        <HeadDisplay
          title='Diagnosis'
          id={CLAIMS_LISTING_IDS.DIAGNOSIS}
          isSorted={sortBy === CLAIMS_LISTING_IDS.DIAGNOSIS}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      columnClass: classes.listingRow,
      width: 300,
      display: (record) => (
        <div className={classes.listingRow}>
          {splitByDelimiter({
            text: record && record[CLAIMS_LISTING_IDS.DIAGNOSIS],
          }).map((i, ix) => <div key={`diagnosis-${ix}`}>{i}</div>)}
        </div>
      ),
    },
    {
      title: 'Procedure',
      id: CLAIMS_LISTING_IDS.SURGERY_OR_PROCEDURE,
      headClass: classes.listingHead,
      width: 250,
      headDisplay: () => (
        <HeadDisplay
          title='Procedure'
          id={CLAIMS_LISTING_IDS.SURGERY_OR_PROCEDURE}
          isSorted={sortBy === CLAIMS_LISTING_IDS.SURGERY_OR_PROCEDURE}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      columnClass: classes.listingRow,
      display: (record) => (
        <div className={classes.listingRow}>
          {splitByDelimiter({
            text: record && record[CLAIMS_LISTING_IDS.SURGERY_OR_PROCEDURE],
          }).map((i, ix) => <div key={`procedures-${ix}`}>{i}</div>)}
        </div>
      ),
    },
    {
      title: 'Total Estimated Amt',
      id: CLAIMS_LISTING_IDS.TOTAL_BILL_AMOUNT,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 100,
      headDisplay: () => (
        <HeadDisplay
          title='Total Estimated Amt'
          id={CLAIMS_LISTING_IDS.TOTAL_BILL_AMOUNT}
          isSorted={sortBy === CLAIMS_LISTING_IDS.TOTAL_BILL_AMOUNT}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <div>
          {roundNumber({
            number: record[CLAIMS_LISTING_IDS.TOTAL_BILL_AMOUNT],
            isCheckEmpty: true,
            isFormatNumber: true,
          })}
        </div>
      ),
    },
    {
      title: 'Simple Disease Flag',
      id: CLAIMS_LISTING_IDS.RULE_SIMPLE_DISEASE,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 80,
      headDisplay: () => (
        <HeadDisplay
          title='Simple Disease Flag'
          id={CLAIMS_LISTING_IDS.RULE_SIMPLE_DISEASE}
          isSorted={sortBy === CLAIMS_LISTING_IDS.RULE_SIMPLE_DISEASE}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <FlagColumnText
          data={record[CLAIMS_LISTING_IDS.RULE_SIMPLE_DISEASE]}
          normalClass={classes.listingRow}
          keyPrefix='simple-disease'
          isShowMarginBottom={false}
        />
      ),
    },
    {
      title: 'Elective Surgery Flag',
      id: CLAIMS_LISTING_IDS.RULE_ELECTIVE_SURGERY,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 80,
      headDisplay: () => (
        <HeadDisplay
          title='Elective Surgery Flag'
          id={CLAIMS_LISTING_IDS.RULE_ELECTIVE_SURGERY}
          isSorted={sortBy === CLAIMS_LISTING_IDS.RULE_ELECTIVE_SURGERY}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <FlagColumnText
          data={record[CLAIMS_LISTING_IDS.RULE_ELECTIVE_SURGERY]}
          normalClass={classes.listingRow}
          keyPrefix='elective-surgery'
          isShowMarginBottom={false}
        />
      ),
    },
    {
      title: 'Day Surgery Flag',
      id: CLAIMS_LISTING_IDS.RULE_DAY_SURGERY,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 80,
      headDisplay: () => (
        <HeadDisplay
          title='Day Surgery Flag'
          id={CLAIMS_LISTING_IDS.RULE_DAY_SURGERY}
          isSorted={sortBy === CLAIMS_LISTING_IDS.RULE_DAY_SURGERY}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <FlagColumnText
          data={record[CLAIMS_LISTING_IDS.RULE_DAY_SURGERY]}
          normalClass={classes.listingRow}
          keyPrefix='day-surgery'
          isShowMarginBottom={false}
        />
      ),
    },
    {
      title: 'Package Pricing Flag',
      id: CLAIMS_LISTING_IDS.RULE_PACKAGE_PRICE,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 80,
      headDisplay: () => (
        <HeadDisplay
          title='Package Pricing Flag'
          id={CLAIMS_LISTING_IDS.RULE_PACKAGE_PRICE}
          isSorted={sortBy === CLAIMS_LISTING_IDS.RULE_PACKAGE_PRICE}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <FlagColumnText
          data={record[CLAIMS_LISTING_IDS.RULE_PACKAGE_PRICE]}
          normalClass={classes.listingRow}
          keyPrefix='package-pricing'
          isShowMarginBottom={false}
        />
      ),
    },
    {
      title: 'Decision',
      id: CLAIMS_LISTING_IDS.STP_DECISION,
      align: 'center',
      headClass: classes.listingHead,
      columnClass: classes.listingRow,
      width: 90,
      headDisplay: () => (
        <HeadDisplay
          title='Decision'
          id={CLAIMS_LISTING_IDS.STP_DECISION}
          isSorted={sortBy === CLAIMS_LISTING_IDS.STP_DECISION}
          setSortBy={setSortBy}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          classes={classes}
        />
      ),
      display: (record) => (
        <div className={classes.listingRow}>
          {checkEmptyString(record && record[CLAIMS_LISTING_IDS.STP_DECISION])}
        </div>
      ),
    },
  ];
};


const minusHeight = 200;

const ClaimListing = ({
  isShowTopBar = true,
  containerHeight,
  isShowStaticData = false,
  isProvideLinkToClaim = true,
  searchFilters,
}) => {
  const theme = useTheme();
  const [height, setHeight] = useState(
    containerHeight ? containerHeight : window.innerHeight - minusHeight,
  );
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(
        containerHeight ? containerHeight : window.innerHeight - minusHeight,
      );
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const classes = useStyles({theme, maxHeight: height});
  // const {authUserInfo} = useAuthentication();
  const userType = 'retail';

  const API_URLs = useURL();
  const CLAIMS_LISTING_IDS =
    UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_LISTING'];
  const [sortBy, setSortBy] = useState(CLAIMS_LISTING_IDS.RECEIVED_DATE);
  const [isAscending, setIsAscending] = useState(false);

  // const canEdit = getEditPermission(
  //   authUserInfo.role,
  //   VIEWS.CLAIM_LIST.key,
  // );

  // TODO: Remove in prod
  isProvideLinkToClaim = false;
  const heads = getHeads({
    sortBy,
    setSortBy,
    isAscending,
    setIsAscending,
    classes,
    userType,
    isProvideLinkToClaim,
  });

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [filters, setFilters] = useState(
    searchFilters ? {...searchFilters} : {},
  );

  const onItemPerPageChange = (number) => setItemPerPage(number.value);

  const onHandlePageChanged = (page) => setPage(page);

  const onFilterCancel = () => setIsFilterOpen(false);

  const onFilterChange = (filters) => {
    setFilters({...filters});
    setIsFilterOpen(false);
  };

  const params = pickBy(
    {
      ...filters,
      provider_type: filters && filters.hospital_type,
      ai_decision: filters.ai_decision && filters.ai_decision.join(','),
      outlier_detected:
        filters.outlier_detected && filters.outlier_detected.join(','),
      auto_adjudication_decision: filters.auto_adjudication_decision && filters.auto_adjudication_decision.join(','),
    },
    identity,
  );
  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URLs.CLAIMS_LISTING, {
      config: {
        params: {
          page_no: page + 1,
          item_per_page: itemPerPage,
          sort_by: sortBy,
          sort_type: isAscending ? 'asc' : 'desc',
          ...params,
        },
      },
    });

  useEffect(() => {
    setFilters(searchFilters ? {...searchFilters} : {});
  }, [searchFilters]);

  useEffect(() => {
    !isShowStaticData && makeRequest();
  }, [page, filters, itemPerPage, sortBy, isAscending]);

  useEffect(() => {
    if (!isShowStaticData && status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t get the list. Please try again.',
      );
    }
  }, [status, response]);

  const onDelete = (key) => {
    setFilters({...filters, [key]: undefined});
  };

  console.log('response listing');
  console.log(response);
  return (
    <Fragment>
      <Card className={classes.card}>
        {isShowTopBar && (
          <div className={classes.topContainer}>
            {/* <Search filters={filters} onFilterChange={onFilterChange} /> */}
            <ul className={classes.functions}>
              {/* {
                canEdit && <li>
                  <DownloadCSV
                    params={params}
                    url={API_URLs.EXPORT_CLAIM_LIST_URL}
                    prefix='claim_list'
                    text='Export to CSV'
                  />
                </li>
              } */}
              {/* <li>
                <Dropdown
                  isOpen={isFilterOpen}
                  onTrigger={setIsFilterOpen}
                  trigger={
                    <Button
                      type={BUTTON_TYPE.ICON}
                      icon={<Icon name={IconNames.Filter} />}
                      title='Filter'
                    />
                  }
                  dropdown={
                    <Filter
                      type={'claims'}
                      filters={filters}
                      onCancel={onFilterCancel}
                      onFilterChange={onFilterChange}
                    />
                  }
                />
              </li> */}
              <li className={classes.itemPerPage}>
                <label>Item per page</label>
                <Input
                  type={INPUT_TYPE.SELECT}
                  defaultValue={{value: itemPerPage, label: itemPerPage}}
                  options={OPTIONS_TYPE.ITEM_PER_PAGE}
                  onChange={onItemPerPageChange}
                />
              </li>
            </ul>
          </div>
        )}
        {!searchFilters && <FilterView items={filters} onDelete={onDelete} />}
        <Table
          heads={heads}
          isLoading={status === FETCHING}
          data={
            isShowStaticData ?
              userType === 'retail' ?
                CLAIM_LISTING_PREDICTIVE_SUMMARY.claimListingRetail :
                CLAIM_LISTING_PREDICTIVE_SUMMARY.claimListingGroup :
              status === SUCCESS ?
                response.data.list :
                []
          }
          style={{maxHeight: height}}
          className={classes.table}
          useCustomScroll={true}
        />
        {response && response.data.total > 0 && (
          <Pagination
            total={response.data.total}
            currentPage={page}
            itemPerPage={itemPerPage}
            onChange={onHandlePageChanged}
          />
        )}
      </Card>
    </Fragment>
  );
};

export default ClaimListing;
