import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {MdCached, MdFileUpload} from 'react-icons/md';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  classNames,
  Input,
  INPUT_TYPE,
  toast,
  useAuthentication,
} from 'aida-components';
import useRequest from 'hooks/useRequest';
import ApprovalList from 'components/approval-list';
import Modal from 'components/modal';

import useURL from 'hooks/useURL';
import {BUSINESS_TYPES} from './index';

import flatMap from 'lodash/flatMap';

import styles from './file-upload.module.scss';

const isCSV = (fileName) => fileName.match(/\.[0-9a-z]+$/i)[0] === '.csv';
export const fileTypeOpt = (fileType) => fileType.map((type) => ({value: type.value, label: type.displayName}));


const Upload = ({onDone}) => {
  const dropRef = useRef();
  const inputRef = useRef();
  const API_URL = useURL();

  const {authUserInfo} = useAuthentication();
  const [drag, setDrag] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [manager, setManager] = useState();
  const [file, setFile] = useState();
  const [businessType, setBusinessType] = useState();


  const [fileProgress, setFileProgress] = useState(-1);
  const formData = useMemo(() => new FormData(), []);

  const [{status, response}, makeRequest, {SUCCESS, ERROR}] = useRequest(API_URL.FILE_UPLOAD_URL, {
    verb: 'post',
    params: formData,
    config: {
      headers: {
        'content-type': 'multipart/form-data',
        approver_id: manager,
        business_type: businessType,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    },
  });

  const onCloseModal = () => {
    setIsOpenModal(false);
    setManager();
    setBusinessType();
    inputRef.current.value = null;
  };
  const onUploadFile = () => {
    setIsOpenModal(false);
    formData.append('upload_file', file);
    makeRequest().finally(() => {
      setFileProgress(-1);
      inputRef.current.value = null;
      formData.delete('upload_file');
    });
  };
  const onSelectManager = ({value}) => setManager(value);

  const handleUpload = (files) => {
    if (files.length !== 1) {
      toast.error('Please upload one file at a time only.');
    } else {
      const file = files[0];
      if (file.size / 1024 / 1024 > 50) {
        toast.error('The file size can not exceed 50 MB.');
      } else if (!isCSV(file.name)) {
        toast.error('We just support CSV file only.');
      } else {
        setFile(file);
        setIsOpenModal(true);
      }
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setDrag(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleUpload(files);
      e.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, []);

  const onOpenFileUpload = () => inputRef.current.click();
  const onFileChange = (e) => e.target.files && e.target.files.length > 0 && handleUpload(e.target.files);
  const onBusinessTypeChange = ({value}) => setBusinessType(value);

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success(`${file.name} is uploaded successful.`);
      onDone && onDone();
    } else if (status === ERROR) {
      toast.error((response.data && response.data.detail) || 'Can\'t upload the file. Please try again.');
    }
  }, [status, response]);

  return (
    <Fragment>
      <div className={classNames(styles.placeholder, {[styles.dragOver]: drag})} ref={dropRef}>
        <input className={styles.hiddenInput} type='file' accept='.csv' ref={inputRef} onChange={onFileChange} />
        <Button
          type={BUTTON_TYPE.LINK}
          text='Upload file'
          size={BUTTON_SIZE.SMALL}
          iconPosition='left'
          onClick={onOpenFileUpload}
          disabled={fileProgress >= 0}
          icon={fileProgress >= 0 ? <MdCached className={styles.spin} size={25} /> : <MdFileUpload size={25} />}
        />
      </div>
      {isOpenModal && (
        <Modal>
          <Card>
            <h3 className={styles.modalTitle}>Warning!</h3>
            <p className={styles.modalContent}>
            You are going to upload <strong>{file.name}</strong>.
            </p>
            <p className={styles.modalContentFileType}>Please choose right file type for your file</p>
            <Input
              className={styles.modalSelect}
              type={INPUT_TYPE.SELECT}
              placeholder='Choose file type'
              options={flatMap(BUSINESS_TYPES, (item)=> item.data)
                .filter((item) => !item.disabled)
                .map((item)=>({
                  label: item.label,
                  value: item.value,
                }))}
              onChange={onBusinessTypeChange}
            />
            <p className={styles.modalContent}>Please choose a manager to approve this file</p>
            <ApprovalList
              onSelect={onSelectManager}
              role={authUserInfo.role}
              screen='file_upload'
            />
            <div className={styles.btnWrapper}>
              <Button text='Cancel' type={BUTTON_TYPE.LINK}
                onClick={onCloseModal} />
              <Button
                text='Request'
                disabled={!manager || !businessType}
                type={BUTTON_TYPE.PRIMARY}
                onClick={onUploadFile}
              />
            </div>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};
export default Upload;
