import {classNames, createUseStyles} from 'aida-components';
import {splitByDelimiter} from 'helper/utils';
import {Fragment} from 'react';

const useLocalStyles = createUseStyles({
  textStyle: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '60%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
  flagText: {
    color: 'blue',
  },
  marginText: {
    '& >p': {
      marginBottom: 5,
    },
  },
});

const FlagText = ({text, keyPrefix='key-pre', isShowMarginBottom=true}) => {
  const classes = useLocalStyles();
  const cleanText = splitByDelimiter({
    text: text,
  });
  const elements = cleanText.map((i, index) => <p key={`${keyPrefix}-${index}`}>{i}</p>);

  return (
    <Fragment>
      <span
        className={classNames({[classes.textStyle]: true,
          [classes.flagText]: cleanText && cleanText[0] !== '-',
          [classes.marginText]: isShowMarginBottom,
        })}
      >
        {elements}
      </span>
    </Fragment>
  );
};

export default FlagText;
