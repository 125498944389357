export const OPTIONS_TYPE = {
  ITEM_PER_PAGE: [
    {value: '20', label: '20'},
    {value: '40', label: '40'},
    {value: '60', label: '60'},
    {value: '80', label: '80'},
    {value: '100', label: '100'},
  ],
  REJECTION_REASONS: [
    {value: 'MS05', label: 'MS05'},
    {value: 'MS24', label: 'MS24'},
    {value: 'MS35', label: 'MS35'},
  ],
  CLAIM_EVENT_TYPE_RETAIL: [
    {value: 'Inpatient', label: 'Inpatient'},
    {value: 'Outpatient', label: 'Outpatient'},
    {value: 'Day Surgery', label: 'Day Surgery'},
    {value: 'Day Treatment', label: 'Day Treatment'},
  ],
  CLAIM_EVENT_TYPE_GROUP: [
    {value: 'Inpatient', label: 'Inpatient'},
  ],
  PROVIDER_TYPE_RETAIL: [
    {value: 'Community Hospital', label: 'Community Hospital'},
    {value: 'Hospice', label: 'Hospice'},
    {value: 'Nursing Home', label: 'Nursing Home'},
    {value: 'Other Hospital', label: 'Other Hospital'},
    {value: 'Overseas Hospital', label: 'Overseas Hospital'},
    {value: 'Polyclinic', label: 'Polyclinic'},
    {value: 'Private Clinic', label: 'Private Clinic'},
    {value: 'Private Hospital', label: 'Private Hospital'},
    {value: 'Public Hospital', label: 'Public Hospital'},
  ],
  PROVIDER_TYPE_GROUP: [
    {value: 'Private Hospital', label: 'Private Hospital'},
    {value: 'Restructured / Government Hospital', label: 'Restructured / Government Hospital'},
    {value: 'Others', label: 'Others'},
  ],
  CLAIM_TYPE: [],
  OUTLIER_FLAG: [
    {value: 'yes', label: 'Yes'},
    {value: 'no', label: 'No'},
  ],
  AI_DECISION: [
    {value: 'Approve', label: 'Approve'},
    {value: 'Require Assessor Review', label: 'Require Assessor Review'},
    {value: 'Reject', label: 'Reject'},
  ],
  PANEL_TYPE: [
    {value: 'PANEL', label: 'Panel'},
    {value: 'NONPANEL', label: 'Non Panel'},
  ],
  CLAIM_PROVIDER_TYPE: [
    {value: 'pte', label: 'PTE'},
    {value: 'npte', label: 'NPTE'},
  ],
  ASSESSOR_DECISION: [
    {value: 'APPROVE', label: 'APPROVE'},
    {value: 'REJECT', label: 'REJECT'},
  ],
};

export const DEFAULT_ITEMS_PER_PAGE = 20;
