import {createUseStyles, Icon, Sidebar, useAuthentication, useHistory, useLocation, useTheme} from 'aida-components';
import {APPEAR_AT, getMenu} from 'helper/routers';
import {useEffect, useMemo, useState} from 'react';

const useStyles = createUseStyles({
  sidebar: {
    width: 120,
    overflow: 'auto',
  },
});

const useItemStyles = createUseStyles((theme)=>({
  sideItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.default.size.spacing}px 0`,
    cursor: 'pointer',
    transition: ['background', '.3s', 'ease-in-out'],
    backgroundColor: ({isActive}) =>
      isActive ? theme.default.color.background : 'transparent',
    '&:hover': {
      backgroundColor: theme.default.color.background,
      '& > svg, & > span': {
        color: theme.sideBar.textHoverColor,
        fill: theme.sideBar.textHoverColor,
      },
    },
  },
  sideText: ({isActive}) => ({
    ...theme.typography.h3,
    transition: ['color', '.3s', 'ease-in-out'],
    color: isActive ? theme.default.color.primary : theme.sideBar.textColor,
    paddingTop: 10,
    textAlign: 'center',
  }),
}));

const SidebarItem = ({link, icon, text}) => {
  const history = useHistory();
  const location = useLocation();
  const {authUserInfo} = useAuthentication();

  const [isActive, setIsActive] = useState(() => {
    const reg = new RegExp(`^/?${link}/?`, 'g');
    return reg.test(location.pathname);
  });

  const theme = useTheme();
  const classes = useItemStyles({isActive});

  useEffect(() => {
    const reg = new RegExp(`^/?${link}/?`, 'g');
    return setIsActive(reg.test(location.pathname));
  }, [link, location.pathname]);

  const onViewChange = () => history.push(link.replace(':type', authUserInfo.userType));

  return (
    <li className={classes.sideItem} onClick={onViewChange}>
      <Icon
        name={icon}
        size={30}
        color={isActive ? theme.default.color.primary : theme.sideBar.textColor}
      />
      <span className={classes.sideText}>{text}</span>
    </li>
  );
};

const SidebarComponent = () => {
  const classes = useStyles();
  const {authUserInfo} = useAuthentication();
  const menus = useMemo(
    () => getMenu(authUserInfo.role, APPEAR_AT.SIDEBAR),
    [],
  );

  return menus.length > 0 && (
    <Sidebar className={classes.sidebar}>
      <ul>
        {menus.map((menu) => (
          <SidebarItem key={`${menu.link}-${menu.text}`} {...menu} />
        ))}
      </ul>
    </Sidebar>
  );
};

export default SidebarComponent;
