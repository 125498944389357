import {useEffect} from 'react';
import {MdFileDownload} from 'react-icons/md';
import fileDownload from 'js-file-download';
import {Button, BUTTON_SIZE, BUTTON_TYPE, moment, toast, useRequest} from 'aida-components';

const DownloadCSV = ({text, params, url, iconOnly, className, prefix, buttonType = BUTTON_TYPE.LINK}) => {
  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] = useRequest(url, {
    config: {
      params,
    },
  });

  const getDownloadFile = () => {
    makeRequest();
  };

  useEffect(() => {
    const time = moment().format('YYYYMMDD_HHmmss');
    if (status === SUCCESS && response) {
      fileDownload(response.data, `${prefix}-${time}.csv`);
    } else if (status === ERROR) {
      toast.error('Can\'t download this file. Please try again.');
    }
  }, [response, status]);

  if (iconOnly) {
    return (
      <Button
        className={className}
        type={BUTTON_TYPE.ICON}
        size={BUTTON_SIZE.SMALL}
        icon={<MdFileDownload size={15} />}
        onClick={getDownloadFile}
        disabled={status === FETCHING}
      />
    );
  }
  return (
    <Button
      type={buttonType}
      text={text || 'Download'}
      size={BUTTON_SIZE.SMALL}
      iconPosition='left'
      icon={<MdFileDownload size={25} />}
      onClick={getDownloadFile}
      disabled={status === FETCHING}
    />
  );
};

export default DownloadCSV;
