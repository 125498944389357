import {createUseStyles, Main, Route, Switch, useAuthentication} from 'aida-components';
import {useMemo} from 'react';
import PageNotFound from 'views/404';
import {getMenu} from 'helper/routers';
import Failure from 'views/failure';

const useStyles = createUseStyles((theme)=>({
  mainContent: {
    padding: theme.default.size.spacing,
  },
  banner: {
    ...theme.typography.body,
    backgroundColor: '#FFE9BC',
    textAlign: 'center',
    padding: [10, 20],
  },
}));

const ContentComponent = () => {
  const classes = useStyles();
  const {authUserInfo} = useAuthentication();
  const views = useMemo(
    // () => getMenu(authUserInfo.role),
    () => getMenu('ahs_admin'),
    [],
  );

  return (
    <Main>
      <div className={classes.mainContent}>
        <Switch>
          {views.map((view) => (
            <Route
              key={`${view.key}-${view.link}`}
              exact
              path={view.link}
              component={view.component}
            />
          ))}
          <Route component={Failure}/>
        </Switch>
      </div>
    </Main>
  );
};

export default ContentComponent;
