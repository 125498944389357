import {Fragment, useState} from 'react';
import {MdFilterList} from 'react-icons/md';

import {Button, BUTTON_SIZE, BUTTON_TYPE, Dropdown, FilterView, useAuthentication} from 'aida-components';

import Filter from 'components/filter';
import Chart from './chart';
import styles from './predictive-model-summary.module.scss';
import ClaimListing from 'views/claims/claims-listing';
import {getEditPermission, getExportPermission, VIEWS} from 'helper/routers';
import useURL from 'hooks/useURL';
import DownloadCSV from 'components/download-csv';

const PredictiveModelSummary = () => {
  const API_URL = useURL();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
  );

  const canExport = getExportPermission(
    authUserInfo.role,
    VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
  );

  const onFilterCancel = () => setIsFilterOpen(false);
  const onFilterChange = (filters) => {
    setFilters({...filters});
    setIsFilterOpen(false);
  };

  const onDelete = (key) => {
    setFilters({...filters, [key]: undefined});
  };

  return (
    <Fragment>
      <div className={styles.btnWrappers}>
        <Dropdown
          isOpen={isFilterOpen}
          onTrigger={setIsFilterOpen}
          trigger={
            <Button
              type={BUTTON_TYPE.LINK}
              size={BUTTON_SIZE.SMALL}
              text='Filter'
              iconPosition='left'
              icon={<MdFilterList size={25} />}
            />
          }
          dropdown={<Filter
            type={'claims'}
            filters={filters}
            onCancel={onFilterCancel}
            onFilterChange={onFilterChange}
          />}
        />
      </div>
      <FilterView items={filters} onDelete={onDelete} />
      <br></br>
      <Chart filters={filters} onFilterChange={onFilterChange} />
      <br></br>
      {(canEdit || canExport) && <div className={styles.btnWrappers}>
        <DownloadCSV
          params={{
            provider_type: filters && filters.hospital_type,
            ...filters,
            is_analysis: true,
          }}
          url={API_URL.EXPORT_CLAIM_LIST_URL}
          prefix='claim_list'
          text='Export to CSV'
        />
      </div>}
      <ClaimListing isShowTopBar={false} containerHeight={500} searchFilters={{
        ...filters,
        is_analysis: true,
      }}/>
    </Fragment>
  );
};

export default PredictiveModelSummary;
