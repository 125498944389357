/*eslint-disable*/
import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {MdInfo} from 'react-icons/md';
import {
  AILoading,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  DateRangePicker,
  moment,
  toast,
  useAuthentication,
} from 'aida-components';
import useRequest from 'hooks/useRequest';
import useURL from 'hooks/useURL';
import {DateFormat, getTime} from 'helper/utils';
import DownloadCSV from 'components/download-csv';

import {Timeline, TimelineEvent} from 'components/timeline';
import Empty from 'components/empty';
import styles from './log.module.scss';
import {getEditPermission, VIEWS} from 'helper/routers';

const Log = () => {
  const API_URL = useURL();

  const [startDate, setStartDate] = useState(
    moment().subtract(7, 'd').format(DateFormat),
  );
  const [endDate, setEndDate] = useState(moment().format(DateFormat),
  );
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.SYSTEM_LOGS.key,
  );
  const [
    {status, response},
    makeRequest,
    {FETCHING, SUCCESS, ERROR},
    source,
  ] = useRequest(API_URL.SYSTEM_LOGS_URL, {
    config: {
      params: {
        from_date: startDate && startDate.replace(/\-/g, '/'),
        to_date: endDate && endDate.replace(/\-/g, '/'),
      },
    },
  });

  useEffect(() => {
    makeRequest();
    return () => {
      source && source.cancel();
    };
  }, [startDate, endDate]);

  useEffect(() => {
    if (status === ERROR) {
      toast.error((response.data && response.data.detail) || 'Can\'t get the logs. Please try again.');
    }
  }, [status]);

  const getFrom7Days = () => {
    const startDate = moment().subtract(7, 'd').format(DateFormat);
    const endDate = moment().format(DateFormat);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getFrom14Days = () => {
    const startDate = moment().subtract(14, 'd').format(DateFormat);
    const endDate = moment().format(DateFormat);
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const getFrom30Days = () => {
    const startDate = moment().subtract(30, 'd').format(DateFormat);
    const endDate = moment().format(DateFormat);
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const onDateRangeChange = (from, to) => {
    from && setStartDate(from);
    to && setEndDate(to);
  };

  return (
    <Container fluid>
      <Row align='center'>
        <Col md={8} offset={{md: 2}}>
          <h2 className={styles.title}>System Logs
            {
              canEdit && <DownloadCSV
                params={{
                  from_date: startDate,
                  to_date: endDate,
                }}
                url={API_URL.EXPORT_SYSTEM_LOGS_URL}
                className={styles.button}
                prefix='system_log'
                text='Export to CSV'
              />
            }

          </h2>
          <Card>
            <div className={styles.quickFilter}>
              <span className={styles.label}>Period:</span>
              <ul className={styles.listOfFilter}>
                <li>
                  <Button
                    text='Last 7 days'
                    type={
                      moment(endDate, 'DD/MM/YYYY').diff(
                        moment(startDate, 'DD/MM/YYYY'),
                        'days',
                      ) === 7 ?
                        BUTTON_TYPE.PRIMARY :
                        BUTTON_TYPE.SECONDARY
                    }
                    size={BUTTON_SIZE.SMALL}
                    onClick={getFrom7Days}
                  />
                </li>
                <li>
                  <Button
                    text='Last 14 days'
                    type={
                      moment(endDate, 'DD/MM/YYYY').diff(
                        moment(startDate, 'DD/MM/YYYY'),
                        'days',
                      ) === 14 ?
                        BUTTON_TYPE.PRIMARY :
                        BUTTON_TYPE.SECONDARY
                    }
                    size={BUTTON_SIZE.SMALL}
                    onClick={getFrom14Days}
                  />
                </li>
                <li>
                  <Button
                    text='Last 30 days'
                    type={
                      moment(endDate, 'DD/MM/YYYY').diff(
                        moment(startDate, 'DD/MM/YYYY'),
                        'days',
                      ) === 30 ?
                        BUTTON_TYPE.PRIMARY :
                        BUTTON_TYPE.SECONDARY
                    }
                    size={BUTTON_SIZE.SMALL}
                    onClick={getFrom30Days}
                  />
                </li>
                <li>
                  <DateRangePicker onChange={onDateRangeChange} size='small' />
                </li>
              </ul>
            </div>

            {status === FETCHING && <AILoading />}
            {status === SUCCESS && (
              <Fragment>
                {response.data && response.data.list.length > 0 && (
                  <Timeline className={styles.timeline}>
                    {response.data.list.map((event) => (
                      <TimelineEvent
                        key={event.action_id}
                        title={event.action_user}
                        createdAt={getTime(event.action_datetime)}
                        icon={<MdInfo size={25} />}>
                        {event.action_message}
                      </TimelineEvent>
                    ))}
                  </Timeline>
                )}
                {!response.data ||
                  (!response.data.list.length && (
                    <Empty
                      text={`No results found from ${startDate.replace(/\-/g, '/')} to ${endDate.replace(/\-/g, '/')}`}
                    />
                  ))}
              </Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Log;
