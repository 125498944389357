import {classNames, createUseStyles, useTheme} from 'aida-components';
import {useAppStyles} from 'helper/app-styles';
import {isBlank, checkEmptyString, remapYesNo} from 'helper/utils';
import FlagText from 'components/label-value/flag-text';


const FlagColumnText = ({
  data,
  normalClass,
  keyPrefix,
  isShowMarginBottom = true,
}) => {
  if (data && !isBlank(data) && (data.toLowerCase() === 'yes' || data.toLowerCase() === 'y' )) {
    return <FlagText
      text={'Yes'}
      keyPrefix={keyPrefix}
      isShowMarginBottom={isShowMarginBottom}
    />;
  }
  return <span
    className={classNames(normalClass)}>{
      checkEmptyString(remapYesNo(data))
    }</span>;
};

export default FlagColumnText;
