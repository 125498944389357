import React, {useEffect, useState} from 'react';
import trim from 'lodash/trim';

import {Button, BUTTON_SIZE, BUTTON_TYPE, createUseStyles, Input, INPUT_TYPE} from 'aida-components';

const useLocalStyles = createUseStyles({
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>div': {
      marginRight: 10,
    },
  },
  zeroMargin: {
    marginBottom: 0,
  },
});

const Search = ({filters, onFilterChange}) => {
  const classes = useLocalStyles();

  const [policyNumber, setPolicyNumber] = useState(filters.policy_number);
  const [claimNumber, setClaimNumber] = useState(filters.claim_number);
  const onPolicyNumberChange = (value) => setPolicyNumber(trim(value));
  const onClaimNumberChange = (value) => setClaimNumber(trim(value));

  const onSearch = () => onFilterChange({
    claim_number: claimNumber,
    policy_number: policyNumber,
  });

  useEffect(() => {
    setPolicyNumber(filters.policy_number);
    setClaimNumber(filters.claim_number);
  }, [filters.policy_number, filters.claim_number]);

  return (
    <div className={classes.search}>
      <Input
        className={classes.zeroMargin}
        type={INPUT_TYPE.TEXT}
        placeholder='Enter Policy Number'
        value={policyNumber || ''}
        onChange={onPolicyNumberChange}
      />
      <Input
        className={classes.zeroMargin}
        type={INPUT_TYPE.TEXT}
        placeholder='Enter Claim Number'
        value={claimNumber || ''}
        onChange={onClaimNumberChange}
      />
      <Button text='Search' type={BUTTON_TYPE.PRIMARY} size={BUTTON_SIZE.MEDIUM} onClick={onSearch} />
    </div>
  );
};

export default Search;
