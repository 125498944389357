import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {
  AILoading,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  CheckboxGroup,
  createUseStyles, toast,
  useAuthentication, useRequest,
  useTheme,
} from 'aida-components';
import {useStyles} from 'views/claims/styles';
import FileItem from './file-item';

import styles from './file-upload.module.scss';
import useURL from 'hooks/useURL';
import {getEditPermission, getExportPermission, VIEWS} from 'helper/routers';
import Upload from 'views/file-upload/upload';
import Empty from 'components/empty';

const useLocalStyles = createUseStyles({
  ...useStyles,
  topContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const BUSINESS_TYPES = [
  {
    title: 'Guideline',
    data: [{
      label: 'Day Procedure',
      value: 'Day Procedure',
      disabled: true,
    }, {
      label: 'Special Arrangements',
      value: 'Special Arrangements',
    }, {
      label: 'Pre-Existing Conditions',
      value: 'Pre-Existing Conditions',
      disabled: true,
    }, {
      label: 'Waiting Period',
      value: 'Waiting Period',
      disabled: true,
    }, {
      label: 'Congenital Exclusions',
      value: 'Congenital Exclusions',
      disabled: true,
    }],
  }, {
    title: 'Line Item',
    data: [{
      label: 'Non-Payable Items',
      value: 'Non-Payable Items',
      disabled: true,
    }],
  },
];

const Filter = ({classes, businessType, onBusinessChange}) => {
  return (
    <Card>
      <div className={classes.topContainer}>
        <span className={styles.label}>Show by:</span>
        <ul className={classes.functions}>
          <li>
            <Button
              type={BUTTON_TYPE.LINK}
              text='Rule Maintenance'
              size={BUTTON_SIZE.MEDIUM}
              disabled
            />
          </li>
          <li>
            <Button
              type={BUTTON_TYPE.LINK}
              text='Mapping Module Maintenance'
              size={BUTTON_SIZE.MEDIUM}
              disabled
            />
          </li>
        </ul>
      </div>
      {
        BUSINESS_TYPES.map((type) => <div
          key={type.title}
          className={styles.block}>
          <h4>{type.title}</h4>
          <CheckboxGroup
            className={styles.listCheckBox}
            items={
              type.data.map((item) => ({
                label: item.label,
                value: item.value,
                disabled: item.disabled,
              }))
            }
            values={businessType}
            onChange={onBusinessChange}
          />
        </div>)
      }
    </Card>
  );
};


const FileUpload = () => {
  const API_URL = useURL();

  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(authUserInfo.role, VIEWS.FILE_UPLOAD.key);
  const theme = useTheme();
  const [businessType, setBusinessType] = useState([]);
  const classes = useLocalStyles({theme});

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.LIST_UPLOADED_FILES_URL, {
      config: {
        params: {
          business_types: businessType.join(','),
        },
      },
    });

  useEffect(() => {
    makeRequest();
  }, [businessType]);

  useEffect(() => {
    if (status === ERROR) {
      toast.error((response.data && response.data.detail) || 'Can\'t get the file list. Please try again.');
    }
  }, [status]);

  const onBusinessChange = (values) => setBusinessType([...values]);

  return (
    <Container fluid>
      <Row>
        <Col md={10} offset={{md: 1}}>
          <h2 className={styles.title}>File Upload</h2>
          <Filter
            classes={classes}
            onBusinessChange={onBusinessChange}
            businessType={businessType}
          />
        </Col>
      </Row>
      <Row align='center'>
        <Col md={10} offset={{md: 1}}>
          {<div className={styles.btnWrapper}>
            {canEdit && (
              <Fragment>
                <div className={styles.tip}>
                  Please note the following special characters in the filenames will be
                  restricted:<br></br>
                  <strong>
                    @, #, $, ^, ?, {'{'}, {'}'}, |, ! , .exe, cmd, http, ://,
                    hyperlink, http://, https://, .bat, .msi
                  </strong>
                </div>
                <Upload
                  onDone={makeRequest}
                />
              </Fragment>
            )}
          </div>}

          {status === FETCHING && (
            <div className={styles.loadingWrapper}>
              <AILoading />
            </div>
          )}

          {status === SUCCESS && response.data && response.data.length > 0 && (
            <Card className={styles.wrapper}>
              {response.data.map((file) => (
                <FileItem
                  {...file}
                  key={`${file.uploadFileName}-${file.businessType}`}
                  onDelete={makeRequest}
                />
              ))}
              {(!response.data || response.data.length < 1) && (
                <Empty text={'No results found.'} />
              )}
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FileUpload;
