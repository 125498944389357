import React from 'react';
import ReactDOM from 'react-dom';
import 'helper/fonts';
import {getTheme, THEME, ThemeProvider} from 'aida-components';

import App from './App';
import reportWebVitals from './reportWebVitals';

const AppTheme = {
  ...THEME.DEFAULT,
  color: {
    ...THEME.DEFAULT.color,
    primary: '#6f38c5',
  },
};

const DomRender = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={getTheme(AppTheme)}>
        <App/>
      </ThemeProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(
  <DomRender />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to system-logs results (for example: reportWebVitals(console.system-logs))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
