import {Fragment, useEffect, useState, useLayoutEffect} from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  Dropdown,
  FilterView,
  Icon,
  IconNames,
  Input,
  INPUT_TYPE,
  Pagination,
  Table,
  toast,
  useAuthentication,
  useTheme,
  DotLoading,
} from 'aida-components';
import {useStyles} from 'views/claims/styles';
import Filter from 'components/filter/line-items-filter';
import {DEFAULT_ITEMS_PER_PAGE, OPTIONS_TYPE} from 'helper/constants';
import {UserTypeConfig} from 'helper/user-type-config';
import {CanEditThisValue} from 'components/can-edit-value';
import {findIndex} from 'lodash';
import {
  checkEmptyString,
  roundNumber,
  DBDateFormat,
  UIOutputFormat,
  convertDateString,
} from 'helper/utils';
import useRequest from 'hooks/useRequest';
import useURL from 'hooks/useURL';
import {isEqual} from 'lodash';
import {SaveButton} from 'views/claims/claims-details/retail/line-item-details/save-btn';
const useLocalStyles = createUseStyles({
  ...useStyles,
  topContainer: {
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nonPayableItemContainer: {
    padding: 20,
    boxShadow: '0 3px 6px #ccc',
    borderRadius: 5,
    marginBottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  totalAmount: {
    fontWeight: 700,
    textAlign: 'right',
    marginTop: 20,
    marginRight: 10,
  },
  table: {
    background: 'transparent',
    boxShadow: 'none',
  },
  itemTitle: {
    fontWeight: '700 !important',
    marginBottom: 10,
  },
  buttons: {
    textAlign: 'right',
  },
});
const HeadDisplay = ({
  classes,
  title,
  id,
  isSorted,
  isAscending,
  setSortBy,
  setIsAscending,
}) => {
  const onHeadClick = () => {
    setSortBy(id);
    isSorted && setIsAscending(!isAscending);
  };

  return (
    <span className={classes.headDisplay} onClick={onHeadClick}>
      {title}
      {isSorted && (
        <Icon name={isAscending ? IconNames.UpArrow : IconNames.DownArrow} />
      )}
    </span>
  );
};

const getItemHeads = ({
  sortBy,
  setSortBy,
  isAscending,
  setIsAscending,
  classes,
  userType,
  isProvideLinkToClaim,
  onRowUpdate,
}) => [{
  title: 'Invoice Date',
  id: 'BILL_DATE',
  align: 'center',
  width: '10%',
  headClass: classes.head,
  columnClass: classes.row,
  headDisplay: () => (
    <HeadDisplay
      title='Invoice Date'
      id='BILL_DATE'
      isSorted={sortBy === 'BILL_DATE'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  headDisplay: () => (
    <HeadDisplay
      title='Invoice Date'
      id='BILL_DATE'
      isSorted={sortBy === 'BILL_DATE'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  display: (data) => <span>{convertDateString(
    data['BILL_DATE'],
    DBDateFormat,
    UIOutputFormat,
  )}</span>,
}, {
  title: 'Invoice Number',
  id: 'BILL_NUMBER',
  align: 'center',
  width: '10%',
  headClass: classes.head,
  columnClass: classes.row,
  headDisplay: () => (
    <HeadDisplay
      title='Invoice Number'
      id='BILL_NUMBER'
      isSorted={sortBy === 'BILL_NUMBER'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  headDisplay: () => (
    <HeadDisplay
      title='Invoice Number'
      id='BILL_NUMBER'
      isSorted={sortBy === 'BILL_NUMBER'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
}, {
  title: 'Line Item Description',
  id: 'LINEITEM_DESC',
  align: 'center',
  width: '25%',
  headClass: classes.head,
  columnClass: classes.row,
  headDisplay: () => (
    <HeadDisplay
      title='Line Item Description'
      id='LINEITEM_DESC'
      isSorted={sortBy === 'LINEITEM_DESC'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  headDisplay: () => (
    <HeadDisplay
      title='Line Item Description'
      id='LINEITEM_DESC'
      isSorted={sortBy === 'LINEITEM_DESC'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
}, {
  title: 'Provider Name',
  id: 'PROVIDER_NAME',
  align: 'center',
  width: '15%',
  headClass: classes.head,
  columnClass: classes.row,
  headDisplay: () => (
    <HeadDisplay
      title='Provider Name'
      id='PROVIDER_NAME'
      isSorted={sortBy === 'PROVIDER_NAME'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  headDisplay: () => (
    <HeadDisplay
      title='Provider Name'
      id='PROVIDER_NAME'
      isSorted={sortBy === 'PROVIDER_NAME'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
}, {
  title: 'NPI Indicator',
  id: 'NPI_INDICATOR',
  align: 'left',
  align: 'left',
  width: '10%',
  headClass: classes.head,
  columnClass: classes.row,
  headDisplay: () => (
    <HeadDisplay
      title='NPI Indicator'
      id='NPI_INDICATOR'
      isSorted={sortBy === 'NPI_INDICATOR'}
      setSortBy={setSortBy}
      isAscending={isAscending}
      setIsAscending={setIsAscending}
      classes={classes}
    />
  ),
  display: (data) =>
    <CanEditThisValue
      old_npi_value={data['NPI_INDICATOR']}
      id={data['RRN_VALUE']}
      is_current_change
      onRowUpdate={onRowUpdate}
    />,
}, {
  title: 'Reason Code & Description',
  id: 'REASON_CODE',
  align: 'center',
  width: '20%',
  headClass: classes.head,
  columnClass: classes.row,
  display: ({REASON_CODE, REASON_DESC}) => <span>
    {REASON_CODE && REASON_DESC ?
      checkEmptyString(`${REASON_CODE} - ${REASON_DESC}`) :
      REASON_DESC ? checkEmptyString(`${REASON_DESC}`) :
        REASON_CODE ? checkEmptyString(`${REASON_CODE}`) : '-'
    }
  </span>,
}, {
  title: 'Amount (RM)',
  id: 'CHARGE_AMT',
  align: 'center',
  width: '10%',
  headClass: classes.head,
  columnClass: classes.row,
  display: ({CHARGE_AMT}) => <div>
    {roundNumber({
      number: CHARGE_AMT,
      isCheckEmpty: true,
      isFormatNumber: true,
    })}
  </div>,
}];
const minusHeight = 400;
const LineItemDetails = ({
  claimNumber,
  isShowTopBar = true,
  searchFilters,
  isShowStaticData = false,
  isProvideLinkToClaim = true,
  containerHeight,
  onSuccess,
}) => {
  const theme = useTheme();
  const [height, setHeight] = useState(
    containerHeight ? containerHeight : window.innerHeight - minusHeight,
  );
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(
        containerHeight ? containerHeight : window.innerHeight - minusHeight,
      );
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const classes = useLocalStyles({theme, maxHeight: height});
  const userTypes = 'retail';
  const API_URLs = useURL();
  const CLAIMS_LISTING_IDS =
    UserTypeConfig[userTypes.toUpperCase()]['DATA_IDS']['CLAIMS_360']['NON_PAYABLE_TABLE'];
  const [sortBy, setSortBy] = useState(CLAIMS_LISTING_IDS.DATE);
  const [isAscending, setIsAscending] = useState(false);
  const [updatedRowsData, setUpdatedRowsData] = useState([]);
  const {authUserInfo} = useAuthentication();
  const {userType} = authUserInfo;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [filters, setFilters] = useState(
    searchFilters ? {...searchFilters} : {},
  );
  const [initialData, setInitialData] = useState([]);
  const onItemPerPageChange = (number) => setItemPerPage(number.value);

  const onHandlePageChanged = (page) => setPage(page);

  const onFilterCancel = () => setIsFilterOpen(false);

  const onFilterChange = (filters) => {
    setFilters({...filters});
    setIsFilterOpen(false);
  };
  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}, source] = useRequest(
    API_URLs.CLAIM_DETAILS,
    {
      config: {
        params: {
          claim_id: claimNumber,
          page_no: page + 1,
          item_per_page: itemPerPage,
          sort_by: sortBy,
          sort_type: isAscending ? 'asc' : 'desc',
          ...filters,
          page_no: page + 1,
          item_per_page: itemPerPage,
          sort_by: sortBy,
          sort_type: isAscending ? 'asc' : 'desc',
          ...filters,
        },
      },
    },
  );
  useEffect(() => {
    if (status === ERROR) {
      if (response.data) {
        // toast.error(response.data && response.data.header.message);
        toast.error('error');
      } else {
        toast.error(response);
      }
    } else if (status === SUCCESS && response && response.data) {
      setInitialData(response.data.line_items);
    }
  }, [status, response]);
  useEffect(() => {
    makeRequest();
    setUpdatedRowsData([]);
    return () => {
      source && source.cancel();
      console.log('source', source);
    };
  }, [claimNumber]);

  useEffect(() => {
    setFilters(searchFilters ? {...searchFilters} : {});
  }, [searchFilters]);

  useEffect(() => {
    !isShowStaticData && makeRequest();
    setUpdatedRowsData([]);
  }, [page, filters, itemPerPage, sortBy, isAscending]);

  useEffect(() => {
    if (!isShowStaticData && status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t get the list. Please try again.',
      );
    }
  }, [status, response]);
  const onDelete = (key) => {
    setFilters({...filters, [key]: undefined});
  };

  const handleRowUpdate = ({id, npiValue}) => {
    const index = initialData.findIndex((item) => item.RRN_VALUE === id && item.NPI_INDICATOR !== npiValue);
    if (updatedRowsData.length > 0) {
      const updatedRowsIndex = updatedRowsData.findIndex((item) => item.rrn_value === id);
      if (updatedRowsIndex > -1) {
        updatedRowsData.splice(updatedRowsIndex, 1);
        setUpdatedRowsData([...updatedRowsData]);
      }
    }
    if (index !== -1) {
      const updatedItem = {
        rrn_value: id,
        qc_value: npiValue,
        npi_description: initialData[index].LINEITEM_DESC,
      };
      setUpdatedRowsData((prevRows) => [...prevRows, updatedItem]);
    }
  };
  const handleSaveSuccess = () => {
    makeRequest();
    setUpdatedRowsData([]);
  };
  useEffect(() => {
  }, [updatedRowsData]);
  const itemHeads = getItemHeads({
    sortBy,
    setSortBy,
    isAscending,
    setIsAscending,
    classes,
    userType,
    isProvideLinkToClaim,
    onRowUpdate: handleRowUpdate,
  });
  return (
    <Fragment>
      <Card className={classes.nonPayableItemContainer}>
        {isShowTopBar && response && response.data.lineitems_count > 0 && (
          <div className={classes.topContainer}>
            <div></div>
            <ul className={classes.functions}>
              <li>
                <Dropdown
                  isOpen={isFilterOpen}
                  onTrigger={setIsFilterOpen}
                  trigger={
                    <Button
                      type={BUTTON_TYPE.ICON}
                      icon={<Icon name={IconNames.Filter} />}
                      title='Filter'
                    />
                  }
                  dropdown={
                    <Filter
                      type={'claims'}
                      filters={filters}
                      onCancel={onFilterCancel}
                      onFilterChange={onFilterChange}
                    />
                  }
                />
              </li>
              <li className={classes.itemPerPage}>
                <label>Item per page</label>
                <Input
                  type={INPUT_TYPE.SELECT}
                  defaultValue={{value: itemPerPage, label: itemPerPage}}
                  options={OPTIONS_TYPE.ITEM_PER_PAGE}
                  onChange={onItemPerPageChange}
                />
              </li>
            </ul>
          </div>
        )}
        {!searchFilters && <FilterView items={filters} onDelete={onDelete} />}
        <Table
          className={classes.table}
          heads={itemHeads}
          isLoading={status===FETCHING}
          data={response && response.data && response.data.line_items}
          style={{maxHeight: height}}
          useCustomScroll={true}
        />
        {response && response.data.lineitems_count > 0 && (
          <Pagination
            total={response.data.lineitems_count}
            currentPage={page}
            itemPerPage={itemPerPage}
            onChange={onHandlePageChanged}
          />
        )}
        {response && response.data.lineitems_count > 0 && (
          <div className={classes.buttons}>
            <SaveButton
              disabled={status === FETCHING || (isEqual(updatedRowsData.length, 0))}
              updatedRowsData={updatedRowsData}
              claimId={claimNumber}
              onSuccess={handleSaveSuccess}
            />
          </div>
        )}
      </Card>
    </Fragment>
  );
};
export default LineItemDetails;
