import {Fragment} from 'react';
import {Col, Row} from 'react-grid-system';

import styles from './stp-conditions.module.scss';
import FileUploadConditions from './form';
import STPForm from './stp-form';

const StpConditions = () => <Fragment>
  <Row>
    <Col
      md={6}
      offset={{md: 3}}>
      <h2 className={styles.title}>Auto Adjudication Conditions</h2>
      <STPForm />
    </Col>
  </Row>
  <br />
  <br />
  <Row>
    <Col md={6} offset={{md: 3}}>
      <h2 className={styles.title}>Pre-Auth Fee Tolerance Settings</h2>
      <FileUploadConditions />
    </Col>
  </Row>
</Fragment>;

export default StpConditions;
