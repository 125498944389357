import {IconNames} from 'aida-components';
import union from 'lodash/union';
import indexOf from 'lodash/indexOf';
import find from 'lodash/find';
import includes from 'lodash/includes';

import ClaimListing from 'views/claims/claims-listing';
import StpRateCalculator from 'views/stp-rate-calculator';
import PredictiveModelSummary from 'views/predictive-model-summary';
import StpConditions from 'views/stp-conditions';
import ModelControl from 'views/model-control';
import FileUpload from 'views/file-upload';
import SystemNotification from 'views/system-notification';
import SystemLogs from 'views/system-logs';
import UserManagement from 'views/user-management';

import ClaimDetails from 'views/claims/claims-details';

const PredictiveModelSummaryDetails = () => <div>PredictiveModelSummaryDetails</div>;


export const RouterRole = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const APPEAR_AT = {
  HEADER: 'HEADER',
  AVATAR_MENU: 'AVATAR_MENU',
  SIDEBAR: 'SIDEBAR',
};

export const VIEWS = {
  USER_MANAGEMENT: {
    key: 'USER_MANAGEMENT',
    text: 'User Management',
    link: '/user-management',
    icon: IconNames.Group,
    appearAt: APPEAR_AT.AVATAR_MENU,
    component: UserManagement,
  },
  CLAIM_LIST: {
    key: 'CLAIM_LIST',
    text: 'Claims',
    link: '/claims',
    icon: IconNames.BulletedList,
    appearAt: APPEAR_AT.SIDEBAR,
    component: ClaimListing,
  },
  CLAIM_DETAILS: {
    key: 'CLAIM_DETAILS',
    link: '/claims/:claimNumber',
    appearAt: null,
    component: ClaimDetails,
  },
  PREDICTIVE_MODEL_SUMMARY: {
    key: 'PREDICTIVE_MODEL_SUMMARY',
    link: '/predictive-model-summary',
    text: 'Predictive Model Summary',
    icon: IconNames.Dashboard,
    appearAt: APPEAR_AT.SIDEBAR,
    component: PredictiveModelSummary,
  },
  PREDICTIVE_MODEL_SUMMARY_DETAILS: {
    key: 'PREDICTIVE_MODEL_SUMMARY_DETAILS',
    link: '/predictive-model-summary/:claimNumber',
    appearAt: null,
    component: PredictiveModelSummaryDetails,
  },
  STP_RATE_CALCULATOR: {
    key: 'STP_RATE_CALCULATOR',
    link: '/stp-rate-calculator',
    text: 'Auto Adjudication Calculator',
    icon: IconNames.TableView,
    appearAt: APPEAR_AT.SIDEBAR,
    component: StpRateCalculator,
  },
  STP_CONDITIONS: {
    key: 'STP_CONDITIONS',
    link: '/stp-conditions',
    text: 'Auto Adjudication Settings',
    icon: IconNames.Rule,
    appearAt: APPEAR_AT.SIDEBAR,
    component: StpConditions,
  },
  MODEL_CONTROL: {
    key: 'MODEL_CONTROL',
    link: '/model-control',
    text: 'Model Control',
    icon: IconNames.Settings,
    appearAt: APPEAR_AT.SIDEBAR,
    component: ModelControl,
  },
  FILE_UPLOAD: {
    key: 'FILE_UPLOAD',
    link: '/file-upload',
    text: 'File Upload',
    icon: IconNames.Upload,
    appearAt: APPEAR_AT.HEADER,
    component: FileUpload,
  },
  SYSTEM_NOTIFICATION: {
    key: 'SYSTEM_NOTIFICATION',
    link: '/system-notifications',
    text: 'System Notifications',
    icon: IconNames.Notification,
    appearAt: APPEAR_AT.AVATAR_MENU,
    component: SystemNotification,
  },
  SYSTEM_LOGS: {
    key: 'SYSTEM_LOGS',
    link: '/system-logs',
    text: 'System Logs',
    icon: IconNames.CheckList,
    appearAt: APPEAR_AT.AVATAR_MENU,
    component: SystemLogs,
  },
};

export const roles = [
  {
    id: 'ahs_admin',
    text: 'AHS Admin',
    canView: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
    ],
    canEdit: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
    ],
    canExport: [
      VIEWS.CLAIM_LIST.key,
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'claim_supervisor',
    text: 'Claim Supervisor',
    canView: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_RATE_CALCULATOR.key,
      VIEWS.MODEL_CONTROL.key,
      VIEWS.FILE_UPLOAD.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
      VIEWS.STP_RATE_CALCULATOR.key,
    ],
    canExport: [
      VIEWS.FILE_UPLOAD.key,
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'auc_supervisor',
    text: 'AUC Supervisor',
    canView: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_RATE_CALCULATOR.key,
      VIEWS.STP_CONDITIONS.key,
      VIEWS.MODEL_CONTROL.key,
      VIEWS.FILE_UPLOAD.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
      VIEWS.STP_RATE_CALCULATOR.key,
      VIEWS.USER_MANAGEMENT.key,
      VIEWS.STP_CONDITIONS.key,
      VIEWS.MODEL_CONTROL.key,
      VIEWS.FILE_UPLOAD.key,
    ],
    canExport: [
      VIEWS.FILE_UPLOAD.key,
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
    ],
    canApprove: true,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'claim_assessor',
    text: 'Claim Assessor',
    canView: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_RATE_CALCULATOR.key,
      VIEWS.FILE_UPLOAD.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
    ],
    canExport: [
      VIEWS.FILE_UPLOAD.key,
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'auc_assessor',
    text: 'AUC Assessor',
    canView: [
      VIEWS.CLAIM_LIST.key,
      VIEWS.CLAIM_DETAILS.key,
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_RATE_CALCULATOR.key,
      VIEWS.FILE_UPLOAD.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
    ],
    canExport: [
      VIEWS.FILE_UPLOAD.key,
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'cc_supervisor',
    text: 'CC Supervisor',
    canView: [
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_CONDITIONS.key,
      VIEWS.MODEL_CONTROL.key,
      VIEWS.SYSTEM_LOGS.key,
      VIEWS.SYSTEM_NOTIFICATION.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
    ],
    canExport: [
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  }, {
    id: 'cc_agent',
    text: 'CC Agent',
    canView: [
      VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
      VIEWS.STP_CONDITIONS.key,
      VIEWS.MODEL_CONTROL.key,
      VIEWS.SYSTEM_LOGS.key,
      VIEWS.SYSTEM_NOTIFICATION.key,
      VIEWS.USER_MANAGEMENT.key,
    ],
    canEdit: [
    ],
    canExport: [
    ],
    canApprove: false,
    default: VIEWS.CLAIM_LIST,
  },
];

export const roleOptions = roles.map((role) => ({
  value: role.id,
  label: role.text,
}));

export const getMenu = (userRole, location) => {
  const menuItems = [];
  roles.map((role) => {
    if (role.id === userRole) {
      const userViews = union(role.canView, role.canEdit);
      return Object.keys(VIEWS).map((key) => {
        const viewItem = VIEWS[key];
        if (includes(userViews, viewItem.key)) {
          if (viewItem.appearAt === location) {
            return menuItems.push(viewItem);
          } else if (!location) {
            return menuItems.push(viewItem);
          }
        }
        return null;
      });
    }
    return null;
  });
  return menuItems;
};

export const getEditPermission = (userRole, viewId) => {
  const userPermission = find(roles, ['id', userRole]);
  if (userPermission && userPermission.canEdit) {
    return indexOf(userPermission.canEdit, viewId) >= 0;
  }
  return false;
};

export const getExportPermission = (userRole, viewId) => {
  const userPermission = find(roles, ['id', userRole]);
  if (userPermission && userPermission.canExport) {
    return indexOf(userPermission.canExport, viewId) >= 0;
  }
  return false;
};

export const getApprovalPermission = (userRole) => {
  const userPermission = find(roles, ['id', userRole]);
  return userPermission ? userPermission.canApprove : false;
};

export const getDefaultView = (userRole) => {
  const userPermission = find(roles, ['id', userRole]);
  return userPermission && userPermission.default;
};

export const CRM_ROLE_TO_OCR_ROLE_MAPPING = {
  'RIPPLE AHS Admin (BU)': 'ahs_admin',
  'RIPPLE Claim Supervisor (BU)': 'claim_supervisor',
  'RIPPLE AUC Supervisor (BU)': 'auc_supervisor',
  'RIPPLE Claim Assessor (BU)': 'claim_assessor',
  'RIPPLE AUC Assessor (BU)': 'auc_assessor',
  'RIPPLE CC Supervisor (BU)': 'cc_supervisor',
  'RIPPLE CC Agent (BU)': 'cc_agent',
};
