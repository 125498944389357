export const useAppStyles = {
  container: ({maxHeight = 'inherit'}) => ({

  }),
  pageTitle: ({theme}) => ({
    ...theme.typography.h4,
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 30,
  }),
  space: {
    margin: '0 0 30px 0',
    '&:last-child': {
      margin: 0,
    },
  },
  cardTitle: ({theme}) => ({
    ...theme.typography.h4,
    fontWeight: 600,
    marginBottom: 20,
  }),
  listWrapper: {
    '&> li': {
      marginTop: 10,
    },
  },
  orderedListWrapper: {
    listStyle: 'decimal',
    listStylePosition: 'inside',
    '&> li': {
      marginTop: 10,
    },
  },
  functions: {
    display: 'flex',
    justifyContent: 'flex-end',
    listStyle: 'none',
    position: 'relative',
    zIndex: 2,
    '&> li': {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 20,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  table: {
    background: 'transparent',
    boxShadow: 'none',
    marginBottom: 30,
  },
  head: ({theme}) => ({
    background: theme.card.backgroundColor,
    borderBottom: '1px solid #999',
    color: theme.default.color.text,
    ...theme.typography.title,
    fontWeight: 600,
  }),
  row: {
    fontSize: '16px',
    '&> span': {
      fontSize: '16px',
    },
  },
  listingHead: ({theme}) => ({
    background: theme.card.backgroundColor,
    borderBottom: '1px solid #999',
    color: theme.default.color.text,
    ...theme.typography.title,
    fontWeight: 600,
    fontSize: '14px',
  }),
  listingRow: {
    fontSize: '14px',
    '&> span': {
      fontSize: '14px',
      marginBottom: 5,
    },
    '&> div': {
      fontSize: '14px',
      marginBottom: 5,
    },
  },
  listingFlag: {
    fontSize: '14px',
    color: 'blue',
    '&> span': {
      fontSize: '14px',
      color: 'blue',
    },
  },
  itemPerPage: {
    display: 'inline-block',
    verticalAlign: 'middle',

    '&> label': {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingRight: 20,
    },

    '& >div': {
      width: 83,
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: 0,
    },
  },
  card: {
    marginTop: '20px',
  },
  btnWrapper: {
    paddingTop: 10,
    textAlign: 'right',

    '& >button': {
      width: '100px',

      '&:first-child': {
        marginRight: 20,
      },
    },
  },
};
