import {classNames, createUseStyles, Tooltip, useTheme} from 'aida-components';
import {isBlank} from 'helper/utils';
import {Fragment} from 'react';

const useStyles = createUseStyles({
  label: ({theme}) => ({
    ...theme.typography.title,
    color: theme.default.color.secondary,
    display: 'inline-block',
    verticalAlign: 'top',
    width: '35%',
    boxSizing: 'border-box',
  }),
  value: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '65%',
    boxSizing: 'border-box',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
  },
  label2: ({theme}) => ({
    ...theme.typography.title,
    color: theme.default.color.secondary,
    display: 'inline-block',
    verticalAlign: 'top',
    boxSizing: 'border-box',
    width: '40%',
  }),
  value2: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    boxSizing: 'border-box',
    lineHeight: '18px',
    width: '60%',
    whiteSpace: 'pre-line',
  },
  flagText: {
    color: 'blue',
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '60%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
  lineBreak: {
    marginTop: 20,
  },
  valueTooltipWidth: {
    width: '65%',
  },
  textOverflow: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inherit',
  },
  textRed: {
    color: '#F00',
  },
});

const LabelValue = ({
  item,
  data,
}) => {
  const theme = useTheme();
  const classes = useStyles({theme});

  let element = <br />;
  if (item.isHideIfNoValue && isBlank(data && data[item.id])) {
    return (<Fragment/>);
  }

  if (!item.isBreak) {
    if (item.displayType && item.displayType === 'flag') {
      element = <li>
        {
          item.labelDisplay ?
            item.labelDisplay(data) :
            <span
              className={classNames(classes.label2, item.labelClass)}
            >
              {item.isHideValue ? `${item.label}` : `${item.label}`} {item.isMandatory? <sup className={classes.textRed}>*</sup>:''} {item.isHideValue ? '' : ': '}
            </span>
        }
        { !item.isHideValue && (
          item.valueDisplay ?
            item.valueDisplay(data) :
            data && data[item.id] === 'Yes' ? (
              <Fragment>
                <span
                  className={classNames(classes.flagText, item.flagClass)}
                >
                  <p>{data && data[item.id]}</p>
                  <p className={classes.lineBreak}>{data && data[item.flagDescriptionId]}</p>
                </span>
              </Fragment>
            ) : (
              <span
                className={classNames(classes.value2, item.valueClass)}
              >
                {data && data[item.id] ? `${data[item.id]}` : '-'}
              </span>
            )
        )}
      </li>;
    } else {
      const labelClassName = item.classType === 2 ? classes.label2 : classes.label;
      const valueClassName = item.classType === 2 ? classes.value2 : classes.value;

      element = <li>
        {
          item.labelDisplay ?
            item.labelDisplay(data) :
            <span
              className={classNames(labelClassName, item.labelClass)}
            >
              {item.isHideValue ? `${item.label}` : `${item.label}`} {item.isMandatory? <sup className={classes.textRed}>*</sup>:''} {item.isHideValue ? '' : ': '}
            </span>
        }
        { !item.isHideValue && (
          item.valueDisplay ?
            item.valueDisplay(data) :
            <span
              className={classNames(valueClassName, item.valueClass)}
            >
              <Tooltip
                content={data && data[item.id] ? `${data[item.id]}` : '-'}
                isFullWidth={true}
              >
                <span className={classes.textOverflow}>
                  {data && data[item.id] ? `${data[item.id]}` : '-'}
                </span>
              </Tooltip>
            </span>
        )}
      </li>;
    }
  }

  return (element);
};

export default LabelValue;
