import {Layout} from 'aida-components';

import HeaderComponent from 'components/header';
import SidebarComponent from 'components/sidebar';
import ContentComponent from 'components/content';

const MainLayout = () => (
  <Layout
    header={<HeaderComponent />}
    // sidebar={<SidebarComponent />}
    main={<ContentComponent />}
  />
);

export default MainLayout;
