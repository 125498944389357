import {Card, createUseStyles, useTheme} from 'aida-components';
import {useStyles} from 'views/claims/styles';
import {additionalChecksList} from 'views/claims/claims-details/retail/data-ids';
import LabelValue from 'components/label-value';

const useLocalStyles = createUseStyles({
  ...useStyles,
  labelLocal: {
    width: '60%',
  },
  valueLocal: {
    width: '40%',
  },
  flagTextLocal: {
    width: '40%',
  },
});

const AdditionalChecks = ({claimDetails, businessType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  // const {authUserInfo} = useAuthentication();
  // const {userType} = authUserInfo;

  const userType = 'retail';

  const aChecksList = additionalChecksList({userType, businessType, classes});

  return (
    <Card className={classes.space}>
      <div>
        <h3 className={classes.cardTitle}>Stage 0 - AIML Additional Checks</h3>
        <ul className={classes.listWrapper}>
          {aChecksList.map((i, index) => (
            <LabelValue
              key={`${i.label}-key-${index}`}
              item={i}
              data={claimDetails}
            />
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default AdditionalChecks;
