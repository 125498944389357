import {createUseStyles, useTheme} from 'aida-components';
import React from 'react';
const useStyles = createUseStyles({
  textRed: {
    color: '#F00',
  },
});
const CustomTableHead = ({
  title,
  id,
  isMandatory}) => {
  const theme = useTheme();
  const classes = useStyles({theme});
  return (
    <span className={classes.headDisplay}>
      {title}
      {isMandatory?<sup className={classes.textRed}>*</sup>:''}
    </span>
  );
};

export default CustomTableHead;
