import {Redirect, Route, useAuthentication, useLocation} from 'aida-components';
import MainLayout from 'views/main-layout';
import useQuery from 'hooks/useQuery';

const Private = () => {
  // const {authUserInfo} = useAuthentication();
  // const {pathname} = useLocation();
  // const hasAuth = () => authUserInfo && authUserInfo.email;
  // const query = useQuery();

  // if (!hasAuth() && pathname !== '/failure') {
  //   sessionStorage.setItem('oldPath', pathname);
  //   if (query && query.size === 3) {
  //     return <Redirect to={`/login?${query.toString()}`} />;
  //   } else {
  //     return <Redirect to={`/refresh?${query.toString()}`} />;
  //   }
  // }

  return <Route component={MainLayout} />;
};

export default Private;
