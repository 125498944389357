import {Fragment, useEffect, useState} from 'react';
import {MdCached} from 'react-icons/md';
import {
  AILoading,
  Button,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  formatNumber,
  Input,
  INPUT_TYPE,
  RadioGroup,
  toast,
  useAuthentication,
} from 'aida-components';
import {getEditPermission, VIEWS} from 'helper/routers';
import useRequest from 'hooks/useRequest';
import ApprovalList from 'components/approval-list';
import Modal from 'components/modal';
import useURL from 'hooks/useURL';
import styles from './stp-conditions.module.scss';

const useLocalStyles = createUseStyles({
  listWrapper: {
    '&> li': {
      marginTop: 10,
      '& >div > div[class*=checkbox]': {
        marginTop: 10,
      },
    },
  },
});

const FileUploadConditions = () => {
  const classes = useLocalStyles();

  const API_URL = useURL();
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.STP_CONDITIONS.key,
  );

  const [xPercent, setXPercent] = useState();
  const [yDollar, setYDollar] = useState();
  const [isHigherOrLower, setIsHigherOrLower] = useState();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.GET_BENCHMARK_SETTING_URL);

  useEffect(() => makeRequest(), []);

  useEffect(() => {
    if (status === SUCCESS) {
      setXPercent(response.data && response.data.fb_tolerance_perc || 0);
      setYDollar(response.data && response.data.fb_tolerance_amt || 0);
      setIsHigherOrLower(response.data && response.data.fb_tolerance_selection || 'higher');
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t get the Pre-Auth Fee Tolerance settings. Please try again.',
      );
    }
  }, [status, response]);

  const onXPercentChange = (value) => setXPercent(parseInt(value, 10) > 100 ? 100 : value);
  const onYDollarChange = (value) => setYDollar(value);
  const onIsHigherLowerChange = (value) => setIsHigherOrLower(value);
  const onReset = () => {
    if (response) {
      setXPercent(response.data?.fb_tolerance_perc || 0);
      setYDollar(response.data?.fb_tolerance_amt || 0);
      setIsHigherOrLower(response.data?.fb_tolerance_selection || 'higher');
    }
  };
  return (
    <Card className={styles.wrapper}>
      {status === FETCHING && (
        <div className={styles.loadingWrapper}>
          <AILoading />
        </div>
      )}
      {status === SUCCESS && (
        <Fragment>
          <div className={styles.block}>
            <h3 className={styles.subTitle}>
              Total Bill Amount Tolerance Threshold
            </h3>
            <ul className={classes.listWrapper}>
              {!canEdit && (
                <Fragment>
                  <li className={styles.label}>
                    +/- X(%):{' '}
                    <strong>{xPercent}%</strong>
                  </li>
                  <li className={styles.label}>
                    +/- Y($):{' '}<strong>$ {formatNumber(yDollar)}</strong>
                  </li>
                  <li className={styles.label}>
                    {isHigherOrLower === 'higher' ?
                      'X% or $Y (whichever is higher)' :
                      'X% or $Y (whichever is lower)'}
                  </li>
                </Fragment>
              )}
              {canEdit && (
                <Fragment>
                  <li>
                    <Input
                      label='+/-  X(%):'
                      type={INPUT_TYPE.NUMBER}
                      placeholder='%'
                      onChange={onXPercentChange}
                      value={xPercent}
                      className={styles.maxInput}
                    />
                  </li>
                  <li>
                    <Input
                      label='+/-  Y($):'
                      type={INPUT_TYPE.NUMBER}
                      placeholder='$'
                      onChange={onYDollarChange}
                      value={yDollar}
                      className={styles.maxInput}
                    />
                  </li>
                  <li>
                    <RadioGroup
                      className={styles.listCheckBox}
                      items={[
                        {
                          value: 'higher',
                          label: 'X% or $Y (whichever is higher)',
                        },
                        {
                          value: 'lower',
                          label: 'X% or $Y (whichever is lower)',
                        },
                      ]}
                      value={isHigherOrLower}
                      onChange={onIsHigherLowerChange}
                    />
                  </li>
                </Fragment>
              )}
            </ul>
            {canEdit && response && (
              <SaveSetting
                fb_tolerance_amt={yDollar}
                fb_tolerance_perc={xPercent}
                fb_tolerance_selection={isHigherOrLower}
                old_fb_setting={response && response.data ? response.data : {
                  fb_tolerance_amt: 0,
                  fb_tolerance_perc: 0,
                  fb_tolerance_selection: 'higher',
                }}
                onReset={onReset}
              />
            )}
          </div>
        </Fragment>
      )}
    </Card>
  );
};

const SaveSetting = ({
  fb_tolerance_amt,
  fb_tolerance_perc,
  fb_tolerance_selection,
  old_fb_setting,
  onReset,
}) => {
  const API_URL = useURL();
  const [manager, setManager] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {authUserInfo} = useAuthentication();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.SAVE_BENCHMARK_SETTING_URL, {
      verb: 'post',
      params: {
        old_fb_setting,
        new_fb_setting: {
          fb_tolerance_amt,
          fb_tolerance_perc,
          fb_tolerance_selection},
      },
      config: {
        headers: {
          lan_id: authUserInfo.email,
          approver_id: manager,
        },
      },
    });

  const onSelectManager = ({value}) => setManager(value);
  const onCloseModal = () => {
    setIsOpenModal(false);
    setManager();
  };
  const onOpenModal = () => setIsOpenModal(true);

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success('Pre-Auth Fee Tolerance settings are sent successful.');
      setIsOpenModal(false);
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t sent Pre-Auth Fee Tolerance settings. Please try again.',
      );
    }
  }, [status, response]);

  return (
    <div className={styles.popupBtnWrapper}>
      <Button text='Reset' type={BUTTON_TYPE.LINK} onClick={onReset} />
      <Button text='Save' type={BUTTON_TYPE.PRIMARY} onClick={onOpenModal} />
      {isOpenModal && (
        <Modal>
          <Card>
            <h3 className={styles.modalTitle}>Warning!</h3>
            <p className={styles.modalContent}>
              You are going to update the Pre-Auth Fee Tolerance Setting.
            </p>
            <p className={styles.modalContent}>
              Please choose a manager to approve the change
            </p>
            <ApprovalList onSelect={onSelectManager} role={authUserInfo.role} screen='stp_conditions'/>
            <div className={styles.btnWrapper}>
              <Button
                text='Cancel'
                type={BUTTON_TYPE.LINK}
                onClick={onCloseModal}
              />
              <Button
                text='Request'
                disabled={!manager || status === FETCHING}
                type={BUTTON_TYPE.PRIMARY}
                onClick={makeRequest}
                icon={
                  status === FETCHING && (
                    <MdCached className={styles.spin} size={23} />
                  )
                }
              />
            </div>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default FileUploadConditions;
