import {Card, Table, classNames, createUseStyles, useTheme} from 'aida-components';
import CustomTableHead from 'components/custom-table-head';
import LabelValue from 'components/label-value';
import {UserTypeConfig} from 'helper/user-type-config';
import {DBDateFormat, DBDateString, UIOutputFormat, checkEmptyString, convertDateString, isBlank, remapYesNo, roundNumber} from 'helper/utils';
import {Fragment} from 'react';
import {
  pendingClaimLeftList,
  stage1FinalList,
  stage1LeftMainList,
  stage1PolicyExclusion,
  stage1RightMainList,
  stage2LeftMainList,
  stage2RightMainList,
} from 'views/claims/claims-details/retail/data-ids';
import {useStyles} from 'views/claims/styles';
const useLocalStyles = createUseStyles({
  ...useStyles,
  table: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tableSubText: {
    fontSize: 12,
  },
  head: ({theme}) => ({
    background: 'transparent',
    borderBottom: '1px solid #999',
    color: theme.default.color.text,
    ...theme.typography.title,
    fontWeight: 600,
    fontSize: 16,
  }),
  row: {
    fontSize: '14px',
    '&> span': {
      fontSize: '14px',
    },
  },
  bigTextRow: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowColor: {
    fontSize: '14px',
    color: 'blue',
    fontWeight: 'bold',
    '&> span': {
      fontSize: '14px',
    },
  },
  splitDiv: {
    display: 'flex',
    flexDirection: 'column',

    '& >span': {
      marginBottom: 5,
    },
  },
  listWrapperLocal: {
    marginTop: 20,
  },
  subLabel: {
    marginTop: 5,
  },
  blueText: {
    color: 'blue',
    fontWeight: 500,
  },
  customDisplayValue: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '60%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
  textRed: {
    color: '#F00',
  },
});

export const noOfYearsAndMonths = (months, ref, isSplitMonths = true) => {
  if (isBlank(ref)) {
    ref = '';
  } else {
    ref = `(${ref})`;
  }

  if (months === null || months === undefined || isBlank(months)) {
    return months;
  } else if (isSplitMonths) {
    const noOfYears = Math.floor(Number(months) / 12);
    const noOfMonths = Number(months) % 12;


    return `${noOfYears} ${noOfYears !== 1 ? 'years' : 'year'} 
    ${noOfMonths} ${noOfMonths !== 1 ? 'months' : 'month'} ${ref}`;
  } else {
    return `${months} ${ref}`;
  }
};
const probabilityGraphHead = ({classes, PROB_GRAPH_TABLE_IDS}) => {
  return [{
    title: 'Diagnosis',
    id: PROB_GRAPH_TABLE_IDS.DIAGNOSIS,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    headDisplay: () => (
      <CustomTableHead
        title='Diagnosis'
        id={PROB_GRAPH_TABLE_IDS.DIAGNOSIS}
        isMandatory={false}
        classes={classes}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[PROB_GRAPH_TABLE_IDS.DIAGNOSIS])}
    </div>),
  }, {
    title: 'Requesting Doctor Details',
    id: PROB_GRAPH_TABLE_IDS.DOCTOR,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(remapYesNo(record && record[PROB_GRAPH_TABLE_IDS.DOCTOR]))}
    </div>),
  }, {
    title: 'Early Claim Indicator',
    id: PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_INDICATOR,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classNames(classes.row,
      {
        [classes.blueText]: record && record[PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_INDICATOR] &&
          record[PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_INDICATOR] === 'Y',
      })
    }>
      {checkEmptyString(remapYesNo(record && record[PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_INDICATOR]), 'No Data Available')}
    </div>),
  }, {
    title: 'Average Claimed after:',
    id: PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_THRESHOLD,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(
        noOfYearsAndMonths(
          record && record[PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_THRESHOLD],
          record && record[PROB_GRAPH_TABLE_IDS.EARLY_CLAIM_REFERENCE],
        ), 'No Data Available',
      )}
    </div>),
  }];
};
const specialistHead = ({classes, SPECIALIST_IDS}) => {
  return [{
    title: 'MMA Code & Description',
    id: SPECIALIST_IDS.REQUEST,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    headDisplay: () => (
      <CustomTableHead
        title='MMA Code & Description'
        id={SPECIALIST_IDS.REQUEST}
        isMandatory={false}
        classes={classes}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[SPECIALIST_IDS.REQUEST])}
    </div>),
  }, {
    title: 'Requesting Specialist',
    id: SPECIALIST_IDS.SPECIALIST,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[SPECIALIST_IDS.SPECIALIST])}
    </div>),
  }, {
    title: 'Requested On',
    id: SPECIALIST_IDS.REQUEST_DATE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(convertDateString(
        record && record[SPECIALIST_IDS.REQUEST_DATE],
        DBDateString,
        UIOutputFormat,
      ))}
    </div>),
  }];
};

const stage2IBHead = ({classes, S2_IB_IDS}) => {
  return [{
    title: 'Medical Plan',
    id: S2_IB_IDS.MEDICAL_PLAN,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[S2_IB_IDS.MEDICAL_PLAN])}
    </div>),
  }, {
    title: 'Annual Limit Balance (RM)',
    id: S2_IB_IDS.ANNUAL_LIMIT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => <div className={classes.row}>{
      roundNumber({
        number: record[S2_IB_IDS.ANNUAL_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</div>,
  }, {
    title: 'Lifetime Limit Balance (RM)',
    id: S2_IB_IDS.LIFETIME_LIMIT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => <div className={classes.row}>{
      roundNumber({
        number: record[S2_IB_IDS.LIFETIME_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</div>,
  }, {
    title: 'Disability Limit Balance (RM)',
    id: S2_IB_IDS.DISABILITY_LIMIT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => <div className={classes.row}>{
      roundNumber({
        number: record[S2_IB_IDS.DISABILITY_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</div>,
  }];
};

const getClaimHistoryHeads = ({classes, CLAIMS_HIST_IDS}) => {
  return [
    {
      title: 'GL No.',
      id: CLAIMS_HIST_IDS.GL_NUMBER,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.GL_NUMBER])}
      </div>),
    },
    {
      title: 'Claim No.',
      id: CLAIMS_HIST_IDS.CLAIM_NUMBER,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.CLAIM_NUMBER])}
      </div>),
    },
    {
      title: 'Product',
      id: CLAIMS_HIST_IDS.PRODUCT,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.PRODUCT])}
      </div>),
    },
    {
      title: 'Admission Date',
      id: CLAIMS_HIST_IDS.ADMISSION_DATE,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        <span className={classes.customDisplayValue}>{convertDateString(
          record[CLAIMS_HIST_IDS.ADMISSION_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      </div>),
    },
    {
      title: 'Discharge Date',
      id: CLAIMS_HIST_IDS.DISCHARGE_DATE,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        <span className={classes.customDisplayValue}>{convertDateString(
          record[CLAIMS_HIST_IDS.DISCHARGE_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      </div>),
    },
    {
      title: 'Hospital',
      id: CLAIMS_HIST_IDS.HOSPITAL,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.HOSPITAL])}
      </div>),
    },
    {
      title: 'Doctor',
      id: CLAIMS_HIST_IDS.DOCTOR,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.DOCTOR])}
      </div>),
    },
    {
      title: 'Diagnosis',
      id: CLAIMS_HIST_IDS.DIAGNOSIS,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.DIAGNOSIS])}
      </div>),
    },
    {
      title: 'Procedure',
      id: CLAIMS_HIST_IDS.TREATMENT,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.TREATMENT])}
      </div>),
    },
    {
      title: 'Paid Amount (RM)',
      id: CLAIMS_HIST_IDS.INCURRED_AMT,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {roundNumber({
          number: record[CLAIMS_HIST_IDS.INCURRED_AMT],
          isCheckEmpty: true,
          isFormatNumber: true,
          roundTo: 2,
        })}
      </div>),
    },
    {
      title: 'Claim Status',
      id: CLAIMS_HIST_IDS.CLAIM_STATUS,
      align: 'left',
      headClass: classes.head,
      columnClass: classes.row,
      display: (record) => (<div
        className={record[CLAIMS_HIST_IDS.IS_EXGRATIA] === 'Yes' ? classes.rowColor : classes.row}
      >
        {checkEmptyString(record[CLAIMS_HIST_IDS.CLAIM_STATUS])}
      </div>),
    },
  ];
};

const getS4S5Head = ({classes, S4_S5_IDS}) => {
  return [{
    title: 'Details',
    id: S4_S5_IDS.DETAILS,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 150,
    display: (record) => (<div className={classes.row}>
      <span>{record[S4_S5_IDS.DETAILS]}</span><br />
      <div className={classes.tableSubText}>
        <label>Definition: </label>
        <span>{record[S4_S5_IDS.DESCRIPTION]}</span>
      </div>
    </div>),
  }, {
    title: 'Recommendation',
    id: S4_S5_IDS.RECOMMENDATION,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[S4_S5_IDS.RECOMMENDATION])}
    </div>),
  }];
};

const getS4S5BMHead = ({classes, S4_S5_IDS}) => {
  return [{
    title: 'Details',
    id: S4_S5_IDS.DETAILS,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 150,
    display: (record) => (<div className={classes.row}>
      <span>{record[S4_S5_IDS.DETAILS]}</span><br />
      <div className={classes.tableSubText}>
        <label>Definition: </label>
        <span>{record[S4_S5_IDS.DESCRIPTION]}</span>
      </div>
    </div>),
  }, {
    title: 'Recommendation',
    id: S4_S5_IDS.RECOMMENDATION,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[S4_S5_IDS.RECOMMENDATION])}
    </div>),
  }, {
    title: 'AIA Historical Data Benchmark',
    id: S4_S5_IDS.AIA_BM,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[S4_S5_IDS.AIA_BM])}
    </div>),
  }, {
    title: 'AiDA Benchmark',
    id: S4_S5_IDS.AIDA_BM,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[S4_S5_IDS.AIDA_BM])}
    </div>),
  }];
};

const populateS4BM = ({userType, claimDetails}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return ([
    {
      detail: 'Unusual Length of Admission',
      desc: 'Length of Stay is longer than usual for the given diagnosis based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.UNUSUAL_LOS],
      aia_bm: claimDetails[CLAIM_360_IDS.UNUSUAL_LOS_AIA_BM],
      aida_bm: claimDetails[CLAIM_360_IDS.UNUSUAL_LOS_AIDA_BM],
      avg_bm: claimDetails[CLAIM_360_IDS.UNUSUAL_LOS_AVG_BM],
    },
  ]);
};

const populateS4 = ({userType, claimDetails}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return ([
    {
      detail: 'Low Occurrence of ICD & Age Combination',
      desc: 'The given diagnosis (ICD) is unusual in this age group based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.LOW_OCCURRENCE_ICD_AGE_COMBINATION],
    },
    {
      detail: 'Low Occurrence of ICD & Gender Combination',
      desc: 'The given diagnosis (ICD) is unusual in this gender group based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.LOW_OCCURRENCE_GENDER_COMBINATION],
    },
    {
      detail: 'Low Occurrence of ICD & Surgical Code Combination',
      desc: 'The given surgery is unusual for the given diagnosis (ICD) based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.LOW_OCCURRENCE_ICD_TOSP_COMBINATION],
    },
  ]);
};

const populateS5BM = ({userType, claimDetails}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return ([
    {
      detail: 'High Claim Cost',
      desc: 'The amount is higher than usual for the given diagnosis based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.HIGH_CLAIM_COST],
      aia_bm: claimDetails[CLAIM_360_IDS.HIGH_CLAIM_COST_AIA_BM],
      aida_bm: claimDetails[CLAIM_360_IDS.HIGH_CLAIM_COST_AIDA_BM],
      avg_bm: claimDetails[CLAIM_360_IDS.HIGH_CLAIM_COST_AVG_BM],
    },
  ]);
};

const populateS5 = ({userType, claimDetails}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return ([
    {
      detail: 'Similar Surgical Code',
      desc: 'Two or more surgeries which are not supposed to be performed together',
      reco: claimDetails[CLAIM_360_IDS.SIMILAR_TOSP],
    },
    {
      detail: 'High Average Room Charge',
      desc: 'The daily room charge is higher than the single ward rate',
      reco: claimDetails[CLAIM_360_IDS.HIGH_AVG_ROOM_CHARGE],
    },
    {
      detail: 'High Expense Category Incurred Amount',
      desc: 'The amount of certain expense category is higher than usual for the given diagnosis based on the historical data',
      reco: claimDetails[CLAIM_360_IDS.HIGH_EXPENSE_CATEGORY_INC_AMT],
    },
  ]);
};

const Assessment = ({claimDetails, glType, businessType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const userType = 'retail';

  const COLUMN_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];
  const PROB_GRAPH_TABLE_IDS = COLUMN_IDS['PROB_GRAPH_TABLE'];
  const SPECIALIST_IDS = COLUMN_IDS['SPECIALIST_TABLE'];
  const S2_IB_IDS = COLUMN_IDS['S2_IB_TABLE'];
  const S4_S5_IDS = COLUMN_IDS['S4_S5_TABLE'];
  const CLAIMS_HIST_IDS = COLUMN_IDS['CLAIM_HISTORY_TABLE'];

  const s1LeftMainList = stage1LeftMainList({userType, businessType, classes});
  const s1RightMainList = stage1RightMainList({userType, businessType, classes, glType});
  const s2LeftMainList = stage2LeftMainList({userType, classes});
  const s2RightMainList = stage2RightMainList({userType, classes});
  const s1PolicyExclusion = stage1PolicyExclusion({userType});
  const sFinalList = stage1FinalList({userType, classes});

  const pClaimLeftList = pendingClaimLeftList({userType});

  const s2IBHeads = stage2IBHead({classes, S2_IB_IDS});
  const s4s5Heads = getS4S5Head({classes, S4_S5_IDS});
  const s4s5BMHeads = getS4S5BMHead({classes, S4_S5_IDS});
  const s4BMData = populateS4BM({userType, claimDetails});
  const s5BMData = populateS5BM({userType, claimDetails});
  const s4Data = populateS4({userType, claimDetails});
  const s5Data = populateS5({userType, claimDetails});

  const probGraphHeads = probabilityGraphHead({classes, PROB_GRAPH_TABLE_IDS});

  const specHeads = specialistHead({classes, SPECIALIST_IDS});

  const clmHistory = getClaimHistoryHeads({classes, CLAIMS_HIST_IDS});

  const clmHistoryMessage = `Latest 10 claim records for similar condition for claims since ${businessType && businessType === 'IB' ? 'January 2017' : 'May 2017'}`;

  return (
    <Fragment>
      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 1 - Policy Eligibility</h3>
        <div className={classes.mainContainer}>
          <ul className={classes.listWrapper}>
            {s1LeftMainList.map((i, index) => (
              <LabelValue
                key={`${i.label}-key-${index}`}
                item={i}
                data={claimDetails}

              />
            ))}
          </ul>
          <ul className={classes.listWrapper}>
            {s1RightMainList.map((i, index) => (
              <LabelValue
                key={`${i.label}-key-${index}`}
                item={i}
                data={claimDetails}

              />
            ))}
          </ul>
        </div>
        {businessType && businessType === 'IB' &&
          (<ul className={classes.listWrapper}>
            {s1PolicyExclusion.map((i, index) => (
              <LabelValue
                key={`${i.label}-key-${index}`}
                item={i}
                data={claimDetails}

              />
            ))}
          </ul>)
        }

        <ul className={classes.listWrapper}>
          <Table
            className={classes.table}
            heads={probGraphHeads}
            data={
              claimDetails &&
              claimDetails[COLUMN_IDS.POTENTIAL_PRE_EXISTING_PROB_GRAPH] &&
              JSON.parse(claimDetails[COLUMN_IDS.POTENTIAL_PRE_EXISTING_PROB_GRAPH])
            }
          /><br />
        </ul>
        <ul className={classes.listWrapper}>
          <Table
            className={classes.table}
            heads={specHeads}
            data={
              claimDetails &&
              claimDetails[COLUMN_IDS.SPECIALIST_REQUEST] &&
              JSON.parse(claimDetails[COLUMN_IDS.SPECIALIST_REQUEST])
            }
          />
        </ul>

        <ul className={classes.listWrapper}>
          {sFinalList.map((i, index) => (
            <LabelValue
              key={`${i.label}-key-${index}`}
              item={i}
              data={claimDetails}

            />
          ))}
        </ul>
        <br /><br />
      </Card>
      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 2 - Limit Utilization & Balance</h3>
        {businessType && businessType === 'EB' &&
          (<div className={classes.mainContainer}>
            <ul className={classes.listWrapper}>
              {s2LeftMainList.map((i, index) => (
                <LabelValue
                  key={`${i.label}-key-${index}`}
                  item={i}
                  data={claimDetails}

                />
              ))}
            </ul>
            <ul className={classes.listWrapper}>
              {s2RightMainList.map((i, index) => (
                <LabelValue
                  key={`${i.label}-key-${index}`}
                  item={i}
                  data={claimDetails}

                />
              ))}
            </ul>
            <br /><br /><br /><br /><br />
          </div>)
        }
        <Table
          className={classes.table}
          heads={s2IBHeads}
          data={
            claimDetails &&
            claimDetails[COLUMN_IDS.STAGE2] &&
            JSON.parse(claimDetails[COLUMN_IDS.STAGE2])
          }
        />
        <br /><br />
      </Card>

      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 3 - Claim History</h3>
        <ul className={classNames(classes.listWrapper, classes.listWrapperLocal)}>
          <span className={classes.label2}>{clmHistoryMessage}</span>
          <div className={classes.mainContainer}>
            <ul className={classes.listWrapper}>
              {pClaimLeftList.map((i, index) => (
                <LabelValue
                  key={`${i.label}-key-${index}`}
                  item={i}
                  data={claimDetails}
                />
              ))}
            </ul>
          </div>
          <li>
            <Table
              className={classes.table}
              heads={clmHistory}
              data={
                claimDetails &&
                claimDetails[COLUMN_IDS.CLM_HIST_LIST] &&
                claimDetails[COLUMN_IDS.CLM_HIST_LIST]
              }
            />
          </li>
        </ul>
        <br /><br />
      </Card>

      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 4 - Medical Necessity</h3>
        <ul className={classNames(classes.listWrapper, classes.listWrapperLocal)}>
          <Table
            className={classes.table}
            heads={s4s5BMHeads}
            data={claimDetails && s4BMData}
          />
        </ul>
        <ul className={classNames(classes.listWrapper, classes.listWrapperLocal)}>
          <Table
            className={classes.table}
            heads={s4s5Heads}
            data={claimDetails && s4Data}
          />
        </ul>
      </Card>

      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 5 - Over Charging / Over Servicing</h3>
        <ul className={classNames(classes.listWrapper, classes.listWrapperLocal)}>
          <Table
            className={classes.table}
            heads={s4s5BMHeads}
            data={claimDetails && s5BMData}
          />
        </ul>
        <ul className={classNames(classes.listWrapper, classes.listWrapperLocal)}>
          <Table
            className={classes.table}
            heads={s4s5Heads}
            data={claimDetails && s5Data}
          />
        </ul>
      </Card>
    </Fragment>
  );
};

export default Assessment;
