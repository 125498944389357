import React, {useState, useEffect} from 'react';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  classNames,
  createUseStyles,
  Dropdown,
  Icon,
  IconNames,
  Input,
  INPUT_TYPE,
} from 'aida-components';
import _ from 'lodash';
const useStyles = createUseStyles((theme)=>({
  modal: {
    ...theme.modal,
    backgroundColor: theme.default.color.background,
    borderRadius: 5,
    padding: 30,
  },
  card: {
    marginBottom: 20,
  },
  addBtn: {
    display: 'inline-block',
    verticalAlign: 'middle',
    zIndex: 0,
  },
  cell: {
    overflow: 'initial',
  },
  input: {
    minWidth: 100,
  },
  btnAdd: {
    marginTop: 20,
    width: '100%',
  },
  delete: {
    '& svg': {
      fill: theme.default.color.error,
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  footerBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& >button': {
      marginLeft: 10,
    },
  },
  lineItemCategoryCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  oldChangeFrom: {
    textDecoration: 'line-through',
    color: 'red',
  },
  newName: {
    color: 'green',
  },
  unknown: {
    color: 'red',
  },
  lineItemValue: {
    width: 30,
    display: 'inline-block',
  },
}));
const AddDropdown = ({id, selectedvalue, onChangeNPIValue}) => {
  const classes = useStyles();
  const optionsForNPI = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ];
  const [npiValue, setNpiValue] = useState(selectedvalue);
  const onDropdownChange= (option) => {
    const npiValue = option && option.value;
    onChangeNPIValue && onChangeNPIValue({
      id,
      npiValue,
    });
  };
  return (
    <Card>
      <ul className={classes.ulList}>
        <li>
          <Input
            className={classes.input}
            type={INPUT_TYPE.SELECT}
            options={optionsForNPI}
            onChange={onDropdownChange}
            defaultValue={{value: selectedvalue, label: selectedvalue}}
          />
        </li>
      </ul>
    </Card>
  );
};

export const CanEditThisValue = ({id, old_npi_value, new_npi_value, is_current_change, onRowUpdate}) => {
  const classes = useStyles();
  const [newNPIValue, setNewNPIValue] = useState(old_npi_value);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const onChangeNPIValue= ({id, npiValue}) => {
    setNewNPIValue(npiValue);
    setIsDropdownOpened(false);
    onRowUpdate && onRowUpdate({id, npiValue});
  };
  return (
    <div className={classes.lineItemCategoryCol}>
      {newNPIValue && newNPIValue === old_npi_value && (
        <div>
          <span className={classes.lineItemValue}>{old_npi_value}</span>&nbsp;&nbsp;
        </div>
      )}
      {newNPIValue && newNPIValue !== old_npi_value && (
        <div>
          <s style={{color: 'red'}}>{old_npi_value}</s>&nbsp;&nbsp;
          <span style={{color: 'green'}}>{newNPIValue}</span>&nbsp;
        </div>
      )}

      <Dropdown
        isOpen={isDropdownOpened}
        onTrigger={setIsDropdownOpened}
        trigger={
          <Button
            text='Update'
            className={classes.addBtn}
            type={BUTTON_TYPE.LINK}
            size={BUTTON_SIZE.SMALL}
            icon={<Icon name={IconNames.Edit} />}
            iconOnly
          />
        }
        dropdown={
          <AddDropdown
            id={id}
            selectedvalue={newNPIValue}
            onChangeNPIValue={onChangeNPIValue}
          />
        }
      />
    </div>
  );
};
