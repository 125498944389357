import React, {useEffect, useState} from 'react';
import {MdCancel, MdDelete} from 'react-icons/md';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  classNames,
  Dropdown, toast,
  useAuthentication,
  useRequest,
} from 'aida-components';
import {formatBytes, getTime} from 'helper/utils';
import DownloadCSV from 'components/download-csv';

import styles from './file-upload.module.scss';
import find from 'lodash/find';
import {BUSINESS_TYPES} from 'views/file-upload/index';
import useURL from 'hooks/useURL';
import {getEditPermission, getExportPermission, VIEWS} from 'helper/routers';

const fileStatus = {
  0: 'Denied',
  1: 'Importing',
  2: 'Wait for approval',
  3: 'Imported',
  4: 'Import failed',
};

const getFileUploadTypeDisplayName = (category, fileType) => {
  const bizObject = find(BUSINESS_TYPES, {title: category}).data;
  const fileObject = find(bizObject, {value: fileType});
  if (fileObject && fileObject.value) {
    return fileObject.label;
  }
  return '';
};

const FileItem = ({
  UPLOAD_FILE_NAME,
  UPLOAD_FILE_SIZE,
  UPLOAD_DATE,
  FILE_STATUS,
  CATEGORY,
  UPLOAD_FILE_TYPE,
  onDelete,
}) => {
  const API_URL = useURL();
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(authUserInfo.role, VIEWS.FILE_UPLOAD.key);
  const canExport = getExportPermission(authUserInfo.role, VIEWS.FILE_UPLOAD.key);

  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] = useRequest(API_URL.DELETE_FILE_URL, {
    verb: 'delete',
    config: {
      params: {
        file_name: UPLOAD_FILE_NAME,
      },
    },
  });

  useEffect(() => {
    if (status === ERROR) {
      toast.error((response.data && response.data.detail) || `Can't delete "${UPLOAD_FILE_NAME}". Please try again.`);
    }
    if (status === SUCCESS) {
      onDelete && onDelete();
      setIsDeleteUserOpen(false);
      toast.success(`"${UPLOAD_FILE_NAME}" was deleted successfully`);
    }
  }, [status]);

  const onDeleteCancel = () => setIsDeleteUserOpen(false);

  return (
    <div className={styles.file}>
      <div className={styles.foldedCorner}>
        <span>CSV</span>
      </div>
      <div className={styles.info}>
        <h4>{UPLOAD_FILE_NAME}</h4>
        <p>
          <span>{formatBytes(UPLOAD_FILE_SIZE)}</span>
          <span>{getTime(UPLOAD_DATE)}</span>
          <span
            className={classNames(styles.tag, {
              [styles.valid]: parseInt(FILE_STATUS, 10) === 1 || parseInt(FILE_STATUS, 10) === 3,
              [styles.invalid]: parseInt(FILE_STATUS, 10) === 0 || parseInt(FILE_STATUS, 10) === 4,
              [styles.waitForApproval]: parseInt(FILE_STATUS, 10) === 2,
            })}
          >{fileStatus[FILE_STATUS]}</span>
        </p>
        <ul className={styles.tags}>
          <li className={classNames(styles.tag1, styles.claimType)}>{CATEGORY}</li>
          <li className={classNames(styles.tag1, styles.claimType)}>
            {getFileUploadTypeDisplayName(CATEGORY, UPLOAD_FILE_TYPE)}
          </li>
        </ul>
      </div>
      {(canEdit || canExport) && (
        <ul className={styles.iconWrapper}>
          {canExport && (<li>
            <DownloadCSV
              params={{file_name: UPLOAD_FILE_NAME}}
              url={API_URL.FILE_CONTENT_URL}
              iconOnly
              className={styles.button}
              prefix={UPLOAD_FILE_NAME.replace('.csv', '')}
            />
          </li>)}

          {canEdit && (<li>
            <Dropdown
              isOpen={isDeleteUserOpen}
              onTrigger={setIsDeleteUserOpen}
              trigger={
                <Button
                  className={styles.button}
                  type={BUTTON_TYPE.ICON}
                  size={BUTTON_SIZE.SMALL}
                  icon={<MdDelete size={15} />}
                />
              }
              dropdown={
                <Card className={styles.form}>
                  <p>{`Are you sure want to delete "${UPLOAD_FILE_NAME}?"`}</p>
                  <div className={styles.btnWrapper}>
                    <Button
                      className={styles.realDeleteBtn}
                      type={BUTTON_TYPE.LINK}
                      text='Delete'
                      size={BUTTON_SIZE.SMALL}
                      iconPosition='left'
                      icon={<MdDelete size={15} />}
                      onClick={makeRequest}
                      disabled={status === FETCHING}
                    />
                    <Button
                      className={styles.cancelBtn}
                      type={BUTTON_TYPE.LINK}
                      text='Cancel'
                      size={BUTTON_SIZE.SMALL}
                      iconPosition='left'
                      icon={<MdCancel size={15} />}
                      onClick={onDeleteCancel}
                    />
                  </div>
                </Card>
              }
            />
          </li>)}

        </ul>
      )}
    </div>
  );
};

export default FileItem;
