import {
  Button,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  Dropdown,
  formatNumber,
  Icon,
  IconNames,
  toast,
  useRequest,
} from 'aida-components';
import {Fragment, useEffect, useState} from 'react';
import useURL from 'hooks/useURL';
import {getTime} from 'helper/utils';
import Modal from 'components/modal';

const useStyles = createUseStyles((theme) => ({
  btnWrapper: {
    position: 'relative',
  },
  count: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#fff',
    fontFamily: 'RobotoCondensed',
    fontSize: 10,
    padding: [2, 2],
    borderRadius: 3,
    background: theme.default.color.primary,
  },
  list: {
    maxHeight: 500,
    maxWidth: 300,
    overflow: 'auto',
  },
  item: {
    borderBottom: [1, 'dashed', '#eaeaea'],
    marginBottom: 10,
    paddingBottom: 10,
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: [0, 'dashed', '#eaeaea'],
      marginBottom: 0,
      paddingBottom: 0,
    },
    '&>h4': {
      fontFamily: 'RobotoCondensed',
      fontSize: 16,
      fontWeight: 600,
    },
    '& strong': {
      fontWeight: 600,
    },
    '&>p': {
      fontSize: 14,
      padding: [10, 0, 5, 0],
      lineHeight: '18px',
    },
    '&>span': {
      fontSize: 10,
      lineHeight: '10px',
    },
  },
  modalTitle: {
    marginBottom: 20,
    fontFamily: 'RobotoCondensed',
    fontSize: 24,
  },
  modalContent: {
    lineHeight: '20px',
    '& > strong': {
      fontWeight: 600,
    },
  },
  btnWrapper1: {
    marginTop: 20,
    textAlign: 'right',
    '& > button': {
      marginLeft: 20,
    },
  },
  dataList: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    '&>li': {
      '&>h5': {
        fontFamily: 'RobotoCondensed',
        fontWeight: 600,
        marginBottom: 10,
      },
    },
  },
  contentList: {
    '& > li': {
      padding: [5, 0],
      '&>span': {
        display: 'block',
        fontFamily: 'RobotoCondensed',
        fontSize: 13,
        fontWeight: 300,
      },
      '&>p': {
        marginTop: 5,
        fontWeight: 600,
      },
    },
  },
}));

const TEXTS = {
  old_stp_setting: 'Current Auto Adjudication Setting',
  new_stp_setting: 'New Auto Adjudication Setting',
  stp_approve_mc: 'APPROVE - Machine Confidence',
  stp_approve_amt: 'APPROVE - Claim Amount',
  stp_reject_mc: 'REJECT - Machine Confidence',
  stp_reject_amt: 'REJECT - Claim Amount',
  fb_tolerance_amt: '+/- Y($)',
  fb_tolerance_perc: '+/- X(%)',
  fb_tolerance_selection: 'X% or $Y - whichever is',
  old_fb_setting: 'Current Pre-Auth Fee Tolerance Setting',
  new_fb_setting: 'New Pre-Auth Fee Tolerance Setting',
};

const ApprovalModel = ({
  EDIT_DATA,
  ACTION_TYPE,
  REQUESTER_ID,
  ID,
  onCloseModal,
}) => {
  const classes = useStyles();
  const API_URL = useURL();
  const [approvalFlag, setApprovalFlag] = useState();
  const URL = {
    FileUpload: API_URL.APPROVE_FILE_UPLOAD_URL,
    STPSave: API_URL.APPROVE_STP_CONDITION_URL,
    FBSave: API_URL.APPROVE_BENCHMARK_SETTING_URL,
    ModelSelect: API_URL.MODEL_APPROVE_URL,
  };

  const [{status}, makeRequest, {SUCCESS, ERROR}] = useRequest(
    URL[ACTION_TYPE],
    {
      verb: 'post',
      params: {
        approval_id: ID,
        approval_flag: approvalFlag,
      },
    },
  );

  useEffect(() => {
    approvalFlag && makeRequest();
  }, [approvalFlag]);

  useEffect(() => {
    if (status === SUCCESS) {
      if (approvalFlag === 'Y') {
        toast.success('You have approved successfully.');
      } else {
        toast.success('You have denied successfully.');
      }
      onCloseModal();
    } else if (status === ERROR) {
      toast.error(
        'There is an error when approving the change. Please try again.',
      );
    }
  }, [status]);

  const onDeny = () => setApprovalFlag('N');
  const onApprove = () => setApprovalFlag('Y');

  return (
    <Modal>
      <Card>
        <h3 className={classes.modalTitle}>Warning!</h3>
        {ACTION_TYPE === 'FileUpload' && (
          <p className={classes.modalContent}>
            Are you sure you want to approve the system to read the file:{' '}
            <strong>{EDIT_DATA}</strong>?
          </p>
        )}
        {(ACTION_TYPE === 'STPSave' || ACTION_TYPE === 'FBSave') && (
          <Fragment>
            <p className={classes.modalContent}>
              Are you sure you want to approve these changes:
            </p>
            <ul className={classes.dataList}>
              {Object.keys(JSON.parse(EDIT_DATA)).map((key) => (
                <li key={key}>
                  <h5>{TEXTS[key]}</h5>
                  <ul className={classes.contentList}>
                    {Object.keys(JSON.parse(EDIT_DATA)[key]).map((itemKey) => (
                      <li key={itemKey}>
                        <span>{TEXTS[itemKey]} :</span>
                        <p>
                          {itemKey.indexOf('amt') >= 0 ?
                            formatNumber(JSON.parse(EDIT_DATA)[key][itemKey]) :
                            itemKey.indexOf('perc') >= 0 ||
                            itemKey.indexOf('mc') >= 0 ?
                              `${JSON.parse(EDIT_DATA)[key][itemKey]}%` :
                              JSON.parse(EDIT_DATA)[key][itemKey]}
                        </p>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Fragment>
        )}
        {ACTION_TYPE === 'ModelSelect' && (
          <p className={classes.modalContent}>
            Are you sure you want to active this model version:
            <strong>{EDIT_DATA}</strong>?
          </p>
        )}
        <div className={classes.btnWrapper1}>
          <Button text='Deny' type={BUTTON_TYPE.SECONDARY} onClick={onDeny} />
          <Button
            text='Approve'
            type={BUTTON_TYPE.PRIMARY}
            onClick={onApprove}
          />
          <Button
            text='Cancel'
            type={BUTTON_TYPE.LINK}
            onClick={onCloseModal}
          />
        </div>
      </Card>
    </Modal>
  );
};

const NotificationItem = ({
  ACTION_TYPE,
  REQUESTER_ID,
  REQUESTED_DATE,
  EDIT_DATA,
  index,
  onClick,
}) => {
  const classes = useStyles();
  const onNotificationClick = () => onClick && onClick(index);
  return (
    <li onClick={onNotificationClick} className={classes.item}>
      <h4>{ACTION_TYPE}</h4>
      {ACTION_TYPE === 'FileUpload' && (
        <p>
          <strong>{EDIT_DATA}</strong> has been uploaded.{' '}
          <strong>{REQUESTER_ID}</strong> requires <strong>your</strong>{' '}
          approval.
        </p>
      )}
      {ACTION_TYPE === 'STPSave' && (
        <p>
          A new Auto Adjudication setting has been set. <strong>{REQUESTER_ID}</strong>{' '}
          requires <strong>your</strong> approval.
        </p>
      )}
      {ACTION_TYPE === 'FBSave' && (
        <p>
          A new Pre-Auth Fee Tolerance Setting has been set.{' '}
          <strong>{REQUESTER_ID}</strong> requires <strong>your</strong>{' '}
          approval.
        </p>
      )}
      {ACTION_TYPE === 'ModelSelect' && (
        <p>
          <strong>{EDIT_DATA}</strong> has been initiated.{' '}
          <strong>{REQUESTER_ID}</strong> requires <strong>your</strong>{' '}
          approval.
        </p>
      )}
      <span>{getTime(REQUESTED_DATE)}</span>
    </li>
  );
};

const Notification = () => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const API_URL = useURL();

  const classes = useStyles();
  const [showModal, setShowModal] = useState();

  const [{status, response}, makeRequest, {SUCCESS}] =
    useRequest(API_URL.USER_NOTIFICATION_URL);

  useEffect(() => {
    makeRequest();
  }, []);

  const onItemClick = (index) => setShowModal(index);
  const onCloseModal = () => {
    setShowModal();
    makeRequest();
  };

  return (
    status === SUCCESS &&
    response.data &&
    response.data.list.length > 0 && (
      <Fragment>
        <Dropdown
          isOpen={isDropdownOpened}
          onTrigger={setIsDropdownOpened}
          trigger={
            <div className={classes.btnWrapper}>
              <Button
                className={classes.icon}
                type={BUTTON_TYPE.ICON}
                icon={<Icon name={IconNames.Notification} />}
              />
              <span className={classes.count}>{response.data.list.length}</span>
            </div>
          }
          dropdown={
            <Card className={classes.list}>
              <ul>
                {response.data.list.map((item, index) => (
                  <NotificationItem
                    key={item.ID}
                    {...item}
                    index={index}
                    onClick={onItemClick}
                  />
                ))}
              </ul>
            </Card>
          }
        />
        {showModal >= 0 && (
          <ApprovalModel
            {...response.data.list[showModal]}
            onCloseModal={onCloseModal}
          />
        )}
      </Fragment>
    )
  );
};

export default Notification;
