import {Fragment, useEffect, useState} from 'react';
import {MdCached, MdChevronLeft, MdChevronRight} from 'react-icons/md';
import {
  AILoading,
  Button,
  BUTTON_TYPE,
  Card,
  formatNumber,
  Input,
  INPUT_TYPE,
  toast,
  useAuthentication,
} from 'aida-components';
import {getEditPermission, VIEWS} from 'helper/routers';
import useURL from 'hooks/useURL';
import ApprovalList from 'components/approval-list';
import Modal from 'components/modal';
import styles from './stp-conditions.module.scss';
import useRequest from 'hooks/useRequest';

const STPForm = () => {
  const API_URL = useURL();

  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.STP_CONDITIONS.key,
  );
  const [approvedClaimAmount, setApprovedClaimAmount] = useState();
  const [approvedMachineConfidence, setApprovedMachineConfidence] = useState();

  const [declinedClaimAmount, setDeclinedClaimAmount] = useState();
  const [declinedMachineConfidence, setDeclinedMachineConfidence] = useState();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.GET_STP_CONDITION_URL);

  useEffect(()=>makeRequest(), []);
  useEffect(() => {
    if (status === SUCCESS) {
      setApprovedClaimAmount(response.data && response.data.stp_approve_amt || 0);
      setApprovedMachineConfidence(response.data && response.data.stp_approve_mc || 0);
      setDeclinedClaimAmount(response.data && response.data.stp_reject_amt || 0);
      setDeclinedMachineConfidence(response.data && response.data.stp_reject_mc || 0);
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t get the Auto Adjudication settings. Please try again.',
      );
    }
  }, [status, response]);

  const onReset = () => {
    if (response.data) {
      setApprovedClaimAmount(response.data.stp_approve_amt || 0);
      setApprovedMachineConfidence(response.data.stp_approve_mc || 0);
      setDeclinedClaimAmount(response.data.stp_reject_amt || 0);
      setDeclinedMachineConfidence(response.data.stp_reject_mc || 0);
    }
  };
  const onApprovedMachineConfidenceChange = (value) =>setApprovedMachineConfidence(value);
  const onApprovedClaimAmountChange = (value) =>setApprovedClaimAmount(value);
  const onDeclinedMachineConfidenceChange = (value) =>setDeclinedMachineConfidence(value);
  const onDeclinedClaimAmountChange = (value) =>setDeclinedClaimAmount(value);
  return (
    <Card className={styles.wrapper}>
      {
        status === FETCHING && <div className={styles.loadingWrapper}>
          <AILoading />
        </div>
      }
      {
        status === SUCCESS && <Fragment>
          <h3 className={styles.subTitle}>Approve</h3>
          <ul className={styles.twoColumns}>
            {!canEdit && (
              <Fragment>
                <li className={styles.label}>
              Machine Confidence {' >= '}{' '}
                  <strong>{approvedMachineConfidence}%</strong>
                </li>
                <li className={styles.label}>
              Claim Amount {' <= '} <strong>{formatNumber(approvedClaimAmount)}</strong>
                </li>
              </Fragment>
            )}
            {canEdit && (
              <Fragment>
                <li>
                  <Input
                    label='Machine Confidence'
                    type={INPUT_TYPE.SLIDER}
                    min={0}
                    max={100}
                    marks={{0: '0%', 50: '50%', 75: '75%', 100: '100%'}}
                    step={1}
                    icon={<MdChevronRight size={35} />}
                    value={approvedMachineConfidence}
                    onChange={onApprovedMachineConfidenceChange}
                  />
                </li>
                <li>
                  <Input
                    label='Claim Amount'
                    type={INPUT_TYPE.TEXT}
                    placeholder='S$'
                    icon={<MdChevronLeft size={35} />}
                    onChange={onApprovedClaimAmountChange}
                    value={approvedClaimAmount}
                  />
                </li>
              </Fragment>
            )}
          </ul>
          <br />
          <h3 className={styles.subTitle}>Reject</h3>
          <ul className={styles.twoColumns}>
            {!canEdit && (
              <Fragment>
                <li className={styles.label}>
              Machine Confidence {' >= '}{' '}
                  <strong>{declinedMachineConfidence}%</strong>
                </li>
                <li className={styles.label}>
              Claim Amount {' <= '} <strong>{formatNumber(declinedClaimAmount)}</strong>
                </li>
              </Fragment>
            )}
            {canEdit && (
              <Fragment>
                <li>
                  <Input
                    label='Machine Confidence'
                    type={INPUT_TYPE.SLIDER}
                    min={0}
                    max={100}
                    marks={{0: '0%', 50: '50%', 75: '75%', 100: '100%'}}
                    step={1}
                    icon={<MdChevronRight size={35} />}
                    value={declinedMachineConfidence}
                    onChange={onDeclinedMachineConfidenceChange}
                  />
                </li>
                <li>
                  <Input
                    label='Claim Amount'
                    type={INPUT_TYPE.TEXT}
                    placeholder='S$'
                    icon={<MdChevronLeft size={35} />}
                    onChange={onDeclinedClaimAmountChange}
                    value={declinedClaimAmount}
                  />
                </li>
              </Fragment>
            )}
          </ul>
        </Fragment>
      }
      {canEdit && response && (<SaveSetting
        stp_approve_amt={approvedClaimAmount}
        stp_approve_mc={approvedMachineConfidence}
        stp_reject_amt={declinedClaimAmount}
        stp_reject_mc={declinedMachineConfidence}
        old_stp_setting={response && response.data ? response.data : {
          stp_approve_amt: 0,
          stp_approve_mc: 0,
          stp_reject_amt: 0,
          stp_reject_mc: 0,
        }}
        onReset={onReset}
      />)}
    </Card>
  );
};

const SaveSetting = ({old_stp_setting, stp_approve_amt, stp_approve_mc, stp_reject_amt, stp_reject_mc, onReset}) => {
  const API_URL = useURL();
  const [manager, setManager] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {authUserInfo} = useAuthentication();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.SAVE_STP_CONDITION_URL, {
      verb: 'post',
      params: {
        old_stp_setting,
        new_stp_setting: {
          stp_approve_amt,
          stp_approve_mc,
          stp_reject_amt,
          stp_reject_mc},
      },
      config: {
        headers: {
          lan_id: authUserInfo.email,
          approver_id: manager,
        },
      },
    });

  const onSelectManager = ({value}) => setManager(value);
  const onCloseModal = () => {
    setIsOpenModal(false);
    setManager();
  };
  const onOpenModal = () => setIsOpenModal(true);

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success('Auto Adjudication Conditions settings are sent successful.');
      setIsOpenModal(false);
    } else if (status === ERROR) {
      toast.error((response.data && response.data.detail) || 'Can\'t sent Auto Adjudication Conditions settings. Please try again.');
    }
  }, [status, response]);

  return <div className={styles.popupBtnWrapper}>
    <Button text='Reset' type={BUTTON_TYPE.LINK} onClick={onReset} />
    <Button
      text='Save'
      type={BUTTON_TYPE.PRIMARY}
      onClick={onOpenModal}
    />
    {isOpenModal && (
      <Modal>
        <Card>
          <h3 className={styles.modalTitle}>Warning!</h3>
          <p className={styles.modalContent}>
            You are going to update the Auto Adjudication Conditions setting.
          </p>
          <p className={styles.modalContent}>Please choose a manager to approve the change</p>
          <ApprovalList onSelect={onSelectManager} role={authUserInfo.role} screen='stp_conditions'/>
          <div className={styles.btnWrapper}>
            <Button text='Cancel' type={BUTTON_TYPE.LINK}
              onClick={onCloseModal} />
            <Button
              text='Request'
              disabled={!manager || status === FETCHING}
              type={BUTTON_TYPE.PRIMARY}
              onClick={makeRequest}
              icon={
                status === FETCHING && (
                  <MdCached className={styles.spin} size={23} />
                )
              }
            />
          </div>
        </Card>
      </Modal>
    )}

  </div>;
};

export default STPForm;
