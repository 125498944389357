import {Fragment} from 'react';
import {Col, Row} from 'react-grid-system';
import ModelHistory from 'views/model-control/model-history';
import styles from 'views/model-control/model-control.module.scss';

const ModelControl = () => {
  return (
    <Fragment>
      <Row align='start'>
        <Col md={8}>
          <h2 className={styles.title}>Model Management</h2>
        </Col>
      </Row>
      <Row align='start'>
        <Col md={8} offset={{md: 2}}>
          <ModelHistory/>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ModelControl;
