import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';

import {AILoading} from 'aida-components';
import Empty from 'components/empty';

const colors = {
  correctPredictions: '#008000',
  stp: '#008000',
  nonSTP: '#DC143C',
  incorrectPredictions: '#DC143C',
};
const legends = {
  correctPredictions: 'Match',
  stp: 'AI Auto Adjudication Rate',
  nonSTP: 'AI Non-Auto Adjudication Rate',
  incorrectPredictions: 'Mismatch',
};

const Pie = ({data, isLoading, title}) => {
  const getChartOpts = () => ({
    chart: {
      type: 'pie',
    },
    title: {
      text: title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f} %',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        colorByPoint: true,
        center: ['50%', '50%'],
        size: '70%',
        data: Object.keys(data).map((key) => ({name: legends[key], y: parseFloat(data[key]), color: colors[key]})),
      },
    ],
  });

  if (isLoading) {
    return <AILoading />;
  } else if (_.isEmpty(data)) {
    return <Empty text='No chart data found.' />;
  }

  return <HighchartsReact options={getChartOpts()} highcharts={Highcharts} />;
};

export default Pie;
