import {useAppStyles} from 'helper/app-styles';

export const useStyles = {
  ...useAppStyles,
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& >ul': {
      width: '50%',
    },
  },
  stageTitle: ({theme}) => ({
    ...theme.typography.title,
    fontSize: 18,
    marginTop: 30,
  }),
  stageSubTitle: ({theme}) => ({
    ...theme.typography.title,
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20,
  }),
  label: {
    fontWeight: 400,
    color: '#999',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '35%',
    boxSizing: 'border-box',
  },
  value: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '65%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: 20,
  },
  customDisplayValue: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '65%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
};
