import {formatNumber, moment} from 'aida-components';
import _ from 'lodash';

export const secretKey = 'lovethetime';
export const DateFormat = 'DD/MM/YYYY';
export const DBDateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const DBDateString = 'YYYY-MM-DD';
export const DBDateFormat2 = 'YYYY-MM-DD HH:mm:ss.SSS';
export const UIOutputFormat = 'DD-MMM-YYYY';

export const roundNumber = ({
  prefix = '',
  number,
  roundTo = 2,
  suffix = '',
  isCheckEmpty = false,
  isFormatNumber = false,
  isMultiplyBy100 = false,
}) => {
  if (number && number !== '') {
    return addPrefixOrSuffixToString({
      prefix: prefix,
      text: isFormatNumber ?
        (isMultiplyBy100 ? formatNumber((Number(number) * 100).toFixed(roundTo)) :
          formatNumber(Number(number).toFixed(roundTo))) :
        (isMultiplyBy100 ? (Number(number) * 100).toFixed(roundTo) : Number(number).toFixed(roundTo)),
      suffix: suffix,
    });
  } else if (number === 0) {
    return addPrefixOrSuffixToString({
      prefix: prefix,
      text: Number('0.00').toFixed(roundTo),
      suffix: suffix,
    });
  }
  return isCheckEmpty ? '-' : '';
};

export const addPrefixOrSuffixToString = ({
  prefix = '',
  suffix = '',
  text,
}) => {
  if (text) {
    return prefix + text + suffix;
  }
  return '';
};

export const checkEmptyString = (text, blank_message='-') => {
  if (!isBlank(text)) {
    return text.toString().trim();
  }

  return blank_message;
};

export const parseLoginQueryString = (query) => {
  return {
    userName: query.get('username'),
    userTypeCode: query.get('userType'),
    token: query.get('token'),
  };
};

export const remapYesNo = (text) => {
  if (!isBlank(text)) {
    if (text === 'Y') {
      return 'Yes';
    }
    if (text === 'N') {
      return 'No';
    }
  }
  return text;
};

export const isBlank = (text) => {
  return !(text && text.toString() && text.toString().trim().length > 0);
};

export const splitByDelimiter = ({text, delimiter = '|'}) => {
  return checkEmptyString(text).split(delimiter);
};

export const getObjectParams = (obj) =>
  Object.keys(obj)
    .map(
      (key) =>
        obj[key] != null &&
        obj[key] !== false &&
        !_.isEmpty(obj[key].toString()) && {
          key: key,
          text: _.startCase(key) + '=' + obj[key],
        },
    )
    .filter(function(param) {
      return param !== false;
    });

const arrayHasClaimType = [
  'PayeeWatchlist',
  'NationalityWatchlist',
  'PlanAndComponent',
  'LineItemBlacklist',
  'RetailerBlacklist',
];
export const showClaimType = (businessType) =>
  arrayHasClaimType.indexOf(businessType) >= 0;

export const getTime = (time, days = 1) => {
  if (moment().diff(time, 'days') >= days) {
    return time;
  }
  return moment(time).fromNow();
};

export const formatDateDisplay = (date, format = 'DD/MM/YYYY hh:mm:ss A') => {
  if (date) {
    return moment(date).format(format);
  }
  return '';
};

export const convertDateString = (dateString, fromFormat = 'DD-MM-YYYY', toFormat = 'YYYY-MM-DD') => {
  if (isBlank(dateString)) {
    return '';
  }

  return moment(dateString, fromFormat).format(toFormat);
};

export const convertDaysToYearMonthDay = (days) => {
  if (typeof days !== 'number' || days <= 0) {
    return '-';
  }
  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);
  const remainingDays = (days % 365) - (months * 30);
  let result = '';
  if (years > 0) {
    result += years === 1 ? '1 Year' : `${years} Years`;
  }
  if (months > 0) {
    if (result !== '') result += ' ';
    result += months === 1 ? '1 Month' : `${months} Months`;
  }
  if (remainingDays > 0) {
    if (result !== '') result += ' ';
    result += remainingDays === 1 ? '1 Day' : `${remainingDays} Days`;
  }
  return result;
};

export const convertDateToYearMonthDayFormat = (date1, date2) => {
  if (!date1 || !date2) {
    return '-';
  }
  let years; let months; let days;
  date1 = new Date(date1);
  date2 = new Date(date2);
  let result = '';

  // find years
  years = date2.getFullYear() - date1.getFullYear();
  date1.setFullYear(date1.getFullYear() + years);

  // find months
  if (date1 > date2) {
    date1.setFullYear(date1.getFullYear() - 1);
    years--;
    months = date2.getMonth() + 12 - date1.getMonth();
  } else {
    months = date2.getMonth() - date1.getMonth();
  }
  date1.setMonth(date1.getMonth() + months);

  // find days
  if (date1 > date2) {
    date1.setMonth(date1.getMonth() - 1);
    months--;
    days = Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));
  } else {
    days = date2.getDate() - date1.getDate();
  }

  // Construct the result string
  if (years > 0) {
    result += years === 1 ? '1 Year ' : `${years} Years `;
  }
  if (months > 0) {
    result += months === 1 ? '1 Month ' : `${months} Months `;
  }
  if (days > 0) {
    result += days === 1 ? '1 Day' : `${days} Days`;
  }

  return result.trim(); // Trim the result to remove any extra whitespace
};
export const concatenateTheValue = (date1, date2) => {
  const formattedDate1 = convertDateString(date1, DBDateFormat, UIOutputFormat);
  const formattedDate2 = convertDateString(date2, DBDateFormat, UIOutputFormat);
  return `${formattedDate1} - ${formattedDate2}`;
};
export const findGracePeriod = (prem_ptdate, admission_date) => {
  if (!admission_date || !prem_ptdate) {
    return '-';
  }

  const MS_PER_DAY = 24 * 60 * 60 * 1000;
  const timeDiffInMs = new Date(admission_date) - new Date(prem_ptdate);
  const days = Math.floor(timeDiffInMs / MS_PER_DAY);

  if (days > 0) {
    return convertDateToYearMonthDayFormat(prem_ptdate, admission_date);
  } else {
    return '-';
  }
};

export const CSVtoArray = (text) => {
  const reValid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/; // eslint-disable-line
  const reValue =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g; // eslint-disable-line

  if (!reValid.test(text)) {
    return text.split(',');
  }
  const a = []; // Initialize array to receive values.
  text.replace(
    reValue, // "Walk" the string using replace with callback.
    function (m0, m1, m2, m3) { // eslint-disable-line
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, '\''));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ''; // Return empty string.
    },
  );
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push('');
  return a;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
