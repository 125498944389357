import React, {Fragment, useEffect, useState} from 'react';
import {MdChevronLeft, MdChevronRight, MdZoomOutMap} from 'react-icons/md';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  createUseStyles,
  Grid,
  GridItem,
  Modal, toast, useAuthentication, useRequest,
  useTheme,
} from 'aida-components';
import _ from 'lodash';
import {Col, Row} from 'react-grid-system';

import Column from './column';

import styles from './predictive-model-summary.module.scss';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import {PREDICTIVE_MODEL_SUMMARY} from '../temp-data';
import useURL from 'hooks/useURL';
import {getEditPermission, getExportPermission, VIEWS} from 'helper/routers';
import DownloadCSV from 'components/download-csv';
import {roundNumber} from 'helper/utils';

const useLocalStyles = createUseStyles({
  label: {
    textAlign: 'center',
  },
  align_grid: {
    marginBottom: 15,
  },
  card_title: {
    marginBottom: 10,
    fontSize: 14,
  },
  card_content: {
    fontSize: 12,
  },
  col_items: {
    textAlign: 'left',

    '& > div': {
      marginTop: 10,
      marginBottom: 10,
    },
  },
});

const chartTypes = ['modelStatistics', 'outlierFlag', 'declineReasons'];
const Chart = ({filters}) => {
  const API_URL = useURL();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [chartType, setChartType] = useState(0);
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
  );

  const canExport = getExportPermission(
    authUserInfo.role,
    VIEWS.PREDICTIVE_MODEL_SUMMARY.key,
  );

  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const params = pickBy(
    {
      ...filters,
      provider_type: filters && filters.hospital_type,
      ai_decision: filters.ai_decision && filters.ai_decision.join(','),
      outlier_detected:
        filters.outlier_detected && filters.outlier_detected.join(','),
      auto_adjudication_decision: filters.auto_adjudication_decision && filters.auto_adjudication_decision.join(','),
    },
    identity,
  );

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}, source] = useRequest(
    API_URL.PREDICTIVE_MODEL_SUMMARY_CHART_URL,
    {
      config: {
        params: {
          ...params,
        },
      },
    },
  );

  useEffect(() => {
    makeRequest();
    return () => {
      source && source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    if (status === ERROR && response.header) {
      toast.error(response.header.message);
    }
  }, [status]);

  const onCloseModal = () => setIsOpenModal(false);

  const onZoomModelStatisticsChart = () => {
    setIsOpenModal(true);
    setChartType(0);
  };
  const onZoomOutlierFlagChart = () => {
    setIsOpenModal(true);
    setChartType(1);
  };
  const onZoomNercReasonsChart = () => {
    setIsOpenModal(true);
    setChartType(2);
  };
  const onPrevChart = () => setChartType(chartType - 1);
  const onNextChart = () => setChartType(chartType + 1);

  return (
    <Fragment>
      <Grid gridArea={['c1 c1 c2 c2 c3 c3 c3 c3 c4 c4 c4 c4', 'c1 c1 c2 c2 c3 c3 c3 c3 c5 c5 c5 c5']} className={classes.align_grid}>
        <GridItem
          name="c1"
          component={
            <Card className={classes.label}>
              <div className={classes.card_title}>Total Claims Approved</div>
              <Row className={classes.card_content}>
                <Col md={7} className={classes.col_items}>
                  <div>Number of Claims: </div>
                  <div>Total Amount (MYR): </div>
                </Col>
                {status === SUCCESS && response.data && response.data.claimDetails && (
                  <Col className={classes.col_items}>
                    <div>{response.data.claimDetails.totalClaimsApproved} ({
                      response.data.claimDetails.totalClaims ?
                        Number(
                          (response.data.claimDetails.totalClaimsApproved / response.data.claimDetails.totalClaims) * 100).toFixed(2) : 0}%)</div>
                    <div>{roundNumber({
                      number: response.data.claimDetails.totalAmountApproved,
                      isFormatNumber: true,
                    })}</div>
                  </Col>
                )}
              </Row>
            </Card>
          }
        />
        <GridItem
          name="c2"
          component={
            <Card className={classes.label}>
              <div className={classes.card_title}>Total Claims Rejected</div>
              <Row className={classes.card_content}>
                <Col md={7} className={classes.col_items}>
                  <div>Number of Claims: </div>
                  <div>Total Amount (MYR): </div>
                </Col>
                {status === SUCCESS && response.data && response.data.claimDetails && (
                  <Col className={classes.col_items}>
                    <div>{response.data.claimDetails.totalClaimsRejected} ({
                      response.data.claimDetails.totalClaims ?
                        Number(
                          (response.data.claimDetails.totalClaimsRejected / response.data.claimDetails.totalClaims) * 100).toFixed(2) : 0}%)</div>
                    <div>{roundNumber({
                      number: response.data.claimDetails.totalAmountRejected,
                      isFormatNumber: true,
                    })}</div>
                  </Col>
                )}
              </Row>
            </Card>
          }
        />
        <GridItem
          name="c3"
          component={
            <Card className={classes.label}>
              <div className={classes.card_title}>Total No. of Outlier Claims</div>
              <Row className={classes.card_content}>
                <Col md={7} className={classes.col_items}>
                  <div>Number of Claims: </div>
                  <div>Total Amount (MYR): </div>
                </Col>
                {status === SUCCESS && response.data && response.data.claimDetails && (
                  <Col className={classes.col_items}>
                    <div>{response.data.claimDetails.totalNumOutliers} ({
                      response.data.claimDetails.totalClaims ?
                        Number(
                          (response.data.claimDetails.totalNumOutliers / response.data.claimDetails.totalClaims) * 100).toFixed(2) : 0}%)</div>
                    <div>{roundNumber({
                      number: response.data.claimDetails.totalAmountOutliers,
                      isFormatNumber: true,
                    })}</div>
                  </Col>
                )}
              </Row>
            </Card>
          }
        />
      </Grid>
      <Row align='normal' style={{display: 'flex', justifyContent: 'space-between'}}>
        {
          <Col md={4}>
            <Card>
              {(canEdit || canExport) && <DownloadCSV
                className={styles.downloadCsv}
                params={{
                  ...params,
                  chart_type: 'model_accuracy',
                }}
                url={API_URL.PREDICTIVE_MODEL_CHARTS_EXPORT_URL}
                prefix='model-statistics'
                text='Export to CSV'
                iconOnly={true}
              />}
              <Button
                className={styles.zoomBtn}
                type={BUTTON_TYPE.ICON}
                size={BUTTON_SIZE.SMALL}
                icon={<MdZoomOutMap size={20} />}
                onClick={onZoomModelStatisticsChart}
              />
              {<Column
                data={status === SUCCESS && response.data && response.data.modelStatistics}
                isLoading={status === FETCHING}
                title={'modelStatistics'}
                filters={filters}
              />}
            </Card>
          </Col>
        }
        {
          <Col md={4}>
            <Card>
              {(canEdit || canExport) && <DownloadCSV
                className={styles.downloadCsv}
                params={{
                  ...params,
                  chart_type: 'outlier_reason',
                }}
                url={API_URL.PREDICTIVE_MODEL_CHARTS_EXPORT_URL}
                prefix='top-10-outlier-reason'
                text='Export to CSV'
                iconOnly={true}
              />}

              <Button
                className={styles.zoomBtn}
                type={BUTTON_TYPE.ICON}
                size={BUTTON_SIZE.SMALL}
                icon={<MdZoomOutMap size={20} />}
                onClick={onZoomOutlierFlagChart}
              />
              {<Column
                data={
                  status === SUCCESS &&
                  response.data &&
                  response.data.outlierFlag
                }
                isLoading={status === FETCHING}
                title={'outlierFlag'}
                filters={filters}
              />}
            </Card>
          </Col>
        }
        {
          <Col md={4}>
            <Card>
              {(canEdit || canExport) && <DownloadCSV
                className={styles.downloadCsv}
                params={{
                  ...params,
                  chart_type: 'decline_code',
                }}
                url={API_URL.PREDICTIVE_MODEL_CHARTS_EXPORT_URL}
                prefix='top-10-reject-codes'
                text='Export to CSV'
                iconOnly={true}
              />
              }
              <Button
                className={styles.zoomBtn}
                type={BUTTON_TYPE.ICON}
                size={BUTTON_SIZE.SMALL}
                icon={<MdZoomOutMap size={20} />}
                onClick={onZoomNercReasonsChart}
              />
              {<Column
                data={status === SUCCESS && response.data && response.data.declineReasons}
                isLoading={status === FETCHING}
                title={'declineReasons'}
                filters={filters}
              />}
            </Card>
          </Col>
        }
      </Row>

      {isOpenModal && (
        <Modal className={styles.modal}>
          <Card>
            <div className={styles.arrowWrapper}>
              {chartTypes[chartType - 1] && (
                <Button
                  className={styles.prevChart}
                  type={BUTTON_TYPE.LINK}
                  size={BUTTON_SIZE.SMALL}
                  text={_.startCase(chartTypes[chartType - 1])}
                  iconPosition='left'
                  icon={<MdChevronLeft size={25} />}
                  onClick={onPrevChart}
                />
              )}
              {chartTypes[chartType + 1] && (
                <Button
                  className={styles.nextChart}
                  type={BUTTON_TYPE.LINK}
                  size={BUTTON_SIZE.SMALL}
                  text={_.startCase(chartTypes[chartType + 1])}
                  iconPosition='right'
                  icon={<MdChevronRight size={25} />}
                  onClick={onNextChart}
                />
              )}
            </div>
            {<Column
              data={status === SUCCESS && response.data && response.data[chartTypes[chartType]]}
              isLoading={status === FETCHING}
              title={chartTypes[chartType]}
              filters={filters}
            />}
            <div className={styles.btnWrapper}>
              <Button text='Close' type={BUTTON_TYPE.PRIMARY} size={BUTTON_SIZE.SMALL} onClick={onCloseModal} />
            </div>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};

export default Chart;
