import {useState, useEffect} from 'react';
import _ from 'lodash';

import {
  Button,
  BUTTON_TYPE,
  Card,
  Checkbox,
  CheckboxGroup,
  createUseStyles,
  DateRangePicker,
  Input,
  INPUT_TYPE,
  useTheme,
  toast,
  useRequest,
} from 'aida-components';

import TwoColumns from 'components/2columns';
import ThreeColumns from 'components/3columns';

import styles from './predictive-model-summary.module.scss';
import SelectInput, {SELECT_OPTIONS_TYPE} from 'components/select-input';
import {useAppStyles} from 'helper/app-styles';
import {UserTypeConfig} from 'helper/user-type-config';
import useURL from 'hooks/useURL';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  inpLi: {
    '& >div': {
      marginTop: 10,
    },
  },
  inpLabel: ({theme}) => ({
    ...theme.typography.body,
  }),
  item6: {
    display: 'flex !important',
    alignItems: 'center !important',
    verticalAlign: 'middle',
    '&>div': {
      paddingBottom: 0,
    },
  },
});
const Filter = ({filters, onFilterChange, onCancel}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const API_URLs = useURL();

  const [fromDate, setFromDate] = useState(filters.from_date);
  const [toDate, setToDate] = useState(filters.to_date);
  const [policyNumber, setPolicyNumber] = useState(filters.policy_number);
  const [hospitalType, setHospitalType] = useState(filters.hospital_type);
  const [visitType, setVisitType] = useState(filters.visit_type);
  const [businessLine, setBusinessLine] = useState(filters.lob);
  const [insuranceType, setInsuranceType] = useState(filters.insurance_type);
  const [requestType, setRequestType] = useState(filters.request_type);
  const [payableAmount, setPayableAmount] = useState(
    filters.total_bill_amount_value ?
      parseInt(filters.total_bill_amount_value, 10) :
      null,
  );
  const [payableComparisonType, setPayableComparisonType] = useState(
    filters.total_bill_amount_operator || 'lesser',
  );
  const [mismatchClaimsDecisions, setMismatchClaimsDecisions] = useState(
    filters.is_mismatch,
  );
  const [autoAdjudicationDecisions, setAutoAdjudicationDecisions] = useState(
    filters.stp_decision ||
      UserTypeConfig['RETAIL']
        .SELECT_OPTION_TYPES.STP_DECISION.map((d) => d.value),
  );
  const [aiDecisions, setAiDecisions] = useState(
    filters.ai_decision ||
      UserTypeConfig['RETAIL']
        .SELECT_OPTION_TYPES.AI_DECISION.map((d) => d.value),
  );
  const [outlierFlag, setOutlierFlag] = useState(
    filters.outlier_detected || ['Yes', 'No', 'Not Applicable'],
  );

  const applyFilter = () =>{
    const newFilter = {
      from_date: fromDate,
      to_date: toDate,
      policy_number: policyNumber,
      visit_type: visitType,
      hospital_type: hospitalType,
      lob: businessLine,
      insurance_type: insuranceType,
      request_type: requestType,
      total_bill_amount_value: payableAmount,
      ai_decision: aiDecisions,
      auto_adjudication_decision: autoAdjudicationDecisions,
      total_bill_amount_operator: payableComparisonType,
      is_mismatch: mismatchClaimsDecisions,
      outlier_detected: outlierFlag,
    };
    onFilterChange &&
    onFilterChange(_.pickBy(newFilter, (item)=> !_.isEmpty(item)));
  };
  const onDateRangeChange = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };

  const onVisitTypeChange = (type) => setVisitType(type && type.value);
  const onProviderTypeChange = (type) => setHospitalType(type && type.value);
  const onPolicyNumberChange = (value) => setPolicyNumber(value);
  const onBusinessLineChange = (type) => setBusinessLine(type && type.value);
  const onInsurancePlanChange = (type) => setInsuranceType(type && type.value);
  const onRequestTypeChange = (type) => setRequestType(type && type.value);
  const onPayableAmountChange = (value) => setPayableAmount(value);
  const onPayableComparisonTypeChange = (type) =>
    setPayableComparisonType(type && type.value);
  const onAutoAdjudicationDecisionsChange = (values) => setAutoAdjudicationDecisions([...values]);
  const onAiDecisionsChange = (values) => setAiDecisions([...values]);
  const onOutlierFlagChange = (values) => setOutlierFlag([...values]);

  const onMismatchClaimsDecisionsChange = () =>
    setMismatchClaimsDecisions(mismatchClaimsDecisions === 'Y' ? '' : 'Y');

  return (
    <Card>
      <ul className={classes.listWrapper}>
        <li className={classes.inpLi}>
          <label className={classes.inpLabel}>Claim Received Period</label>
          <DateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            onChange={onDateRangeChange}
          />
        </li>
      </ul>
      <TwoColumns className={classes.listWrapper}>
        <li>
          <Input
            label='Policy Number'
            type={INPUT_TYPE.TEXT}
            placeholder='Enter Policy Number'
            onChange={onPolicyNumberChange}
            value={policyNumber}
          />
        </li>
        <li>
          <Input
            label='Hospital Tier'
            type={INPUT_TYPE.ASYNC}
            url={API_URLs.HOSPITAL_TIER}
            placeholder='Enter Hospital Tier'
            value={hospitalType ? {
              label: hospitalType,
              value: hospitalType,
            } : null}
            onChange={onProviderTypeChange}
            optionsFilter={(data) => data}
          />
        </li>
        <li>
          <Input
            label='Visit Type'
            type={INPUT_TYPE.ASYNC}
            url={API_URLs.VISIT_TYPES}
            placeholder='Enter Visit Type'
            value={visitType ? {
              label: visitType,
              value: visitType,
            } : null}
            onChange={onVisitTypeChange}
            optionsFilter={(data) => data}
          />
        </li>
        <li>
          <Input
            label='LOB'
            type={INPUT_TYPE.SELECT}
            placeholder='Enter LOB'
            options={
              UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES
                .BUSINESS_LINE
            }
            onChange={onBusinessLineChange}
            defaultValue={UserTypeConfig['RETAIL']
              .SELECT_OPTION_TYPES.BUSINESS_LINE.find(
                (opt) => opt.value === businessLine,
              )}
          />
        </li>
        <li>
          <Input
            label='Insurance Plan Type'
            type={INPUT_TYPE.SELECT}
            placeholder='Enter Insurance Plan Type'
            options={
              UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES
                .INSURANCE_TYPE
            }
            onChange={onInsurancePlanChange}
            defaultValue={UserTypeConfig['RETAIL']
              .SELECT_OPTION_TYPES.INSURANCE_TYPE.find(
                (opt) => opt.value === insuranceType,
              )}
          />
        </li>
        <li>
          <Input
            label='CRM Request Type'
            type={INPUT_TYPE.ASYNC}
            url={API_URLs.REQUEST_TYPES}
            placeholder='Enter Request Type'
            value={requestType ? {
              label: requestType,
              value: requestType,
            } : null}
            onChange={onRequestTypeChange}
            optionsFilter={(data) => data}
          />
        </li>
        <li>
          <SelectInput
            label='Total Bill Amount'
            placeholder='S$'
            onSelectChange={onPayableComparisonTypeChange}
            onInputChange={onPayableAmountChange}
            inputValue={payableAmount}
            defaultSelectValue={
              payableComparisonType ?
                {
                  value: payableComparisonType,
                  label: payableComparisonType === 'lesser' ? '<=' : '>=',
                } :
                undefined
            }
            selectOptions={SELECT_OPTIONS_TYPE.SYMBOL_TYPE}
          />
        </li>
        <li className={classes.item6}>
          <Checkbox
            className={styles.checkboxWrapper}
            checked={mismatchClaimsDecisions === 'Y'}
            label='Mismatch Claims Decisions'
            onChange={onMismatchClaimsDecisionsChange}
          />
        </li>
      </TwoColumns>
      <ThreeColumns>
        <li>
          <label className={styles.label}>Auto Adjudication Decision</label>
          <CheckboxGroup
            className={styles.checkboxWrapper}
            items={
              UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES
                .STP_DECISION
            }
            values={autoAdjudicationDecisions}
            onChange={onAutoAdjudicationDecisionsChange}
          />
        </li>
        <li>
          <label className={styles.label}>AI Decision</label>
          <CheckboxGroup
            className={styles.checkboxWrapper}
            items={
              UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES
                .AI_DECISION
            }
            values={aiDecisions}
            onChange={onAiDecisionsChange}
          />
        </li>
        <li>
          <label className={styles.label}>Outlier Detected</label>
          <CheckboxGroup
            className={styles.checkboxWrapper}
            items={[
              {
                value: 'Yes',
                label: 'Yes',
              },
              {
                value: 'No',
                label: 'No',
              },
              {
                value: 'Not Applicable',
                label: 'Not Applicable',
              },
            ]}
            values={outlierFlag}
            onChange={onOutlierFlagChange}
          />
        </li>
      </ThreeColumns>
      <div className={styles.popupBtnWrapper}>
        <Button text='Cancel' type={BUTTON_TYPE.LINK} onClick={onCancel} />
        <Button text='Apply' type={BUTTON_TYPE.PRIMARY} onClick={applyFilter} />
      </div>
    </Card>
  );
};

export default Filter;
