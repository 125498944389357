import {Fragment, useLayoutEffect, useState} from 'react';
import {createUseStyles, TabSwitch, useParams, useTheme} from 'aida-components';
import MainClaim from 'views/claims/claims-details/retail/main-claim';
import LineItemDetails from 'views/claims/claims-details/retail/line-item-details';
import {useAppStyles} from 'helper/app-styles';
import PageNotFound from 'views/404';

const tabs = [{
  id: 'claim',
  text: 'Claim',
}, {
  id: 'line',
  text: 'Line Items',
}];

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  breadcrumbs: {
    marginBottom: 20,
  },
  tabSwitch: ({theme}) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
    padding: [20, 0],
    background: '#f3f3f4',
    display: 'flex',
    '& >label': {
      ...theme.typography.h4,
      fontWeight: 700,
      padding: '8px 15px',
    },
  }),
});

const minusHeight = 200;

const RetailClaimDetails = () => {
  const theme = useTheme();
  const [height, setHeight] = useState(window.innerHeight - minusHeight);
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight - minusHeight);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const classes = useLocalStyles({theme, maxHeight: height});
  const {claimNumber} = useParams();

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const [isClaimAvailable, setIsClaimAvailable] = useState(true);

  const handleClaimNotAvailable = ({isClaimShow}) => setIsClaimAvailable(isClaimShow);
  return (
    <Fragment>
      {isClaimAvailable && (
        <Fragment>
          <TabSwitch
            items={tabs}
            className={classes.tabSwitch}
            selectedId={activeTab}
            onChange={setActiveTab}
          />
          <div className={classes.container}>
            {activeTab === tabs[0].id && <MainClaim
              claimNumber={claimNumber}
              handleClaimAvailability={handleClaimNotAvailable}
            />}
            {activeTab === tabs[1].id && <LineItemDetails claimNumber={claimNumber}/>}
          </div>
        </Fragment>
      )}
      {!isClaimAvailable && (
        <PageNotFound
          boldMessage={'Claim Not Found'}
          errorMessage={'Sorry, The claim you are looking for is not found in Smart Claims.'}
        />
      )}
    </Fragment>
  );
};

export default RetailClaimDetails;
