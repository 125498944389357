import {UserTypeConfig} from 'helper/user-type-config';
import {convertDateString, DBDateFormat, UIOutputFormat, findGracePeriod, convertDateToYearMonthDayFormat, roundNumber, concatenateTheValue, splitByDelimiter} from 'helper/utils';
export const stage1LeftMainList = ({userType, businessType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const businessTypeList = {
    IB: [{
      label: 'Policy Number',
      id: CLAIM_360_IDS.POLICY_NUMBER,
      isMandatory: false,
      classType: 2,
    }, {
      label: 'Premium Paid-to Date',
      id: CLAIM_360_IDS.PREMIUM_TO_DATE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
        data[CLAIM_360_IDS.PREMIUM_TO_DATE],
        DBDateFormat,
        UIOutputFormat,
      )}</span>,
    }, {
      label: 'Plan Inception Date',
      id: CLAIM_360_IDS.POLICY_INCEPTION_DATE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
        data[CLAIM_360_IDS.POLICY_INCEPTION_DATE],
        DBDateFormat,
        UIOutputFormat,
      )}</span>,
    }, {
      label: 'GIO',
      id: CLAIM_360_IDS.GIO,
      classType: 2,
    }, {
      label: 'Reinstatement Date',
      id: CLAIM_360_IDS.REINSTATEMENT_DATE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
        data[CLAIM_360_IDS.REINSTATEMENT_DATE],
        DBDateFormat,
        UIOutputFormat,
      )}</span>,
    }, {
      label: 'Last Lapse Date',
      id: CLAIM_360_IDS.LAST_LAPSE_DATE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
        data[CLAIM_360_IDS.LAST_LAPSE_DATE],
        DBDateFormat,
        UIOutputFormat,
      )}</span>,
    }, {
      label: 'Rider Inception Date',
      id: CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
        data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
        DBDateFormat,
        UIOutputFormat,
      )}</span>,
    }, {
      label: 'Co-share %',
      id: CLAIM_360_IDS.CO_SHARE_PERCENTAGE,
      classType: 2,
      valueDisplay: (data) => <span className={classes.customDisplayValue}>{roundNumber({
        number: data[CLAIM_360_IDS.CO_SHARE_PERCENTAGE],
        isCheckEmpty: true,
        isMultiplyBy100: true,
        suffix: '%',
        roundTo: 0,
      })}</span>,
    }, {
      label: 'Vitality Status',
      id: CLAIM_360_IDS.VITALITY_STATUS,
      classType: 2,
    }, {
      label: 'Other Medical Plans',
      id: CLAIM_360_IDS.OTHER_MEDICAL_PLANS,
      classType: 2,
    }, {
      label: '30 Days Waiting Period',
      id: CLAIM_360_IDS.WAITING_PERIOD_30_DAYS,
      classType: 2,
      displayType: 'flag',
    }, {
      isBreak: true,
    }],
    EB: [{
      label: 'Policy Number',
      id: CLAIM_360_IDS.POLICY_NUMBER,
      classType: 2,
      isMandatory: false,
    }, {
      label: 'Policy Type',
      id: CLAIM_360_IDS.POLICY_TYPE,
      classType: 2,
    }, {
      label: 'Policy Status',
      id: CLAIM_360_IDS.POLICY_STATUS,
      classType: 2,
    }, {
      label: 'Eligible Products',
      id: CLAIM_360_IDS.PRODUCT,
      classType: 2,
    }, {
      label: 'GL Type',
      id: CLAIM_360_IDS.GL_TYPE,
      classType: 2,
    }, {
      label: 'GL Facility',
      id: CLAIM_360_IDS.GL_FACILITY,
      classType: 2,
    }, {
      label: 'Co-share %',
      id: CLAIM_360_IDS.CO_SHARE_PERCENTAGE,
      classType: 2,
    }, {
      label: 'Potential Pre-Existing',
      id: CLAIM_360_IDS.POTENTIAL_PRE_EXISTING,
      classType: 2,
      displayType: 'flag',
    }, {
      label: '30 Days Waiting Period',
      id: CLAIM_360_IDS.WAITING_PERIOD_30_DAYS,
      classType: 2,
      displayType: 'flag',
    }, {
      isBreak: true,
    }],
  };

  return businessTypeList[businessType];
};

export const stage1RightMainList = ({userType, businessType, classes, glType}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const businessTypeList = {
    IB: {
      IGL: [{
        label: 'Medical Plan',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Grace Period',
        id: CLAIM_360_IDS.GRACE_PERIOD,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          findGracePeriod(
            data[CLAIM_360_IDS.PREMIUM_TO_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Plan Duration',
        id: CLAIM_360_IDS.POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'SOE',
        id: CLAIM_360_IDS.SOE,
        classType: 2,
      }, {
        label: 'Reinstated Duration',
        id: CLAIM_360_IDS.REINSTATEMENT_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.REINSTATEMENT_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Cash Value',
        id: CLAIM_360_IDS.CASH_VALUE,
        classType: 2,
      }, {
        label: 'Rider Duration',
        id: CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        label: 'Deductible Waiver',
        id: CLAIM_360_IDS.DEDUCTIBLE_WAIVER,
        classType: 2,
      }, {
        label: 'Potential Pre-Existing',
        id: CLAIM_360_IDS.POTENTIAL_PRE_EXISTING,
        classType: 2,
        displayType: 'flag',
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }],
      AGL: [{
        label: 'Medical Plan',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Plan Duration',
        id: CLAIM_360_IDS.POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'SOE',
        id: CLAIM_360_IDS.SOE,
        classType: 2,
      }, {
        label: 'Reinstated Duration',
        id: CLAIM_360_IDS.REINSTATEMENT_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.REINSTATEMENT_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Cash Value',
        id: CLAIM_360_IDS.CASH_VALUE,
        classType: 2,
      }, {
        label: 'Rider Duration',
        id: CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        label: 'Deductible Waiver',
        id: CLAIM_360_IDS.DEDUCTIBLE_WAIVER,
        classType: 2,
      }, {
        label: 'Potential Pre-Existing',
        id: CLAIM_360_IDS.POTENTIAL_PRE_EXISTING,
        classType: 2,
        displayType: 'flag',
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }],
      FGL: [{
        label: 'Medical Plan',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Grace Period',
        id: CLAIM_360_IDS.GRACE_PERIOD,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          findGracePeriod(
            data[CLAIM_360_IDS.PREMIUM_TO_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Plan Duration',
        id: CLAIM_360_IDS.POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'SOE',
        id: CLAIM_360_IDS.SOE,
        classType: 2,
      }, {
        label: 'Reinstated Duration',
        id: CLAIM_360_IDS.REINSTATEMENT_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.REINSTATEMENT_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Cash Value',
        id: CLAIM_360_IDS.CASH_VALUE,
        classType: 2,
      }, {
        label: 'Rider Duration',
        id: CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          convertDateToYearMonthDayFormat(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.ADMISSION_DATE],
          )}</span>,
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        label: 'Deductible Waiver',
        id: CLAIM_360_IDS.DEDUCTIBLE_WAIVER,
        classType: 2,
      }, {
        label: 'Potential Pre-Existing',
        id: CLAIM_360_IDS.POTENTIAL_PRE_EXISTING,
        classType: 2,
        displayType: 'flag',
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }],
    },
    EB: {
      IGL: [{
        label: 'Company Name',
        id: CLAIM_360_IDS.COMPANY,
        classType: 2,
      }, {
        label: 'Plan Details',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Policy Period',
        id: CLAIM_360_IDS.POLICY_PERIOD,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          concatenateTheValue(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.PREMIUM_TO_DATE],
          )}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: 'Provider Network',
        id: CLAIM_360_IDS.PROVIDER_NETWORK,
        classType: 2,
      }, {
        label: 'Special Arrangements',
        id: CLAIM_360_IDS.SPECIAL_ARRANGEMENTS,
        classType: 2,
        displayType: 'flag',
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }],
      AGL: [{
        label: 'Company Name',
        id: CLAIM_360_IDS.COMPANY,
        classType: 2,
      }, {
        label: 'Plan Details',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Policy Period',
        id: CLAIM_360_IDS.POLICY_PERIOD,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          concatenateTheValue(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.PREMIUM_TO_DATE],
          )}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: 'Provider Network',
        id: CLAIM_360_IDS.PROVIDER_NETWORK,
        classType: 2,
      }, {
        label: 'Special Arrangements',
        id: CLAIM_360_IDS.SPECIAL_ARRANGEMENTS,
        classType: 2,
        displayType: 'flag',
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }],
      FGL: [{
        label: 'Company Name',
        id: CLAIM_360_IDS.COMPANY,
        classType: 2,
      }, {
        label: 'Plan Details',
        id: CLAIM_360_IDS.PLAN,
        classType: 2,
      }, {
        label: 'Policy Period',
        id: CLAIM_360_IDS.POLICY_PERIOD,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          concatenateTheValue(
            data[CLAIM_360_IDS.RIDER_1_POLICY_INCEPTION_DATE],
            data[CLAIM_360_IDS.PREMIUM_TO_DATE],
          )}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: 'Provider Network',
        id: CLAIM_360_IDS.PROVIDER_NETWORK,
        classType: 2,
      }, {
        label: 'Special Arrangements',
        id: CLAIM_360_IDS.SPECIAL_ARRANGEMENTS,
        classType: 2,
        displayType: 'flag',
      }, {
        label: 'Deductible (RM)',
        id: CLAIM_360_IDS.DEDUCTIBLE,
        classType: 2,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{
          roundNumber({
            number: data[CLAIM_360_IDS.DEDUCTIBLE],
            isFormatNumber: true,
            isCheckEmpty: true,
          })}</span>,
      }, {
        isBreak: true,
      }, {
        isBreak: true,
      }, {
        label: '120 Days Waiting Period',
        id: CLAIM_360_IDS.WAITING_PERIOD_120_DAYS,
        classType: 2,
        displayType: 'flag',
      }, {
        isBreak: true,
      }],
    },
  };

  return businessTypeList[businessType][glType];
};

export const stage1PolicyExclusion = ({userType}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    label: 'Insured Exclusion',
    id: CLAIM_360_IDS.POLICY_EXCLUSION,
    classType: 2,
  }, {
    isBreak: true,
  }];
};

export const stage1FinalList = ({userType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    isBreak: true,
  }, {
    label: 'Treatment Plan / Procedure',
    id: CLAIM_360_IDS.TREATMENT,
    classType: 2,
  }, {
    label: 'General Exclusion',
    id: CLAIM_360_IDS.GENERAL_EXCLUSION,
    classType: 2,
    displayType: 'flag',
  }, {
    label: 'General Exclusion Reason',
    id: CLAIM_360_IDS.GENERAL_EXCLUSION_REASON,
    classType: 2,
    valueDisplay: (data) => <span
      className={classes.customDisplayValue}>{
        splitByDelimiter({
          text: data[CLAIM_360_IDS.GENERAL_EXCLUSION_REASON],
        }).map((ex, index) =>
          <div
            key={`gen-ex-reason-${index}`}
            style={{marginBottom: 5}}
          >{ex}
          </div>)}</span>,
  }, {
    isBreak: true,
  }];
};

export const stage2LeftMainList = ({userType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    label: 'RB Entitlement (RM)',
    id: CLAIM_360_IDS.RB_ENTITLEMENT,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.RB_ENTITLEMENT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    label: 'Limit Whom',
    id: CLAIM_360_IDS.LIMIT_WHOM,
    classType: 2,
  }, {
    isBreak: true,
  }, {
    label: 'HPMS Limit (RM)',
    id: CLAIM_360_IDS.HPMS_LIMIT,
    isHideIfNoValue: true,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.HPMS_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    label: 'NSBN Limit (RM)',
    id: CLAIM_360_IDS.NSBN_LIMIT,
    isHideIfNoValue: true,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.NSBN_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    isBreak: true,
  }];
};

export const stage2RightMainList = ({userType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    label: 'ICU Entitlement (RM)',
    id: CLAIM_360_IDS.ICU_ENTITLEMENT,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.ICU_ENTITLEMENT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    isBreak: true,
  }, {
    isBreak: true,
  }, {
    label: 'SBEN Limit (RM)',
    id: CLAIM_360_IDS.SBEN_LIMIT,
    isHideIfNoValue: true,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.SBEN_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    label: 'DYPR Limit (RM)',
    id: CLAIM_360_IDS.DYPR_LIMIT,
    isHideIfNoValue: true,
    classType: 2,
    valueDisplay: (data) => <span className={classes.customDisplayValue}>{
      roundNumber({
        number: data[CLAIM_360_IDS.DYPR_LIMIT],
        isFormatNumber: true,
        isCheckEmpty: true,
      })}</span>,
  }, {
    isBreak: true,
  }];
};

export const pendingClaimLeftList = ({userType}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    label: 'Any Pending AUC Cases',
    id: CLAIM_360_IDS.PENDING_CLAIM,
    displayType: 'flag',
  }, {
    isBreak: true,
  }];
};

export const pendingClaimRightList = ({userType}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  return [{
    label: 'Prior Closed Claims',
    id: CLAIM_360_IDS.CLOSED_CLAIM,
    displayType: 'flag',
  }, {
    isBreak: true,
  }];
};
export const additionalChecksList = ({userType, businessType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const BUSINESS_TYPE_IDS = {
    IB: [
      {
        label: 'Member/Patient in Watchlist',
        id: CLAIM_360_IDS.CLIENT_IN_WATCHLIST,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Agent in Watchlist',
        id: CLAIM_360_IDS.AGENT_IN_WATCHLIST,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Doctor in Watchlist',
        id: CLAIM_360_IDS.DOCTOR_IN_WATCHLIST,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Interval of Readmissions Flag',
        id: CLAIM_360_IDS.INTERVAL_READMISSION_FLAG,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'High Frequency of Doctor Visits',
        id: CLAIM_360_IDS.HIGH_FREQ_DOC_VISITS,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'ICD for Review - Approve',
        id: CLAIM_360_IDS.ICD_REVIEW_LIST_APPROVE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'ICD for Review - Reject',
        id: CLAIM_360_IDS.ICD_REVIEW_LIST_DECLINE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Exclusion Lump and Bump Identified',
        id: CLAIM_360_IDS.EXCLUSION_LUMP_AND_BUMP,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Top 10 AUC Procedure Identified',
        id: CLAIM_360_IDS.TOP_10_AUC_PROCEDURE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Daycare Procedure Identified',
        id: CLAIM_360_IDS.DAYCARE_PROCEDURE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      },
    ],
    EB: [
      {
        label: 'Doctor in Watchlist',
        id: CLAIM_360_IDS.DOCTOR_IN_WATCHLIST,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Interval of Readmissions Flag',
        id: CLAIM_360_IDS.INTERVAL_READMISSION_FLAG,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'High Frequency of Doctor Visits',
        id: CLAIM_360_IDS.HIGH_FREQ_DOC_VISITS,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'ICD for Review - Approve',
        id: CLAIM_360_IDS.ICD_REVIEW_LIST_APPROVE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'ICD for Review - Reject',
        id: CLAIM_360_IDS.ICD_REVIEW_LIST_DECLINE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Exclusion Lump and Bump Identified',
        id: CLAIM_360_IDS.EXCLUSION_LUMP_AND_BUMP,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Top 10 AUC Procedure Identified',
        id: CLAIM_360_IDS.TOP_10_AUC_PROCEDURE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      }, {
        label: 'Daycare Procedure Identified',
        id: CLAIM_360_IDS.DAYCARE_PROCEDURE,
        displayType: 'flag',
        labelClass: classes.labelLocal,
        valueClass: classes.valueLocal,
        flagClass: classes.flagTextLocal,
      },
    ],
  };

  return BUSINESS_TYPE_IDS[businessType];
};
