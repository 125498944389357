import {Card, createUseStyles, Scroll, Table, useTheme} from 'aida-components';
import {checkEmptyString, roundNumber, convertDateString, DBDateFormat, UIOutputFormat} from 'helper/utils';
import {UserTypeConfig} from 'helper/user-type-config';
import {useStyles} from 'views/claims/styles';
import {sumBy} from 'lodash';
import CustomTableHead from 'components/custom-table-head';

const useLocalStyles = createUseStyles({
  ...useStyles,
  table: {
    background: 'transparent',
    boxShadow: 'none',
  },
  head: ({theme}) => ({
    background: 'transparent',
    borderBottom: '1px solid #999',
    color: theme.default.color.text,
    ...theme.typography.title,
    fontWeight: 600,
    fontSize: 16,
  }),
  row: {
    fontSize: '14px',
    '&> span': {
      fontSize: '14px',
    },
  },
  rowColor: {
    fontSize: '14px',
    color: 'blue',
    '&> span': {
      fontSize: '14px',
    },
  },
  splitDiv: {
    display: 'flex',
    flexDirection: 'column',

    '& >span': {
      marginBottom: 5,
    },
  },
  totalAmount: {
    fontWeight: 700,
    textAlign: 'right',
    marginBottom: 20,
    marginRight: 10,
  },
});

const getNonPayableHeads = ({classes, NON_PAYABLE_IDS}) => {
  return [{
    title: 'Line Item Charged From Date',
    id: NON_PAYABLE_IDS.LI_FROM_DATE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '20%',
    display: (record) => (<div className={classes.row}>
      {convertDateString(record && record[NON_PAYABLE_IDS.LI_FROM_DATE], DBDateFormat, UIOutputFormat)}
    </div>),
  }, {
    title: 'Line Item Charged To Date',
    id: NON_PAYABLE_IDS.LI_TO_DATE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '20%',
    display: (record) => (<div className={classes.row}>
      {convertDateString(record && record[NON_PAYABLE_IDS.LI_TO_DATE], DBDateFormat, UIOutputFormat)}
    </div>),
  }, {
    title: 'Line Item Description',
    id: NON_PAYABLE_IDS.ITEM_DESC,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '50%',
    headDisplay: () => (
      <CustomTableHead
        title='Line Item Description'
        id={NON_PAYABLE_IDS.ITEM_DESC}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[NON_PAYABLE_IDS.ITEM_DESC])}
    </div>),
  }, {
    title: 'Quantity',
    id: NON_PAYABLE_IDS.QUANTITY,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    headDisplay: () => (
      <CustomTableHead
        title='Quantity'
        id={NON_PAYABLE_IDS.QUANTITY}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[NON_PAYABLE_IDS.QUANTITY],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 0,
      })}
    </div>),
  }, {
    title: 'Price Per Unit',
    id: NON_PAYABLE_IDS.PRICE_PER_UNIT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    headDisplay: () => (
      <CustomTableHead
        title='Price Per Unit'
        id={NON_PAYABLE_IDS.PRICE_PER_UNIT}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[NON_PAYABLE_IDS.PRICE_PER_UNIT],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }, {
    title: 'Total Amount',
    id: NON_PAYABLE_IDS.TOTAL_AMOUNT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    headDisplay: () => (
      <CustomTableHead
        title='Total Amount'
        id={NON_PAYABLE_IDS.TOTAL_AMOUNT}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[NON_PAYABLE_IDS.TOTAL_AMOUNT],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }];
};

const getCreditNoteHeads = ({classes, CREDIT_NOTE_IDS}) => {
  return [{
    title: 'Date',
    id: CREDIT_NOTE_IDS.DATE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {convertDateString(record && record[CREDIT_NOTE_IDS.DATE], DBDateFormat, UIOutputFormat)}
    </div>),
  }, {
    title: 'Line Item Description',
    id: CREDIT_NOTE_IDS.ITEM_DESC,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '30%',
    headDisplay: () => (
      <CustomTableHead
        title='Line Item Description'
        id={CREDIT_NOTE_IDS.ITEM_DESC}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[CREDIT_NOTE_IDS.ITEM_DESC])}
    </div>),
  }, {
    title: 'Quantity',
    id: CREDIT_NOTE_IDS.QUANTITY,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    headDisplay: () => (
      <CustomTableHead
        title='Quantity'
        id={CREDIT_NOTE_IDS.QUANTITY}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[CREDIT_NOTE_IDS.QUANTITY],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 0,
      })}
    </div>),
  }, {
    title: 'Overcharged Amt',
    id: CREDIT_NOTE_IDS.OVERCHARGED_AMOUNT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[CREDIT_NOTE_IDS.OVERCHARGED_AMOUNT],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }, {
    title: 'Total CN',
    id: CREDIT_NOTE_IDS.TOTAL_CN,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[CREDIT_NOTE_IDS.TOTAL_CN],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }, {
    title: 'Reason for CN',
    id: CREDIT_NOTE_IDS.CN_REASON,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '30%',
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[CREDIT_NOTE_IDS.CN_REASON])}
    </div>),
  }];
};

const getAUCHeads = ({classes, AUC_IDS}) => {
  return [{
    title: 'Date',
    id: AUC_IDS.DATE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[AUC_IDS.DATE])}
    </div>),
  }, {
    title: 'Line Item Description',
    id: AUC_IDS.ITEM_DESC,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '30%',
    headDisplay: () => (
      <CustomTableHead
        title='Line Item Description'
        id={AUC_IDS.ITEM_DESC}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[AUC_IDS.ITEM_DESC])}
    </div>),
  }, {
    title: 'Quantity',
    id: AUC_IDS.QUANTITY,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    headDisplay: () => (
      <CustomTableHead
        title='Quantity'
        id={AUC_IDS.QUANTITY}
        isMandatory={false}
      />
    ),
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[AUC_IDS.QUANTITY],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 0,
      })}
    </div>),
  }, {
    title: 'Overcharged Amt',
    id: AUC_IDS.OVERCHARGED_AMOUNT,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[AUC_IDS.OVERCHARGED_AMOUNT],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }, {
    title: 'Total AUC',
    id: AUC_IDS.TOTAL_AUC,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '10%',
    display: (record) => (<div className={classes.row}>
      {roundNumber({
        number: record[AUC_IDS.TOTAL_AUC],
        isCheckEmpty: true,
        isFormatNumber: true,
        roundTo: 2,
      })}
    </div>),
  }, {
    title: 'Reason for AUC',
    id: AUC_IDS.AUC_REASON,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: '30%',
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[AUC_IDS.AUC_REASON])}
    </div>),
  }];
};

const UncoveredCharges = ({claimDetails}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const userType = 'retail';

  const COLUMN_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];
  const NON_PAYABLE_IDS = COLUMN_IDS['NON_PAYABLE_TABLE'];
  const CREDIT_NOTE_IDS = COLUMN_IDS['CREDIT_NOTE_TABLE'];
  const AUC_IDS = COLUMN_IDS['AUC_TABLE'];

  const nonPayableHeads = getNonPayableHeads({classes, NON_PAYABLE_IDS});
  const creditNoteHeads = getCreditNoteHeads({classes, CREDIT_NOTE_IDS});
  const aucHeads = getAUCHeads({classes, AUC_IDS});

  return (
    <Scroll>
      <Card className={classes.space}>
        <h3 className={classes.cardTitle}>Stage 6 - Total Uncovered Charges</h3>

        <h5 className={classes.stageTitle}>Non Payable Item</h5>
        <Table
          className={classes.table}
          heads={nonPayableHeads}
          data={
            claimDetails &&
              claimDetails[COLUMN_IDS.NON_PAYABLE_ITEMS]
          }
        />
        <br/>
        <h5 className={classes.totalAmount}>{`Grand Total: ${roundNumber({
          number: sumBy(claimDetails &&
              claimDetails[COLUMN_IDS.NON_PAYABLE_ITEMS], NON_PAYABLE_IDS.TOTAL_AMOUNT),
          isCheckEmpty: true,
          isFormatNumber: true,
        })}`}</h5>
        <br/><br/>

        <h5 className={classes.stageTitle}>Credit Note (CN)</h5>
        <Table
          className={classes.table}
          heads={creditNoteHeads}
          data={
            claimDetails &&
              claimDetails[COLUMN_IDS.CREDIT_NOTE_ITEMS] &&
              JSON.parse(claimDetails[COLUMN_IDS.CREDIT_NOTE_ITEMS])
          }
        />
        <br/>
        <h5 className={classes.totalAmount}>{`Grand Total: ${roundNumber({
          number: sumBy(claimDetails &&
            claimDetails[COLUMN_IDS.CREDIT_NOTE_ITEMS] &&
            JSON.parse(claimDetails[COLUMN_IDS.CREDIT_NOTE_ITEMS]), CREDIT_NOTE_IDS.TOTAL_CN),
          isCheckEmpty: true,
          isFormatNumber: true,
        })}`}</h5>
        <br/><br/>

        <h5 className={classes.stageTitle}>Amount Under Clarification (AUC)</h5>
        <Table
          className={classes.table}
          heads={aucHeads}
          data={
            claimDetails &&
              claimDetails[COLUMN_IDS.AUC_ITEMS] &&
              JSON.parse(claimDetails[COLUMN_IDS.AUC_ITEMS])
          }
        />
        <br/>
        <h5 className={classes.totalAmount}>{`Grand Total: ${roundNumber({
          number: sumBy(claimDetails &&
            claimDetails[COLUMN_IDS.AUC_ITEMS] &&
            JSON.parse(claimDetails[COLUMN_IDS.AUC_ITEMS]), AUC_IDS.TOTAL_CN),
          isCheckEmpty: true,
          isFormatNumber: true,
        })}`}</h5>
        <br/><br/>

      </Card>
    </Scroll>
  );
};

export default UncoveredCharges;
