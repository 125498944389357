import {Main, Route, Switch, useLocation} from 'aida-components';
import Login from 'views/login';
import Failure from 'views/failure';
import Private from 'components/private';
import RefreshUser from 'views/refresh-user';

const Root = () => {
  return (
    <Main>
      <div>
        <Switch>
          {/* <Route exact path='/login' component={Login}/>
          <Route exact path='/refresh' component={RefreshUser}/>
          <Route exact path='/failure' component={Failure}/> */}
          <Route path='/' component={Private} />
        </Switch>
      </div>
    </Main>
  );
};

export default Root;
