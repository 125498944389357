import {forwardRef, useEffect} from 'react';
import useURL from 'hooks/useURL';
import {AILoading, createUseStyles, toast, useRequest, useTheme} from 'aida-components';
import {useAppStyles} from 'helper/app-styles';
import LabelValue from 'components/label-value';
import _ from 'lodash';
import {roundNumber} from 'helper/utils';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  localLabel: {
    width: '70%',
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      zIndex: 0,
      // eslint-disable-next-line max-len
      content: '"......................................................................................................................................................................................................................................................"',
    },
  },
  itemTitle: ({theme}) => ({
    ...theme.typography.title,
    fontSize: 18,
    color: theme.default.color.text,
    fontWeight: 500,
  }),
  localTitle: {
    width: '70%',
  },
  localTitle2: ({theme}) => ({
    ...theme.typography.title,
  }),
  localValue: {
    width: '30%',
  },
  sub: {
    width: '70%',
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      zIndex: 0,
      // eslint-disable-next-line max-len
      content: '"......................................................................................................................................................................................................................................................"',
    },
    paddingLeft: 20,
  },
});

const overviewTitle = ({classes}) => [{
  label: 'Overall View',
  id: '',
  labelClass: classes.itemTitle,
  isHideValue: true,
}];

const overviewHeads = ({classes}) => [{
  isBreak: true,
}, {
  label: 'Time Period (in months)',
  id: 'time_period',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({time_period}) => <span>{`${time_period} months`}</span>,
}, {
  label: 'Total Number of Claims submitted',
  id: 'total_claim',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({total_claim}) => <span>{roundNumber({
    number: total_claim,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  label: 'Total Claim Amount submitted',
  id: 'total_claim_amount',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({total_claim_amount}) => <span>{roundNumber({
    number: total_claim_amount,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  label: 'Number of Claims for selected LOB, Hospital, Visit, Insurance and Request Types',
  id: 'total_selected_claim',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({total_selected_claim}) => <span>{roundNumber({
    number: total_selected_claim,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  label: 'Percentage of claims for selected LOB, Hospital, Visit, Insurance and Request Types',
  id: 'percentage',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({percentage}) => <span>{roundNumber({
    number: percentage,
    roundTo: 2,
    suffix: '%',
  })}</span>,
}, {
  isBreak: true,
}];

const stpClaimsTitle = ({classes}) => [{
  label: 'Auto Adjudication Claims',
  id: '',
  labelClass: classes.itemTitle,
  isHideValue: true,
}];

const stpClaimsHeads = ({classes}) => [{
  isBreak: true,
}, {
  label: 'Auto Adjudication Rate for selected claims',
  id: 'stp_rate',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({stp_rate}) => <span>{roundNumber({
    number: stp_rate,
    roundTo: 2,
    suffix: '%',
  })}</span>,
}, {
  label: 'Accuracy',
  id: 'accuracy',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({accuracy}) => <span>{roundNumber({
    number: accuracy,
    roundTo: 2,
    suffix: '%',
  })}</span>,
}, {
  label: 'Number of Auto Adjudication Claims',
  id: 'total_stp_claim',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({total_stp_claim}) => <span>{roundNumber({
    number: total_stp_claim,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  label: 'Total Auto Adjudication Claim Amount',
  id: 'stp_claim_amt',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({stp_claim_amt}) => <span>{roundNumber({
    number: stp_claim_amt,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  label: 'Number of Claims with Correct Decision',
  id: 'correct_decision',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({correct_decision}) => <span>{roundNumber({
    number: correct_decision,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  isBreak: true,
}];

const monthlyAverageTitle = ({classes}) => [{
  label: 'Monthly Average',
  id: '',
  labelClass: classes.itemTitle,
  isHideValue: true,
}];

const monthlyAverageHeads = ({classes}) => [{
  isBreak: true,
}, {
  label: 'Auto Adjudicated Claim Amount',
  id: 'stp_claim_amt_monthly',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({stp_claim_amt_monthly}) => <span>{roundNumber({
    number: stp_claim_amt_monthly,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  label: 'Average Amount Per Claim',
  id: 'stp_clm_amt_monthly_per_claim',
  labelClass: classes.localLabel,
  valueClass: classes.localValue,
  valueDisplay: ({stp_clm_amt_monthly_per_claim}) => <span>{roundNumber({
    number: stp_clm_amt_monthly_per_claim,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  isBreak: true,
}];

const falseDeclinedTitle = ({classes}) => [{
  label: 'False Rejected (Cost to Insured)',
  id: '',
  labelClass: classes.localTitle2,
  isHideValue: true,
}];

const falseDeclinedDollarHeads = ({classes}) => [{
  label: 'Number of Claims',
  id: 'total_fr_stp_clm_monthly',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({total_fr_stp_clm_monthly}) => <span>{roundNumber({
    number: total_fr_stp_clm_monthly,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  label: 'Claim Amount',
  id: 'total_fr_clm_amt_monthly',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({total_fr_clm_amt_monthly}) => <span>{roundNumber({
    number: total_fr_clm_amt_monthly,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  label: 'Average Claim Amount Per Claim',
  id: 'fr_clm_amt_monthly_per_claim',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({fr_clm_amt_monthly_per_claim}) => <span>{roundNumber({
    number: fr_clm_amt_monthly_per_claim,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  isBreak: true,
}];

const leakageTitle = ({classes}) => [{
  label: 'False Approved (Cost to Insurer)',
  id: '',
  labelClass: classes.localTitle2,
  isHideValue: true,
}];

const leakageHeads = ({classes}) => [{
  label: 'Number of Claims',
  id: 'total_fa_stp_clm_monthly',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({total_fa_stp_clm_monthly}) => <span>{roundNumber({
    number: total_fa_stp_clm_monthly,
    isFormatNumber: true,
    roundTo: 0,
  })}</span>,
}, {
  label: 'Leakage Amount',
  id: 'total_fa_clm_amt_monthly',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({total_fa_clm_amt_monthly}) => <span>{roundNumber({
    number: total_fa_clm_amt_monthly,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  label: 'Cost Per Claim',
  id: 'fa_clm_amt_monthly_per_claim',
  labelClass: classes.sub,
  valueClass: classes.localValue,
  valueDisplay: ({fa_clm_amt_monthly_per_claim}) => <span>{roundNumber({
    number: fa_clm_amt_monthly_per_claim,
    isFormatNumber: true,
    roundTo: 2,
    prefix: 'RM ',
  })}</span>,
}, {
  isBreak: true,
}];

const Overview = forwardRef(({filters}, commingRef) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const API_URLs = useURL();
  const [
    {status, response},
    makeRequest,
    {FETCHING, SUCCESS, ERROR},
    source,
  ] = useRequest(API_URLs.STP_CALCULATOR_TEXT_URL, {
    config: {
      params: {
        from_date: filters && filters.fromDate,
        to_date: filters && filters.toDate,
        lob: filters && filters.businessLine,
        provider_type: filters && filters.providerType,
        hospital_tier: filters && filters.hospitalTier,
        visit_type: filters && filters.visitType,
        insurance_plan: filters && filters.insurancePlan,
        request_type: filters && filters.requestType,
        // claim_event_type: filters && filters.submissionType,
        // panel_type: filters && filters.doctorFlag ? filters.doctorFlag.join(',') : null,
        total_bill_amount_value: filters && filters.claimAmount,
        machine_confidence: filters && filters.machineConfidence,
        outlier_score: filters && filters.outlierScore,
        ai_decision: filters && filters.aiDecisions ? filters.aiDecisions.join(',') : null,
        outlier_detected: filters && filters.outlierFlag ? filters.outlierFlag.join(',') : null,
      },
    },
  });

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      makeRequest();
    }
    return () => {
      source && source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    if (status === ERROR && response.header) {
      toast.error(response.header.message);
    }
  }, [status]);

  if (status === FETCHING) {
    return <AILoading />;
  } else if (status === SUCCESS) {
    return (
      <ul className={classes.listWrapper} ref={commingRef}>
        {overviewTitle({classes}).map((f, index) =>
          <LabelValue
            key={`false-head-title-${index}`}
            item={f}
            data={[]}
          />,
        )}
        {overviewHeads({classes}).map((m, index) =>
          <LabelValue
            key={`m-head-${index}`}
            item={m}
            data={response && response.data}
          />,
        )}
        {stpClaimsHeads({classes}).map((m, index) =>
          <LabelValue
            key={`m-head-${index}`}
            item={m}
            data={response && response.data}
          />,
        )}
        {monthlyAverageTitle({classes}).map((f, index) =>
          <LabelValue
            key={`false-head-title-${index}`}
            item={f}
            data={[]}
          />,
        )}
        {monthlyAverageHeads({classes}).map((m, index) =>
          <LabelValue
            key={`m-head-${index}`}
            item={m}
            data={response && response.data}
          />,
        )}
        {falseDeclinedTitle({classes}).map((f, index) =>
          <LabelValue
            key={`false-head-title-${index}`}
            item={f}
            data={[]}
          />,
        )}
        {falseDeclinedDollarHeads({classes}).map((f, index) =>
          <LabelValue
            key={`false-head-${index}`}
            item={f}
            data={response && response.data}
          />,
        )}
        {leakageTitle({classes}).map((f, index) =>
          <LabelValue
            key={`leakage-head-title-${index}`}
            item={f}
            data={[]}
          />,
        )}
        {leakageHeads({classes}).map((l, index) =>
          <LabelValue
            key={`leakage-head-${index}`}
            item={l}
            data={response && response.data}
          />,
        )}
      </ul>
    );
  }
  return null;
});

export default Overview;
