import styles from './failure.module.scss';

const Failure = () => <div className={styles.wrapper}>
  <div className={styles.lock}></div>
  <div>
    <h1>Access to this page/tab for your role is restricted</h1>
    <p>Please check with the site admin if you believe this is a mistake.</p>
  </div>
</div>;

export default Failure;
