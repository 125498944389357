import {AuthContext, BrowserRouter, createUseStyles, Route, Switch, ToastContainer} from 'aida-components';
import {useState} from 'react';
import Highcharts from 'highcharts';
import Root from 'components/root';

const useStyles = createUseStyles((theme) => ({
  '@global': {
    body: {
      ...theme.typography.body,
      background: theme.default.color.background,
      color: theme.default.color.text,
      minWidth: 1300,
    },
  },
}));

const App = () => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
    accessibility: {
      enabled: false,
    },
  });

  useStyles();

  const [authUserInfo, setAuthUserInfo] = useState({
    isTestLogin: true,
    userId: 'demouser',
    role: 'ahs_admin',
    userName: 'demouser',
    fullName: 'DemoUser',
    email: 'demouser@amplifyhealth.com',
    token: 'demotoken',
  });

  return (
    <AuthContext.Provider
      value={{authUserInfo, setAuthUserInfo}}
    >
      <BrowserRouter>
        <Switch>
          <Route path='/' component={Root}/>
        </Switch>
        <ToastContainer/>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
