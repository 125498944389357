import {toast, useHistory, useRequest as useRequestDefault} from 'aida-components';

const useRequest = (
  endpoint,
  {verb = 'get', params = {}, config = {}} = {},
) => {
  const result = useRequestDefault(endpoint, {
    verb,
    params,
    config,
  });

  const [{status, response}, , {ERROR}] = result;
  const history = useHistory();
  if (status === ERROR) {
    if (response.status === 404) {
      toast.error('Can\'t connect to the server!');
    } else if (response.status === 401) {
      if (window.location.pathname !== '/failure') {
        history.push('/failure');
      }
    }
  }
  return result;
};
export default useRequest;
