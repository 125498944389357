import {createUseStyles, INPUT_TYPE, useTheme} from 'aida-components';
import Slider from 'rc-slider';
import React, {useCallback} from 'react';
import trim from 'lodash/trim';

const useLocalStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  label: ({theme}) => ({
    color: theme.label.color,
    fontFamily: theme.label.font.family,
    fontSize: theme.label.font.size,
    fontWeight: theme.label.font.weight,
    paddingBottom: 10,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    boxSizing: 'border-box',
  }),
  inputWrapper: {
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: 10,
      marginTop: -12,
      transition: ['fill', '.5s', 'ease-in-out'],
    },
  },
  slider: ({theme}) => ({
    fontFamily: theme.input.font.family,
    width: [
      [
        `calc(100% - ${
          theme.input.slider.inputWidth + theme.input.slider.space
        }px)`,
      ],
      '!important',
    ],
  }),
  sliderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: 10,
  },
  sliderInput: ({theme}) => ({
    width: theme.input.slider.inputWidth,
  }),
  input: ({countryCode, error, icon, theme}) => ({
    fontFamily: theme.input.font.family,
    fontSize: theme.input.font.size,
    fontWeight: theme.input.font.weight,
    background: theme.input.background,
    outline: 'none',
    width: '100%',
    boxSizing: 'border-box',
    color: theme.default.color.text,
    padding: [
      10,
      countryCode ? 40 : 10,
      10,
      icon ? theme.input.icon.size + 20 : 10,
    ],
    border: [
      1,
      'solid',
      error ? theme.default.color.error : theme.input.line.color,
    ],
    borderRadius: theme.input.line.radius,
    transition: ['border-color', '.5s', 'ease-in-out'],
    '&:focus': {
      borderColor: error ?
        theme.default.color.error :
        theme.input.line.focusColor,
    },
    '&:focus + svg': {
      fill: error ? theme.default.color.error : theme.input.icon.focusColor,
    },
  }),
});

const SliderInput = ({
  disabled,
  min,
  max,
  marks,
  step,
  value,
  defaultValue,
  onChange,
  placeholder,
  allowIntegerOnly=true,
  icon,
  className,
  label,
}) => {
  const theme = useTheme();
  const classes = useLocalStyles({disabled, icon, theme});
  const onSliderInputChange = useCallback((e) => {
    if (onChange) {
      let number = Number(trim(e.target.value));
      if (allowIntegerOnly) {
        number = parseInt(trim(e.target.value), 10);
      }
      if (number < min) {
        onChange(min);
      } else if (number > max) {
        onChange(max);
      } else {
        onChange(number);
      }
    }
  }, [min, max]);
  return (
    <div className={`${classes.wrapper} ${className || ''}`} tabIndex={-1}>
      {label && (
        <label className={classes.label}>
          {label}
        </label>
      )}
      <div className={classes.sliderWrapper}>
        <Slider
          className={classes.slider}
          disabled={disabled}
          min={min}
          max={max}
          marks={marks}
          step={step}
          value={value}
          defaultValue={defaultValue}
          activeDotStyle={{
            borderColor: theme.default.color.primary,
          }}
          trackStyle={{
            backgroundColor: theme.default.color.primary,
          }}
          handleStyle={{
            border: `solid 3px ${theme.default.color.primary}`,
            width: '20px',
            height: '20px',
            marginTop: '-8px',
          }}
          onChange={onChange}
        />
        <div
          className={`${classes.inputWrapper} ${
            classes.sliderInput || ''
          }`}>
          <input
            placeholder={placeholder}
            min={min}
            max={max}
            step={step}
            value={value}
            type={INPUT_TYPE.NUMBER}
            className={classes.input}
            disabled={disabled}
            onChange={onSliderInputChange}
            tabIndex={0}
            autoComplete='off'
            defaultValue={defaultValue}
          />
          {icon && React.cloneElement(icon, {
            color: theme.input.icon.color,
            size: theme.input.icon.size,
          })}
        </div>
      </div>
    </div>
  );
};

export default SliderInput;
