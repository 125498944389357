import {Grid, GridItem, toast, useRequest} from 'aida-components';
import CaseDetails from 'views/claims/claims-details/retail/main-claim/case-details';
import ClientDetails from 'views/claims/claims-details/retail/main-claim/client-details';
import Assessment from 'views/claims/claims-details/retail/main-claim/assessment';
import UncoveredCharges from 'views/claims/claims-details/retail/main-claim/uncovered-charges';
import AiResult from 'views/claims/claims-details/retail/main-claim/ai-result';
import AdditionalChecks from 'views/claims/claims-details/retail/main-claim/additional-checks';
import {Fragment, useEffect} from 'react';
import useURL from 'hooks/useURL';

const MainClaim = ({claimNumber, handleClaimAvailability}) => {
  const API_URLs = useURL();

  const [{status, response}, makeRequest, {SUCCESS, ERROR}, source] = useRequest(
    API_URLs.CLAIM_DETAILS,
    {
      config: {
        params: {
          claim_id: claimNumber,
        },
      },
    },
  );

  useEffect(() => {
    makeRequest();
    return () => {
      source && source.cancel();
    };
  }, [claimNumber]);

  useEffect(() => {
    if (status === ERROR) {
      if (response && response.header && response.header.message) {
        toast.error(response.header.message);
      }
      handleClaimAvailability && handleClaimAvailability(false);
    }
  }, [status]);

  return (
    <Fragment>
      {status === SUCCESS && response && response.data && (
        <Grid gridArea={['c1 c1 c1 c1 c1 c1 c1 c1 c2 c2 c2 c2']}>
          <GridItem
            name='c1'
            component={
              <div>
                <CaseDetails
                  claimDetails={response.data}
                  glType={response.data.gl_clm_type}
                  requestType={response.data.request_type}
                />
                <ClientDetails
                  claimDetails={response.data}
                  businessType={response.data.LOB}
                />
                <Assessment
                  claimDetails={response.data}
                  glType={response.data.gl_clm_type}
                  businessType={response.data.LOB}
                />
                {response.data.gl_clm_type === 'FGL' && (
                  <UncoveredCharges
                    claimDetails={response.data}
                  />
                )}
              </div>
            }
          />
          <GridItem
            name='c2'
            component={
              <div>
                <AiResult
                  claimDetails={response.data}
                />
                <AdditionalChecks
                  claimDetails={response.data}
                  businessType={response.data.LOB}
                />
              </div>
            }
          />
        </Grid>
      )}
    </Fragment>
  );
};

export default MainClaim;
