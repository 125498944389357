import {
  Card,
  classNames,
  createUseStyles,
  moment,
  toast,
  useAuthentication,
  useRequest,
  useTheme,
} from 'aida-components';
import {Fragment, useEffect, useState} from 'react';

import {useAppStyles} from 'helper/app-styles';
import fileDownload from 'js-file-download';
import useURL from 'hooks/useURL';
import DownloadCSV from 'components/download-csv';
import Overview from 'views/stp-rate-calculator/overview';
import Chart from 'views/stp-rate-calculator/chart';
import {getEditPermission, VIEWS} from 'helper/routers';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  cardHeight: {
  },
});

const tabs = [{
  id: 'overview',
  text: 'Overview',
}, {
  id: 'chart',
  text: 'Chart',
}];

const StpResults = ({filters}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.STP_RATE_CALCULATOR.key,
  );
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const API_URLs = useURL();
  const [{status, response}, makeRequest, {SUCCESS, ERROR}] = useRequest(
    API_URLs.STP_CALCULATOR_PDF_URL, {
      config: {
        responseType: 'blob',
        params: {
          from_date: filters && filters.fromDate,
          to_date: filters && filters.toDate,
          lob: filters && filters.businessLine,
          provider_type: filters && filters.providerType,
          hospital_tier: filters && filters.hospitalTier,
          visit_type: filters && filters.visitType,
          insurance_plan: filters && filters.insurancePlan,
          request_type: filters && filters.requestType,
          // claim_event_type: filters && filters.submissionType,
          // panel_type: filters && filters.doctorFlag ? filters.doctorFlag.join(',') : null,
          total_bill_amount_value: filters && filters.claimAmount,
          machine_confidence: filters && filters.machineConfidence,
          outlier_score: filters && filters.outlierScore,
          ai_decision: filters && filters.aiDecisions ? filters.aiDecisions.join(',') : null,
          outlier_detected: filters && filters.outlierFlag ? filters.outlierFlag.join(',') : null,
        },
      },
    });

  const exportToPDF = () => {
    makeRequest();
  };

  useEffect(() => {
    const time = moment().format('YYYYMMDD_HHmmss');
    if (status === SUCCESS && response) {
      fileDownload(response, `Auto-Adjudication-Calculator-${time}.pdf`, 'application/pdf');
    } else if (status === ERROR && response.header) {
      toast.error(response.header.message);
    }
  }, [response, status]);

  return (
    <Fragment>
      <ul className={classes.functions}>
        <li>
          {
            canEdit && <DownloadCSV
              params={{
                from_date: filters && filters.fromDate,
                to_date: filters && filters.toDate,
                lob: filters && filters.businessLine,
                provider_type: filters && filters.providerType,
                hospital_tier: filters && filters.hospitalTier,
                visit_type: filters && filters.visitType,
                insurance_plan: filters && filters.insurancePlan,
                request_type: filters && filters.requestType,
                // claim_event_type: filters && filters.submissionType,
                // panel_type: filters && filters.doctorFlag ? filters.doctorFlag.join(',') : null,
                total_bill_amount_value: filters && filters.claimAmount,
                machine_confidence: filters && filters.machineConfidence,
                outlier_score: filters && filters.outlierScore,
                ai_decision: filters && filters.aiDecisions ? filters.aiDecisions.join(',') : null,
                outlier_detected: filters && filters.outlierFlag ? filters.outlierFlag.join(',') : null,
              }}
              url={API_URLs.STP_CALCULATOR_EXPORT_URL}
              prefix='STP_calculator'
              text='Export to CSV'
            />
          }

        </li>
      </ul>
      <Card
        className={classNames(classes.space, classes.cardHeight)}
        tabs={tabs}
        selectedTab={activeTab}
        onTabChange={setActiveTab}
      >
        <h3 className={classes.cardTitle}>AI Auto Adjudication Results</h3>
        <div>
          {activeTab === tabs[0].id && <Overview filters={filters}/>}
          {activeTab === tabs[1].id && <Chart filters={filters}/>}
        </div>
      </Card>
    </Fragment>
  );
};

export default StpResults;
