import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  DotLoading,
  Icon,
  IconNames,
  toast,
  useAuthentication,
} from 'aida-components';
import {useEffect} from 'react';
import useURL from 'hooks/useURL';
import useRequest from 'hooks/useRequest';

export const SaveButton = ({claimId, updatedRowsData, onSuccess, disabled}) => {
  const API_URL = useURL();
  const {authUserInfo} = useAuthentication();
  const [
    {status, response},
    makeRequest,
    {FETCHING, SUCCESS, ERROR},
  ] = useRequest(API_URL.UPDATE_LINEITEM_LIST, {
    verb: 'post',
    params: {
      line_item: updatedRowsData,
    },
    config: {
      headers: {
        claim_id: claimId,
        lan_id: authUserInfo.email,
      },
    },
  });

  useEffect(() => {
    if (status === ERROR) {
      if (response && response.error) {
        toast.error(response.error.message);
      } else {
        toast.error('Update Failed');
      }
    }
    if (status === SUCCESS) {
      toast.success('Update Successful', response);
      if (response.data.success) {
        onSuccess && onSuccess();
      }
    }
  }, [status]);

  return (
    <Button
      text='Save'
      disabled={disabled}
      type={BUTTON_TYPE.PRIMARY}
      size={BUTTON_SIZE.MEDIUM}
      onClick={makeRequest}
      icon={status === FETCHING ? <DotLoading/> : <Icon name={IconNames.Save}/>}
    />
  );
};
