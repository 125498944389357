import {forwardRef, useEffect} from 'react';
import useURL from 'hooks/useURL';
import {createUseStyles, toast, useTheme} from 'aida-components';
import {useAppStyles} from 'helper/app-styles';
import Pie from 'views/stp-rate-calculator/pie';
import Column from 'views/stp-rate-calculator/column';
import _ from 'lodash';
import {roundNumber} from 'helper/utils';
import useRequest from 'hooks/useRequest';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  chartWrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& >p': {
      padding: '20px',
    },
  },
  half: {
    width: '48%',
    marginBottom: 20,
  },
  full: {
    width: '100%',
    marginTop: 20,
  },
  strong: {
    fontWeight: 600,
  },
});

const Chart = forwardRef(({filters}, commingRef) => {
  const API_URL = useURL();

  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const [
    {status, response},
    makeRequest,
    {FETCHING, SUCCESS, ERROR},
    source,
  ] = useRequest(API_URL.STP_CALCULATOR_CHART_URL, {
    config: {
      params: {
        from_date: filters && filters.fromDate,
        to_date: filters && filters.toDate,
        lob: filters && filters.businessLine,
        provider_type: filters && filters.providerType,
        hospital_tier: filters && filters.hospitalTier,
        visit_type: filters && filters.visitType,
        insurance_plan: filters && filters.insurancePlan,
        request_type: filters && filters.requestType,
        // claim_event_type: filters && filters.submissionType,
        // panel_type: filters && filters.doctorFlag ? filters.doctorFlag.join(',') : null,
        total_bill_amount_value: filters && filters.claimAmount,
        machine_confidence: filters && filters.machineConfidence,
        outlier_score: filters && filters.outlierScore,
        ai_decision: filters && filters.aiDecisions ? filters.aiDecisions.join(',') : null,
        outlier_detected: filters && filters.outlierFlag ? filters.outlierFlag.join(',') : null,
      },
    },
  });

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      makeRequest();
    }
    return () => {
      source && source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    if (status === ERROR && response.header) {
      toast.error(response.header.message);
    }
  }, [status]);

  if (status === SUCCESS) {
    return (
      <div className={classes.chartWrapper} ref={commingRef}>
        <div className={classes.half}>
          <Pie
            data={
              status === SUCCESS && response.data && response.data.stpRateChart
            }
            isLoading={status === FETCHING}
            title='Auto Adjudication Rate'
          />
          {status === SUCCESS && response.data && (
            <p>
              Number of Auto Adjudication Claims by AI:{' '}
              <span className={classes.strong}>{roundNumber({
                number: response.data.numberOfSTPClaims,
                isFormatNumber: true,
                roundTo: 0,
              })}</span>
            </p>
          )}
        </div>
        <div className={classes.half}>
          <Pie
            data={
              status === SUCCESS &&
              response.data &&
              response.data.stpAccuracyChart
            }
            isLoading={status === FETCHING}
            title='Auto Adjudication Accuracy'
          />
          {status === SUCCESS && response.data && (
            <p>
              Number of Claims matching Assessor
              Decision:{' '}
              <span className={classes.strong}>{roundNumber({
                number: response.data.totalNumberOfCorrectDecisions,
                isFormatNumber: true,
                roundTo: 0,
              })}</span>
            </p>
          )}
        </div>
        <div className={classes.full}>
          {status === SUCCESS && response.data && (
            <p>
              Total Number of Claims submitted to AIA:{' '}
              <span className={classes.strong}>{roundNumber({
                number: response.data.totalNumberOfClaims,
                isFormatNumber: true,
                roundTo: 0,
              })}</span>
            </p>
          )}
          <Column
            data={status === SUCCESS && response.data && response.data.chart}
            isLoading={status === FETCHING}
          />
        </div>
        <div className={classes.half}>
          {status === SUCCESS && response.data && (
            <p>
              Average Claim Amount Per month (False Declined):{' '}
              <span className={classes.strong}>{roundNumber({
                number: response.data.falseDeclinedAverageDollarValue,
                isFormatNumber: true,
                roundTo: 2,
              })}</span>
            </p>
          )}
        </div>
        <div className={classes.half}>
          {status === SUCCESS && response.data && (
            <p>
              Average Claim Amount Per{' '}
              month (False Approved):{' '}
              <span className={classes.strong}>{roundNumber({
                number: response.data.leakageAverageDollarValue,
                isFormatNumber: true,
                roundTo: 2,
              })}</span>
            </p>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
});

export default Chart;
