export const UserTypeConfig = {
  RETAIL: {
    DATA_IDS: {
      CLAIMS_LISTING: {
        RECEIVED_DATE: 'received_date',
        SC_RECEIVED_DATE: 'UPDATE_DATE',
        CLAIM_NUMBER: 'pre_auth_ref_no',
        POLICY_HOLDER: 'policy_holder_name',
        PATIENT_NAME: 'patient_name',
        PATIENT_DOB: 'patient_dob',
        PATIENT_GENDER: 'patient_gender',
        POLICY_NUMBER: 'policy_number',
        PRODUCT_NAME: 'policy_name',
        POLICY_NUMBER_NAME: 'policy_name_number',
        DIAGNOSIS: 'diagnosis',
        SURGERY_OR_PROCEDURE: 'procedures',
        RULE_SIMPLE_DISEASE: 'simple_diseases_flag',
        RULE_ELECTIVE_SURGERY: 'elective_surgery_flag',
        RULE_DAY_SURGERY: 'day_surgery_flag',
        RULE_PACKAGE_PRICE: 'package_price_flag',
        LOB: 'LOB',
        GL_TYPE: 'gl_type',
        REQUEST_TYPE: 'request_type',
        VISIT_TYPE: 'visit_type',
        SOURCE_SYSTEMID: 'SOURCE_SYSTEMID',
        CLAIM_EVENT_TYPE: 'billcatgdesc',
        PROVIDER_TYPE: 'provider_type',
        PANEL_TYPE: 'claimpanelind',
        TOTAL_BILL_AMOUNT: 'total_estimated_amount',
        STP_DECISION: 'final_decision',
        AI_DECISION: 'aidecision',
        MACHINE_CONFIDENCE: 'machineconfidence',
        OUTLIER_DETECTED: 'outlierflag',
        UW_EXCLUSION: 'policy_uwexclusion_ind',
        GENERAL_EXCLUSION: 'policy_exclusion_ind',
        SPECIAL_ARRANGEMENT: 'special_arrangement_ind',
      },
      CLAIMS_360: {
        // Case Details
        CLAIM_NUMBER: 'case_number',
        SUBMISSION_TYPE: 'request_type',
        PROVIDER_NAME: 'provider_name',
        MRN_NUMBER: 'mrn_number',
        ADMISSION_DATE: 'admission_date',
        ADMISSION_DURATION: 'length_of_admission',
        PRINCIPAL_DOCTOR: 'admitting_doctor',
        EPAF_FILLED: 'epaf',
        DIAGNOSIS_1: 'first_diagnosis',
        DIAGNOSIS_2: 'second_diagnosis',
        DIAGNOSIS_3: 'third_diagnosis',
        DIAGNOSIS_4: 'fourth_diagnosis',
        DIAGNOSIS_5: 'fifth_diagnosis',
        ACCIDENT_FLAG: 'accident_ind',
        EMERGENCY_FLAG: 'emergency_ind',
        GL_NUMBER: 'gl_number',
        EVENT_TYPE: 'admission_type',
        PROVIDER_TYPE: 'provider_type',
        HOSPITAL_TIER: 'hospital_tier_desc',
        REG_NO: 'reg_no',
        EST_DISCHARGE_DATE: 'estimate_discharge_date',
        WARD_TYPE: 'room_type',
        SPECIALITY: 'doctor_speciality',
        MEDICAL_OFFICER: 'mo_name',
        CAUSE_AND_PATHOLOGY_1: 'first_cause_pathology',
        CAUSE_AND_PATHOLOGY_2: 'second_cause_pathology',
        CAUSE_AND_PATHOLOGY_3: 'third_cause_pathology',
        CAUSE_AND_PATHOLOGY_4: 'fourth_cause_pathology',
        CAUSE_AND_PATHOLOGY_5: 'fifth_cause_pathology',
        ACCIDENT_DETAILS: 'accident_details',
        TOP_UP: 'top_up_reason',
        IGL_REQ_AMT: 'igl_amount_req',
        ADD_REQ_TYPE: 'additional_request_type',
        ADD_REQ_DATE: 'additional_request_date',
        AGL_REQ_AMT: 'agl_amount_req',
        DISCHARGE_DATE: 'final_discharge_date',
        BILL_NO: 'final_bill_number',
        FOLLOW_UP_DATE: 'next_follow_up_visit',
        INCURRED_AMT: 'final_bill_amount',
        ADD_DOCTOR_1: 'additional_doctor1',
        ADD_DOCTOR_2: 'additional_doctor2',
        ADD_DOCTOR_3: 'additional_doctor3',
        ADD_DOCTOR_4: 'additional_doctor4',
        ADD_DOCTOR_5: 'additional_doctor5',
        ADD_DOCTOR_SPECIALITY_1: 'doctor_speciality1',
        ADD_DOCTOR_SPECIALITY_2: 'doctor_speciality2',
        ADD_DOCTOR_SPECIALITY_3: 'doctor_speciality3',
        ADD_DOCTOR_SPECIALITY_4: 'doctor_speciality4',
        ADD_DOCTOR_SPECIALITY_5: 'doctor_speciality5',
        // Patient Details
        PATIENT: 'patient_name',
        GENDER: 'gender',
        RACE: 'race',
        SPECIAL: 'special_status',
        IDENTIFICATION: 'nric',
        AGE: 'age',
        RELATIONSHIP: 'relationship',
        EB_MEMBER: 'eb_member_name',
        MEMBERSHIP_NO: 'membership_number',
        MEMBER_JOIN_DATE: 'member_join_date',
        MEMBER_END_DATE: 'termination_date',
        // Stage 1: Policy Eligibility Checking
        // *Shared
        POLICY_NUMBER: 'policy_no',
        PLAN: 'medical_plan',
        CO_SHARE_PERCENTAGE: 'co_share',
        DEDUCTIBLE: 'deductible',
        POTENTIAL_PRE_EXISTING: 'potential_preexisting_ind',
        POTENTIAL_PRE_EXISTING_PROB_GRAPH: 'potentialpreexisting_json',
        PROB_GRAPH_TABLE: {
          DIAGNOSIS: 'diagnosis',
          DOCTOR: 'requestDoc',
          EARLY_CLAIM_THRESHOLD: 'earlyClaimThresholdMonth',
          EARLY_CLAIM_INDICATOR: 'earlyClaimIndicator',
        },
        SPECIALIST_REQUEST: 'specialistrequest_json',
        SPECIALIST_TABLE: {
          REQUEST: 'request',
          SPECIALIST: 'request_spec',
          REQUEST_DATE: 'request_date',
        },
        TREATMENT: 'treatment',
        GENERAL_EXCLUSION: 'policy_exclusion_ind',
        GENERAL_EXCLUSION_REASON: 'policy_exclusion',
        // *IB
        PREMIUM_TO_DATE: 'prem_ptdate',
        POLICY_INCEPTION_DATE: 'plan_inception_date',
        GIO: 'gio',
        REINSTATEMENT_DATE: 'policy_reinstatement',
        RIDER_1_POLICY_INCEPTION_DATE: 'rider_inception_date',
        VITALITY_STATUS: 'vitality_status',
        OTHER_MEDICAL_PLANS: '',
        POLICY_EXCLUSION: 'policy_uwexclusion_ind',
        GRACE_PERIOD: 'grace_period',
        PLAN_DURATION: 'duration_since_inception_days',
        SOE: 'soe',
        REINSTATEMENT_DURATION: 'duration_since_reinstatement_days',
        CASH_VALUE: 'case_value',
        RIDER_1_POLICY_DURATION: 'duration_since_rider_inception_days',
        DEDUCTIBLE_WAIVER: 'deductible_waiver',
        LAST_LAPSE_DATE: 'last_lapse_date',
        // *EB
        POLICY_TYPE: 'policy_type',
        POLICY_STATUS: 'policy_status',
        GL_TYPE: 'gl_type',
        GL_FACILITY: 'gl_facility',
        COMPANY: 'company_name',
        POLICY_PERIOD: 'policy_period',
        PRODUCT: 'product',
        PROVIDER_NETWORK: 'provider_network',
        SPECIAL_ARRANGEMENTS: 'special_arrangement_ind',
        WAITING_PERIOD_30_DAYS: 'waiting_period_30days_ind',
        WAITING_PERIOD_120_DAYS: 'waiting_period_120days_ind',
        // Stage 2: Limit Utilization & Balance Checking
        // *IB
        STAGE2: 's2_json',
        S2_IB_TABLE: {
          MEDICAL_PLAN: 'medical_plan',
          ANNUAL_LIMIT: 'annual_limit_balance',
          LIFETIME_LIMIT: 'lifetime_limit_balance',
          DISABILITY_LIMIT: 'disability_limit',
        },
        // *EB
        RB_ENTITLEMENT: 'rb_entitlement',
        INNER_LIMIT: 'inner_limit',
        UTILIZATION_BALANCE: 'utilization_balance',
        LIMIT_WHOM: 'limit_whom',
        ICU_ENTITLEMENT: 'icu_entitlement',
        HPMS_LIMIT: 'hpms_limit',
        NSBN_LIMIT: 'nsbn_limit',
        SBEN_LIMIT: 'sben_limit',
        DYPR_LIMIT: 'dypr_limit',
        // Stage 3: Claims History Checking
        PENDING_CLAIM: 'pending_auc_cases_ind',
        CLOSED_CLAIM: 'closed_claim_ind',
        CLM_HIST_LIST: 'claim_history_list',
        CLAIM_HISTORY_TABLE: {
          GL_NUMBER: 'gl_no',
          CLAIM_NUMBER: 'claim_no',
          PRODUCT: 'product',
          ADMISSION_DATE: 'admission_date',
          DISCHARGE_DATE: 'discharge_date',
          HOSPITAL: 'hospital',
          DOCTOR: 'treating_doctor',
          DIAGNOSIS: 'diagnosis',
          TREATMENT: 'operationcd',
          INCURRED_AMT: 'paid_amount',
          CLAIM_STATUS: 'claim_status',
          IS_EXGRATIA: 'is_exgratia',
        },
        // Stage 4: Medical Necessity Checking
        S4_S5_TABLE: {
          DETAILS: 'detail',
          DESCRIPTION: 'desc',
          RECOMMENDATION: 'reco',
          AIA_BM: 'aia_bm',
          AIDA_BM: 'aida_bm',
          AVG_BM: 'avg_bm',
        },
        UNUSUAL_LOS: 'unusuallos',
        UNUSUAL_LOS_AIA_BM: 'unusual_los_aia_bm',
        UNUSUAL_LOS_AIDA_BM: 'unusual_los_aida_bm',
        UNUSUAL_LOS_AVG_BM: 'unusual_los_avg_bm',
        LOW_OCCURRENCE_ICD_AGE_COMBINATION: 'lowoccicdage',
        LOW_OCCURRENCE_GENDER_COMBINATION: 'lowoccicdgender',
        LOW_OCCURRENCE_ICD_TOSP_COMBINATION: 'lowoccicdsrg',
        EXCEED_IGL_BENCHMARK: 'exceed_igl_benchmark',
        // Stage 5: Overcharing / Servicing Checking
        HIGH_CLAIM_COST: 'highclaimcost',
        HIGH_CLAIM_COST_AIA_BM: 'high_claim_cost_aia_bm',
        HIGH_CLAIM_COST_AIDA_BM: 'high_claim_cost_aida_bm',
        HIGH_CLAIM_COST_AVG_BM: 'high_claim_cost_avg_bm',
        SIMILAR_TOSP: 'multisimilarsurgeries',
        HIGH_AVG_ROOM_CHARGE: 'highavgdailyrnb',
        HIGH_EXPENSE_CATEGORY_INC_AMT: 'highexpensecatamt',
        // Final Auto Assessment Results
        FINAL_STP_REASON: 'finaldecision',
        NON_STP_REASONS: 'non_stp_reason',
        REASON_TABLE: {
          STAGE: 'stage',
          DETAILS: 'detail',
        },
        // AIML Decision
        AI_DECISION: 'aidecision',
        MACHINE_CONFIDENCE: 'machineconfidence',
        // Outlier Detection Score
        OUTLIER_SCORE: 'outlierscore',
        OUTLIER_REASONS: 'outlier_reasons',
        // AIML Additional Checking
        // *Shared
        DOCTOR_IN_WATCHLIST: 'doctor_in_watchlist',
        INTERVAL_READMISSION_FLAG: 'interval_of_readmissions_flag',
        HIGH_FREQ_DOC_VISITS: 'high_frequency_readmissions',
        ICD_REVIEW_LIST_APPROVE: 'icd_for_review_list_approve',
        ICD_REVIEW_LIST_DECLINE: 'icd_for_review_list_decline',
        UNDER_WAITING_PERIOD: 'claim_under_waiting_period',
        PEC_IDENTIFIED: 'pec_identified',
        DIAGNOSIS_EXCLUSION: 'policy_diagnosis_exclusion',
        CONGENITAL_CONDITION: 'congenital_condition',
        EXCLUSION_LUMP_AND_BUMP: 'exclusion_lump_and_bump',
        TOP_10_AUC_PROCEDURE: 'top_10_auc_procedure',
        DAYCARE_PROCEDURE: 'daycare_procedure',
        // *IB
        CLIENT_IN_WATCHLIST: 'client_in_watchlist',
        AGENT_IN_WATCHLIST: 'agent_in_watchlist',
        // *EB - SPECIAL_ARRANGEMENTS under Stage 1 as well
        // Total Uncovered Charges
        NON_PAYABLE_ITEMS: 'nonpayable_json',
        NON_PAYABLE_TABLE: {
          LI_FROM_DATE: 'ITEM_DATE_FROM',
          LI_TO_DATE: 'ITEM_DATE_TO',
          DATE: 'BILL_DATE',
          ITEM_DESC: 'LINEITEM_DESC',
          QUANTITY: 'QUANTITY',
          PRICE_PER_UNIT: 'PRICE_PER_UNIT',
          TOTAL_AMOUNT: 'CHARGE_AMT',
        },
        CREDIT_NOTE_ITEMS: 'creditnote_json',
        CREDIT_NOTE_TABLE: {
          DATE: '',
          ITEM_DESC: '',
          QUANTITY: '',
          OVERCHARGED_AMOUNT: '',
          TOTAL_CN: '',
          CN_REASON: '',
        },
        AUC_ITEMS: 'auc_json',
        AUC_TABLE: {
          DATE: '',
          ITEM_DESC: '',
          QUANTITY: '',
          OVERCHARGED_AMOUNT: '',
          TOTAL_AUC: '',
          AUC_REASON: '',
        },
        // Unused
        PROVIDER_IN_WATCHLIST: 'provider_in_watchlist',
        LOW_OCCURRENCE_EXPENSE_CATEGORY: 'lowoccexpensecat',
        PMIREQ: 'pmireq',
        PANEL_INDICATOR: 'claimpanelind',
        WARD_OF_DISCHARGE: 'dischargewardclasscddesc',
        CAUSE_OF_INJURY: 'causeofinjury',
        HRN_NO: 'hrnkey',
        BILL_TYPE: 'billcatgdesc',
        PRE_AUTH_REF_NO: 'preauthreferencenumber',
        SOURCE_OF_REFERRAL: 'referralsource',
        TYPE_OF_OUTCOME: 'outcometype',
        UPGRADE_DATE: 'main_planchangedt',
        UNDERWRITING_OPTION: 'main_udwoption',
        ESTABLISHED_PRE_EX_COND: 'clnt_preex_cond_json',
        MAIN_UW_EXCLUSION_JSON: 'main_uwexclusion_json',
        RIDER_1_PLAN: 'rider_1_plannm',
        RIDER_1_UPGRADE_DATE: 'rider_1_planchangedt',
        RIDER_1_GENERAL_EXCLUSION: 'rider_1_generalexclusion',
        RIDER_1_GENERAL_EXCLUSION_REASON: 'rider_1_generalexclusionreason',
        RIDER_1_UNDERWRITING_OPTION: 'rider_1_udwoption',
        RIDER_1_REINSTATEMENT_DATE: 'rider_1_reinsdt',
        RIDER_1_POTENTIAL_PRE_EXISTING: 'rider_1_potentialpreexisting',
        RIDER_1_POTENTIAL_PRE_EXISTING_PROB_GRAPH: 'rider_1_potentialpreexisting_json',
        RIDER_1_UW_EX: 'rider_1_uwexclusion_json',
        RIDER_2_PLAN: 'rider_2_plannm',
        RIDER_2_POLICY_INCEPTION_DATE: 'rider_2_policyinceptiondt',
        RIDER_2_UPGRADE_DATE: 'rider_2_planchangedt',
        RIDER_2_GENERAL_EXCLUSION: 'rider_2_generalexclusion',
        RIDER_2_GENERAL_EXCLUSION_REASON: 'rider_2_generalexclusionreason',
        RIDER_2_UNDERWRITING_OPTION: 'rider_2_udwoption',
        RIDER_2_REINSTATEMENT_DATE: 'rider_2_reinsdt',
        RIDER_2_POTENTIAL_PRE_EXISTING: 'rider_2_potentialpreexisting',
        RIDER_2_POTENTIAL_PRE_EXISTING_PROB_GRAPH: 'rider_2_potentialpreexisting_json',
        RIDER_2_UW_EX: 'rider_2_uwexclusion_json',
        MIXED_DECISION: 'mixed_decision_hist_ind',
        EXGRATIA_IND: 'exgratiaind',
        TOTAL_BILL_AMOUNT: 'totalbillamount',
        DOCTOR_ATTENDANCE_FEE: 'doctor_attendance_fee',
        TOTAL_SURGERY_COST: 'total_surgery_cost',
        SURGICAL_IMPLANT_COST: 'surgical_implant_cost',
        DOCTOR_SIMILAR_SPECIALTY: 'doctorsimilarspecialty',
        USER_DECISION: 'userdecision',
        REJECT_CODE: 'USER_QC_REMARKCODE',
        REJECT_CODE_DESC: 'USER_QC_REMARKDESC',
        UPDATED_BY_USER: 'UPDATE_USER',
        UPDATED_AT: 'UPDATE_DATE',
        LIFE_ONCE_TOSP_NSTP_IND: 'life_once_tosp_nstp_ind',
        PRE_EX_COND_TABLE: {
          DIAGNOSIS: 'icdHit',
          RELATED: 'relevantInd',
          PRE_EX_REMARKS: 'remark',
          PRE_EX_CATEGORY: 'category',
        },
        RIDER_UW_EX: {
          DIAGNOSIS: 'icdHit',
          RELATED: 'relevantInd',
          UW_EX: 'exclusionDesc',
        },
        BENEFIT_DETAILS: 'benefit_details_json',
        BENEFIT_DETAILS_TOTAL_BILL: 'totalbillamt_fb',
        BENEFIT_DETAILS_DOC_ATTENDANCE: 'doctor_attendance_fb',
        BENEFIT_DETAILS_SURGERY: 'surgery_fb',
        PMI_DATA: 'pmi_data',
        PMI_DATA_THIRD_PARTY: 'thirdparty_json',
        PMI_DATA_CANCER_DRUG: 'cancer_drug_json',

      },
    },
    SELECT_OPTION_TYPES: {
      PANEL_TYPE: [
        {value: 'PANEL', label: 'Panel'},
        {value: 'NONPANEL', label: 'Non Panel'},
      ],
      CLAIM_EVENT_TYPE: [
        {value: 'Inpatient', label: 'Inpatient'},
        {value: 'Outpatient', label: 'Outpatient'},
        {value: 'Day Surgery', label: 'Day Surgery'},
        {value: 'Day Rreatment', label: 'Day Treatment'},
      ],
      VISIT_TYPE: [
        {value: 'Inpatient', label: 'Inpatient'},
      ],
      PROVIDER_TYPE: [
        {value: 'Community Hospital', label: 'Community Hospital'},
        {value: 'Hospice', label: 'Hospice'},
        {value: 'Nursing Home', label: 'Nursing Home'},
        {value: 'Other Hospital', label: 'Other Hospital'},
        {value: 'Overseas Hospital', label: 'Overseas Hospital'},
        {value: 'Polyclinic', label: 'Polyclinic'},
        {value: 'Private Clinic', label: 'Private Clinic'},
        {value: 'Private Hospital', label: 'Private Hospital'},
        {value: 'Public Hospital', label: 'Public Hospital'},
      ],
      BUSINESS_LINE: [
        {value: 'IB', label: 'IB'},
        {value: 'EB', label: 'EB'},
      ],
      WORKFLOW_TYPE: [
        {value: 'Claim', label: 'Claim'},
        {value: 'Coverage', label: 'Coverage'},
      ],
      INSURANCE_TYPE: [
        {value: 'Conventional', label: 'Conventional'},
        {value: 'Takaful', label: 'Takaful'},
        {value: 'General', label: 'General'},
      ],
      REQUEST_TYPE: [
        {value: 'Insured', label: 'Insured'},
        {value: 'ASO', label: 'ASO'},
      ],
      AI_DECISION: [
        {value: 'APPROVE', label: 'Approve'},
        {value: 'REJECT', label: 'Reject'},
        {value: 'REVIEW', label: 'Review'},
      ],
      STP_DECISION: [
        {value: 'APPROVE', label: 'APPROVE'},
        {value: 'REJECT', label: 'REJECT'},
        {value: 'REVIEW', label: 'REVIEW'},
      ],
    },
  },
};
