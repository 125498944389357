import {useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom';

import {createUseStyles, useTheme} from 'aida-components';

const useStyles = createUseStyles({
  modal: ({theme})=>({
    position: 'fixed',
    zIndex: theme.modal.zIndex,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.modal.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  wrapper: {
    minWidth: 300,
    width: '30%',
  },
});

const Modal = ({className, children}) => {
  const [modalRoot, setModalRoot] = useState();
  const el = useMemo(() => document.createElement('div'), []);
  const theme = useTheme();
  const classes = useStyles({theme});

  el.className = classes.modal;

  useEffect(()=>{
    const elRoot = document.getElementById('modal-root');
    if (elRoot) {
      setModalRoot(elRoot);
    } else {
      const node = document.createElement('div');
      node.id='modal-root';
      document.body.appendChild(node);
      setModalRoot(node);
    }
    return () => {
      const elRoot = document.getElementById('modal-root');
      elRoot && document.body.removeChild(elRoot);
    };
  }, []);

  useEffect(() => {
    modalRoot && modalRoot.appendChild(el);
    return () => modalRoot && modalRoot.removeChild(el);
  }, [modalRoot]);

  return ReactDOM.createPortal(<div className={`${classes.wrapper} ${className || ''}`}>
    {children}
  </div>, el);
};


export default Modal;
