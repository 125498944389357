import React, {useEffect, useState} from 'react';
import {MdCancel, MdModeEdit} from 'react-icons/md';
import _ from 'lodash';
import {Button, BUTTON_SIZE, BUTTON_TYPE, Input, INPUT_TYPE, toast} from 'aida-components';

import {Cell} from 'components/table';

import useURL from 'hooks/useURL';
import {roleOptions} from 'helper/routers';
import styles from './management.module.scss';
import useRequest from 'hooks/useRequest';

const EditUser = ({user, handleSuccess, handleCancel}) => {
  const API_URL = useURL();
  const [role, setRole] = useState(user.role);

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] = useRequest(API_URL.USERS_UPDATE_URL, {
    verb: 'post',
    params: {
      lan_id: user.lan_id,
      role,
    },
  });

  useEffect(() => {
    if (status === ERROR) {
      toast.error((response.data && response.data.detail) || `Can't update "${user.lan_id}". Please try again.`);
    }
    if (status === SUCCESS) {
      if (handleSuccess) {
        handleSuccess();
      }
      toast.success(`"${user.lan_id}" was updated successfully`);
    }
  }, [status]);

  const onUpdateRole = (role) => setRole(role.value);
  return (
    <tr>
      <Cell>{user.lan_id}</Cell>
      <Cell>
        <Input
          className={styles.zeroMargin}
          type={INPUT_TYPE.SELECT}
          placeholder='Choose a role'
          options={roleOptions}
          onChange={onUpdateRole}
          defaultValue={_.find(roleOptions, ['value', user.role])}
        />
      </Cell>
      <Cell>{user.created_by}</Cell>
      <Cell align='center'>
        <span className={styles[user.status]}>{user.status}</span>
      </Cell>
      <Cell align='right'>
        <Button
          className={styles.editBtn}
          type={BUTTON_TYPE.LINK}
          text='Save'
          size={BUTTON_SIZE.SMALL}
          iconPosition='left'
          icon={<MdModeEdit size={20} />}
          disabled={status === FETCHING}
          onClick={makeRequest}
        />
        <Button
          className={styles.cancelBtn}
          type={BUTTON_TYPE.LINK}
          text='Cancel'
          size={BUTTON_SIZE.SMALL}
          iconPosition='left'
          icon={<MdCancel size={20} />}
          onClick={handleCancel}
        />
      </Cell>
    </tr>
  );
};

export default EditUser;
