import {Fragment} from 'react';
import {Card, classNames, createUseStyles, Table, useTheme} from 'aida-components';
import {checkEmptyString, roundNumber} from 'helper/utils';
import {UserTypeConfig} from 'helper/user-type-config';
import {useStyles} from 'views/claims/styles';

const useLocalStyles = createUseStyles({
  ...useStyles,
  table: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tableSubText: {
    fontSize: 12,
  },
  head: ({theme}) => ({
    background: 'transparent',
    borderBottom: '1px solid #999',
    color: theme.default.color.text,
    ...theme.typography.title,
    fontWeight: 600,
    fontSize: 16,
  }),
  row: {
    fontSize: '14px',
    '&> span': {
      fontSize: '14px',
    },
  },
  aiResultContainer: {
    // maxHeight: 632,
    // overflowY: 'scroll',
  },
  stp: {
    background: '#fce7e3',
    // boxShadow: '1px 2px 5px #00000040',
    // marginBottom: 20,
    // padding: 10,
    // borderRadius: 5,
  },
  boldLabel: ({theme}) => ({
    ...theme.typography.h4,
    fontWeight: 600,
    display: 'inline-block',
    width: '60%',
    boxSizing: 'border-box',
    marginBottom: 10,
  }),
  boldValue: {
    fontWeight: 400,
    display: 'inline-block',
    width: '40%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
  },
  outlierListValue: {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    width: '65%',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    lineHeight: '20px',
  },
  paragraph: {
    lineHeight: '20px',
    fontWeight: 'bolder',
  },
  outlierScore: ({theme, score}) => {
    let colorHex = theme.default.color.text;

    if (score === 0) {
      colorHex = '#36b37e';
    } else if (score > 0 && score <= 0.3) {
      colorHex = 'lighten(#ffab00, 20%)';
    } else if (score > 0.3 && score <= 0.7) {
      colorHex = '#ffab00';
    } else if (score > 0.7) {
      colorHex = '#ff5630';
    }
    return {
      color: colorHex,
      fontWeight: 'bolder',
    };
  },
});

const getReasonHead = ({classes, REASON_ID}) => {
  return [{
    title: 'Stage / Guideline',
    id: REASON_ID.STAGE,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 50,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[REASON_ID.STAGE])}
    </div>),
  }, {
    title: 'Details',
    id: REASON_ID.DETAILS,
    align: 'left',
    headClass: classes.head,
    columnClass: classes.row,
    width: 100,
    display: (record) => (<div className={classes.row}>
      {checkEmptyString(record && record[REASON_ID.DETAILS])}
    </div>),
  }];
};

const AiResult = ({claimDetails}) => {
  // const {authUserInfo} = useAuthentication();
  // const {userType} = authUserInfo;

  const userType = 'retail';

  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];
  const REASON_ID = CLAIM_360_IDS['REASON_TABLE'];

  const theme = useTheme();
  const classes = useLocalStyles({theme: theme, score: claimDetails && claimDetails[CLAIM_360_IDS.OUTLIER_SCORE]});

  const reasonHeads = getReasonHead({classes, REASON_ID});

  return (
    <Fragment>
      <Card className={classNames(classes.space, classes.stp)}>
        <ul>
          <li>
            <h3 className={classes.boldLabel}>Final Auto Assessment Result:</h3>
            <p className={classes.boldValue}>
              {checkEmptyString(claimDetails && claimDetails[CLAIM_360_IDS.FINAL_STP_REASON])}
            </p>
          </li>
        </ul>
        <br/>
        <ul className={classes.listWrapper}>
          <Table
            className={classes.table}
            heads={reasonHeads}
            data={
              claimDetails &&
                claimDetails[CLAIM_360_IDS.NON_STP_REASONS] &&
                JSON.parse(claimDetails[CLAIM_360_IDS.NON_STP_REASONS])
            }
          />
        </ul>
      </Card>
      <Card className={classNames(classes.space, classes.stp)}>
        <ul className={classes.listWrapper}>
          <li>
            <h3 className={classes.boldLabel}>AIML Final Decision:</h3>
            <p className={classes.boldValue}>
              {checkEmptyString(claimDetails && claimDetails[CLAIM_360_IDS.AI_DECISION])}
            </p>
          </li>
          <li>
            <h3 className={classes.boldLabel}>AIML Confidence:</h3>
            <p className={classes.boldValue}>{
              roundNumber({
                number: claimDetails && claimDetails[CLAIM_360_IDS.MACHINE_CONFIDENCE],
                isCheckEmpty: true,
                isMultiplyBy100: true,
                suffix: '%',
              })}
            </p>
          </li>
        </ul>
      </Card>
      <Card className={classNames(classes.space, classes.stp)}>
        <ul className={classes.listWrapper}>
          <li>
            <h3 className={classes.boldLabel}>Outlier Detection Score:</h3>
            <p className={classNames(classes.outlierScore, classes.boldValue)}>{
              roundNumber({
                number: claimDetails && claimDetails[CLAIM_360_IDS.OUTLIER_SCORE],
                isCheckEmpty: true,
              })}
            </p>
          </li>
        </ul>
        <br/>
        <p className={classes.paragraph}>Outlier Reasons :</p>
        {
          checkEmptyString(claimDetails && claimDetails[CLAIM_360_IDS.OUTLIER_REASONS]) !== '-' ? (
            <ol className={classes.orderedListWrapper}>
              {claimDetails && claimDetails[CLAIM_360_IDS.OUTLIER_REASONS].split('|').map((row, index) =>
                <li key={`outlier-reasons-${index}`}>{row}</li>)}
            </ol>
          ) : (
            <span>N/A</span>
          )
        }
        <br/>
      </Card>
    </Fragment>
  );
};

export default AiResult;
