/* eslint-disable max-len */
export const RETAIL_SAMPLE_TOTAL_BILL = [{
  rowName: 'Total Bill',
  incurredAmt: 10453.33,
  moh_25th_percentile: 9950.00,
  moh_50th_percentile: 11450.00,
  midpoint_50th_75th_percentile: 12050.00,
  moh_75th_percentile: 12650.00,
  aida_benchmark: 14700.00,
  panel_fee_schedule: null,
  pre_auth_charges: 10200.00,
  is_flag_inc_amt: true,
  highlight_bm: 'pre_auth_charges',
}];

export const GROUP_SAMPLE_TOTAL_BILL = [{
  rowName: 'Total Bill',
  incurredAmt: 57143.11,
  moh_25th_percentile: null,
  moh_50th_percentile: null,
  moh_75th_percentile: 12650.00,
  aida_benchmark: 14700.00,
  pre_auth_certificate: 10200.00,
  is_flag_inc_amt: false,
  highlight_bm: null,
}];

export const RETAIL_SAMPLE_DOCTOR_ATTENDANCE_FEE = [{
  rowName: 'Attendance Fee',
  incurredAmt: 1591.00,
  moh_fb_lb: 1391.00,
  moh_fb_avg: 1498.00,
  midpoint_fb_avg_up: 2247.00,
  moh_fb_ub: 2621.50,
  aida_benchmark: 2996.00,
  panel_fee_schedule: 2996.00,
  pre_auth_charges: 1498.00,
  is_flag_inc_amt: true,
  highlight_bm: 'pre_auth_charges',
}];

export const GROUP_SAMPLE_DOCTOR_ATTENDANCE_FEE = [{
  rowName: 'Doctor Fee',
  incurredAmt: 1908.10,
  aida_benchmark: null,
  panel_fees: null,
  pre_auth_certificate: null,
  moh_doc_ip_att_fee_bm: null,
  hosp_r_and_b: null,
  is_flag_inc_amt: false,
  highlight_bm: null,
}, {
  rowName: 'Room and Board',
  incurredAmt: 5510.00,
  aida_benchmark: null,
  panel_fees: null,
  pre_auth_certificate: null,
  moh_doc_ip_att_fee_bm: null,
  hosp_r_and_b: null,
  is_flag_inc_amt: false,
  highlight_bm: null,
}];

export const RETAIL_SAMPLE_SURGERY_FEE = [{
  id: 'sample-1-2',
  tosp: '1) TOSP: SF823A (4A) - ABDOMINAL WALL, VENTRAL/INCISIONAL/RECURRENT HERNIA, REPAIR (MIS/OPEN)',
  surgeon_name: 'YEUNG PO MAN BALDWIN',
  anaethestist: 'Lim Zhen Wei',
  op_date: '10/04/2022',
  rows: [{
    rowName: 'Surgeon Fee',
    incurredAmt: 7490.00,
    moh_fb_lb: 4708.00,
    moh_fb_avg: 6634.00,
    midpoint_fb_avg_up: 7597.00,
    moh_fb_ub: 8560.50,
    aida_benchmark: 9330.00,
    panel_fee_schedule: 4708.00,
    pre_auth_charges: null,
    is_flag_inc_amt: true,
    highlight_bm: 'panel_fee_schedule',
  }, {
    rowName: 'Anaethestist Fee',
    incurredAmt: 1872.50,
    moh_fb_lb: 1112.80,
    moh_fb_avg: 1439.15,
    midpoint_fb_avg_up: 1602.33,
    moh_fb_ub: 1765.50,
    aida_benchmark: 2332.50,
    panel_fee_schedule: 1177.00,
    pre_auth_charges: null,
    is_flag_inc_amt: true,
    highlight_bm: 'panel_fee_schedule',
  }],
  implant_rows: [],
}, {
  id: 'sample-2-3',
  tosp: '2) TOSP: SL804R (3C) - Retina, Tears, Cryotherapy or Photocoagulation (laser) (Bilateral)',
  surgeon_name: 'YEUNG PO MAN BALDWIN',
  anaethestist: 'Lim Zhen Wei',
  op_date: '10/04/2022',
  rows: [{
    rowName: 'Surgeon Fee',
    incurredAmt: 3477.50,
    moh_fb_lb: 1819.00,
    moh_fb_avg: 2541.25,
    midpoint_fb_avg_up: 2902.38,
    moh_fb_ub: 3263.50,
    aida_benchmark: 3550.00,
    panel_fee_schedule: 1819.00,
    pre_auth_charges: null,
    is_flag_inc_amt: true,
    highlight_bm: 'midpoint_fb_avg_up',
  }, {
    rowName: 'Anaethestist Fee',
    incurredAmt: 812.50,
    moh_fb_lb: null,
    moh_fb_avg: null,
    midpoint_fb_avg_up: null,
    moh_fb_ub: null,
    aida_benchmark: 980.00,
    panel_fee_schedule: 454.75,
    pre_auth_charges: null,
    is_flag_inc_amt: false,
    highlight_bm: 'aida_benchmark',
  }],
  implant_rows: [{
    rowName: 'Implants',
    incurredAmt: 2605.00,
    moh_25th_percentile: 1325.00,
    moh_50th_percentile: 1563.00,
    midpoint_50th_75th_percentile: 1681.50,
    moh_75th_percentile: 1800.00,
    aida_benchmark: 900.00,
    internal_benchmark: 1750.00,
    pre_auth_charges: null,
    is_flag_inc_amt: true,
    highlight_bm: 'internal_benchmark',
  }],
}];

export const GROUP_SAMPLE_SURGERY_FEE = [{
  id: 'sample-1-2',
  tosp: 'SF706G',
  doctor_name_mcr: 'A K M JAMIUR/M66291G',
  anaethestist_mcr: 'A ARAVIN KUMAR/M63836F',
  rows: [{
    rowName: 'Surgeon Fee',
    incurredAmt: 26908.50,
    moh_fb_lb: null,
    moh_avg_low_and_up: null,
    moh_ub: null,
    aida_benchmark: null,
    panel_fees: null,
    pre_auth_certificate: null,
    is_flag_inc_amt: false,
    highlight_bm: null,
  }, {
    rowName: 'Anaethestist Fee',
    incurredAmt: 12908.01,
    moh_fb_lb: null,
    moh_avg_low_and_up: null,
    moh_ub: null,
    aida_benchmark: null,
    panel_fees: null,
    pre_auth_certificate: null,
    is_flag_inc_amt: false,
    highlight_bm: null,
  }],
  implant_rows: [],
}, {
  id: 'sample-2-3',
  tosp: 'SF710B',
  doctor_name_mcr: 'TAN YANG WOO/P66222U',
  anaethestist_mcr: 'AARON CHONG WEI-LOONG/M63533B',
  rows: [{
    rowName: 'Surgeon Fee',
    incurredAmt: 26908.50,
    moh_fb_lb: null,
    moh_avg_low_and_up: null,
    moh_ub: null,
    aida_benchmark: null,
    panel_fees: null,
    pre_auth_certificate: null,
    is_flag_inc_amt: false,
    highlight_bm: null,
  }, {
    rowName: 'Anaethestist Fee',
    incurredAmt: 12908.01,
    moh_fb_lb: null,
    moh_avg_low_and_up: null,
    moh_ub: null,
    aida_benchmark: null,
    panel_fees: null,
    pre_auth_certificate: null,
    is_flag_inc_amt: false,
    highlight_bm: null,
  }],
  implant_rows: [{
    rowName: 'Implant Fee',
    incurredAmt: 57143.11,
    moh_25th_percentile: null,
    moh_50th_percentile: null,
    moh_75th_percentile: null,
    aida_benchmark: null,
    internal_benchmark: null,
    pre_auth_certificate: null,
    is_flag_inc_amt: false,
    highlight_bm: null,
  }],
}];

export const PMI_SAMPLE_DATA = {
  itemisedBill: [{
    category: 'Room & Board',
    itemDescription: 'NORMAL ROOM & BOARD - C',
    treatments: 1.00,
    charges: 70.00,
  }, {
    category: 'Daily Treatment Fee',
    itemDescription: 'Total Daily Treatment Fee',
    treatments: 1.00,
    charges: 70.00,
  }, {
    category: 'Surgeon Fee',
    itemDescription: 'Surgeon Fee',
    treatments: null,
    charges: 515.53,
  }, {
    category: 'Anaesthetist Fee',
    itemDescription: 'Anaesthetist Fee',
    treatments: null,
    charges: 258.17,
  }, {
    category: 'Facility Fee',
    itemDescription: 'Facility Fee',
    treatments: null,
    charges: 902.10,
  }, {
    category: 'Implants',
    itemDescription: 'Surgical Implants Fee',
    treatments: null,
    charges: 0.00,
  }, {
    category: 'Doctor Attendance Fee',
    itemDescription: 'Doctor Attendance Fee',
    treatments: null,
    charges: 33.60,
  }, {
    category: 'Investigation-Lab',
    itemDescription: 'Laboratory investigations',
    treatments: null,
    charges: 187.43,
  }, {
    category: 'Medical Consumables - Cat 1',
    itemDescription: 'Medical Consumables',
    treatments: null,
    charges: 7072.46,
  }, {
    category: 'Investigation - Scans Cat II',
    itemDescription: 'Other specialised investigations or procedures',
    treatments: null,
    charges: 14.82,
  }, {
    category: 'Others',
    itemDescription: 'Other type of charge not covered in any of the above categories',
    treatments: null,
    charges: 738.30,
  }, {
    category: 'Medications',
    itemDescription: 'Prescriptions',
    treatments: null,
    charges: 191.91,
  }, {
    category: 'Rehabilitative Service',
    itemDescription: 'Rehabilitative Service',
    treatments: null,
    charges: 96.45,
  }, {
    category: 'Ward Procedures',
    itemDescription: 'Ward Procedures',
    treatments: null,
    charges: 57.27,
  }, {
    category: 'Investigation - Cat 1',
    itemDescription: 'X-ray examinations or procedures',
    treatments: null,
    charges: 267.10,
  }],
  totalBillAmount: 10453.33,
  cancerDrugs: [{
    indicatorCode: 'C1000069',
    indicationDescription: 'Moderately severe dementia associated with Alzheimer\'s or Parkinson\'s Disease in ' +
      'patients with behavioural symptoms; as confirmed by a ' +
      'specialist physician (geriatrician, neurologist or psychiatrist); with reassessment every 3 - 6 months ' +
      'and continued only if there is ongoing clinical benefit.',
    drugCode: '32951000133102',
    drugDescription: 'Tegafur+ gimeracil+ oteracil potassium capsule (20 mg/5.8 mg/19.6 mg, 25 mg/7.25 mg/24.5 mg)',
    quantity: 1.00,
    charges: 9999.99,
  }, {
    indicatorCode: 'C100218',
    indicationDescription: 'For advanced RCC.',
    drugCode: '703563401000133100',
    drugDescription: 'Afatinib tablet (20 mg, 30 mg, 40 mg)',
    quantity: 10.00,
    charges: 8888.88,
  }],
  totalChargesAmount: 18888.87,
  thirdPartyPayorDetails: [{
    description: 'OTHERS TYPES OF THIRD-PARTY PAYMENTS - MOH - SURVEILLANCE SCREENING',
    charges: 105.00,
  }],
  totalThirdPartyPayment: 105.00,
  balanceBillAmount: 10348.33,
};

export const STP_SUMMARY = {
  numberOfPeriods: '3 Months',
  aiSTPRate: 97.29,
  aiAccuracy: 99.3,
  outlierFlagAdditionalAccuracy: 96.7,
  totalNumberOfClaims: 69393,
  totalNumberOfClaimsWithDecisions: 69300,
  numberOfSTPClaims: 69000,
  totalSTPClaimAmount: 690000,
  averageSTPClaimAmountPerMonth: 230000,
  totalSTPPayableAmount: 689061,
  averagePayableAMountPerMonth: 229687,
  falseDeclinedDollar: {
    numberOfClaims: 30,
    percentageOfClaims: 0.12,
    dollarValue: 300,
    averageDollarValue: 100,
  },
  leakage: {
    numberOfClaims: 63,
    percentageOfClaims: 0.37,
    dollarValue: 630,
    averageDollarValue: 210,
  },
};
export const STP_CONDITIONS = {
  body: {
    approvedClaimAmount: 10000,
    approvedMachineConfidence: 90,
    approvedNumberOfDays: 30,
    declinedClaimAmount: 10000,
    declinedMachineConfidence: 97,
    declinedNumberOfDays: 30,
  },
};

export const MODEL_HISTORY = {
  body: {
    modelMLList: [{
      modelVersion: 2.0,
      modelNo: 'ib_coverage_model_v1.0',
      modelUser: 'System',
      modelAccuracy: 0.997,
      selectedModel: 2,
      isSelected: 1,
    }, {
      modelVersion: 1.0,
      modelNo: 'eb_claim_model_v1.0',
      modelUser: 'System',
      modelAccuracy: 0.982,
      selectedModel: 1,
      isSelected: 0,
    }],
    lastModelSelectInformation: {
      currentStatus: 'ACTIVE',
      selectedModel: 2.0,
    },
  },
};

export const SYSTEM_NOTIFICATIONS = [{
  eventTime: '2022-06-15 10:06 PM',
  event: 'User Management',
  eventMessage: 'New User Added',
}, {
  eventTime: '2022-06-15 9:30 PM',
  event: 'User Management',
  eventMessage: 'User Deleted',
}];

export const USERS = [{
  lanId: 'slretail',
  role: 'Claims Manager',
  status: 'active',
}, {
  lanId: 'slretail_xyz',
  role: 'Claims Manager',
  status: 'active',
}];

export const SYSTEM_LOGS = [{
  actionDateTime: '2022-06-15 10:06 PM',
  lanId: 'slretail',
  actionMessage: 'System Action logs - 1',
}, {
  actionDateTime: '2022-06-15 9:30 PM',
  lanId: 'slretail_xyz',
  actionMessage: 'System Action logs - 2',
}];

export const FILE_UPLOAD_CONDITIONS = {
  admissionDate: '2022-06-15',
  payableAmountBuffer: 1000,
  totalBillSizeAndPreAuthAmountDiff: 1000,
  xPercent: 5,
  yDollar: 1000,
  isHigherOrLower: 'Y',
};

export const FILES = [{
  uploadId: 'file-1',
  uploadFileName: 'File - 1',
  claimType: 'Inpatient',
  validFlag: true,
  uploadDateTime: '2022-06-15 10:06 PM',
  uploadFileSize: 2,
}, {
  uploadId: 'file-2',
  uploadFileName: 'File - 2',
  claimType: 'Inpatient',
  validFlag: true,
  uploadDateTime: '2022-06-15 10:06 PM',
  uploadFileSize: 2.9,
}];

export const GROUPS = {
  RETAIL: [{
    description: 'Watchlist',
    businessTypes: [{
      displayName: 'Doctor Watchlist',
      value: 'bl_doc',
    }, {
      displayName: 'Client Watchlist',
      value: 'bl_client',
    }, {
      displayName: 'Provider Watchlist',
      value: 'bl_prov',
    }, {
      displayName: 'Agent Watchlist',
      value: 'bl_agent',
    }, {
      displayName: 'Non Auto Adjudication ICD list',
      value: 'bl_icd',
    }],
  }, {
    description: 'List of Fees',
    businessTypes: [{
      displayName: 'Fee Benchmark / Panel Fee',
      value: 'fee_bm',
    }, {
      displayName: 'R&B Fee',
      value: 'r_and_b_fee',
    }],
  }, {
    description: 'Customization List',
    businessTypes: [{
      displayName: 'Product Customizations',
      value: 'prod_cust',
    }],
  }],
  GROUP: [{
    description: 'Watchlist',
    businessTypes: [{
      displayName: 'Doctor Watchlist',
      value: 'bl_doc',
    }, {
      displayName: 'Client Watchlist',
      value: 'bl_client',
    }, {
      displayName: 'Provider Watchlist',
      value: 'bl_prov',
    }, {
      displayName: 'Agent Watchlist',
      value: 'bl_agent',
    }, {
      displayName: 'Non Auto Adjudication ICD list',
      value: 'bl_icd',
    }],
  }, {
    description: 'List of Fees',
    businessTypes: [{
      displayName: 'Fee Benchmark / Panel Fee',
      value: 'fee_bm',
    }, {
      displayName: 'R&B Fee',
      value: 'r_and_b_fee',
    }],
  }, {
    description: 'Customization List',
    businessTypes: [{
      displayName: 'Contract Customizations',
      value: 'contract_cust',
    }],
  }],
};

export const PREDICTIVE_MODEL_SUMMARY = {
  modelStatisticsRetail: {
    Categories: ['50%', '60%', '70%', '80%', '90%'],
    Total: [3622, 3596, 3580, 3511, 3238],
    Match: [3549, 3531, 3520, 3461, 3206],
    Mismatch: [73, 65, 65, 65, 32],
    // 'User Decision': [260, 206, 193, 189, 185],
  },
  modelStatisticsGroup: {
    Categories: ['50%', '60%', '70%', '80%', '90%'],
    Total: [8898, 8819, 8734, 8611, 8387],
    Match: [8688, 8650, 8599, 8520, 8343],
    Mismatch: [210, 169, 135, 91, 44],
    // 'User Decision': [260, 206, 193, 189, 185],
  },
  declineReasonsRetail: {
    Categories: ['MS02(V8)', 'MS08b', 'MS18', 'MS24', 'MS07b1', 'MS08a', 'MS07b', 'MS26', 'MS06', 'MS12a'],
    Data: [210, 103, 28, 12, 11, 8, 7, 7, 5, 5],
    Description: [
      'Except where covered by the MediShield Life (if applicable), ' +
      'your policy does not cover Pre-Existing Conditions. A Pre-Existing Condition means any illness, ' +
      'injury, condition or symptom that existed before the cover start date, ' +
      'the last reinstatement date or the date of upgrade, whichever is later. ' +
      'For more details on this, you may refer to the \'What your policy does not cover\' ' +
      'section (item b) of the policy contract.',

      'This policy does not cover pregnancy, childbirth, miscarriage, abortion or termination of pregnancy, ' +
      'or any form of related hospitalisation or treatment. However, the policy covers the following inpatient ' +
      'pregnancy complications if first diagnosed after a waiting period of 10 months: (i) Ectopic pregnancy ' +
      '(ii) Pre-eclampsia or eclampsia (iii) Disseminated intravascular coagulation (iv) Miscarriage after ' +
      '13 weeks of pregnancy which must not be due to a voluntary or malicious act This particular claim event ' +
      'is not one of the covered pregnancy complications.',

      'This policy does not provide coverage for the condition as it falls under the exclusion(s) imposed for the Life Assured.',

      'Treatment provided after the date your policy ends and within 30 days from the reinstatement date are not ' +
      'covered unless treatment was received as an inpatient for injuries caused by an accident which took place ' +
      'after the reinstatement date. This particular claim event did not meet the above conditions.',

      'The policy covers Accident Inpatient Dental Treatment: Charges to remove, restore or replace sound natural ' +
      'teeth which have been lost or damaged in an accident and treatment must be received within 31 days following ' +
      'the accident. This particular claim event did not meet the above conditions.',

      'This policy does not cover tests or treatment relating to infertility, contraception, sterilisation, impotence, ' +
      'sexual dysfunction or assisted conception tests or treatments or sex change operations.',

      'The policy covers Accident Inpatient Dental Treatment: Charges to remove, restore or replace sound natural ' +
      'teeth which have been lost or damaged in an accident and treatment must be received within 14 / 31 days ' +
      'following the accident. This particular claim event did not meet the above conditions.',

      'This policy covers associated outpatient consultation fees, examinations and laboratory tests (including MRI, ' +
      'CT scan and X-ray) under the \'Outpatient Cancer Treatment\' benefit if they are ordered by the attending doctor ' +
      'before the chemotherapy, radiotherapy, brachytherapy, immunotherapy or stereotactic radiotherapy treatment ' +
      'and take place not more than 30 days before the treatment. For this particular claim event, there is no ' +
      'chemotherapy, radiotherapy, brachytherapy, immunotherapy or stereotactic radiotherapy treatment. Thus, the ' +
      'claim is not covered under the policy.',

      'This policy does not cover hospitalisation for diagnosis, diagnostic examinations, general physical or medical check-ups.',

      'This policy does not cover treatment for self-inflicted injury or suicide.',
    ],
  },
  declineReasonsGroup: {
    Categories: ['R21', '89', '84', '75', '61', '80', '83', '87', '106', '62'],
    Data: [1064, 167, 144, 138, 87, 52, 48, 40, 32, 26],
    Description: [
      'Duplicate claim submission',
      'Exceeded benefit / capping limit.',
      'Benefit not Covered.',
      'Pre-existing illness.',
      'Pregnancy related conditions.',
      'Excluded condition.',
      'The bill is fully paid by a third party.',
      'Did not meet benefit\'s definition.',
      'Healthscreening / Medical Checkup',
      'Dental treatment.',
    ],
  },
  nercReasons: null,
  outlierFlag: {
    Categories: [
      'Unusual Length of Stay',
      'Low Occurrence of ICD Age combination',
      'Low Occurrence of ICD Gender combination',
      'Low Occurrence of ICD TOSP combination',
      'Low Occurrence of Expense Category',
      'Similar TOSP',
      'High Claim Cost',
      'High Average Room Charge',
      'Agent in Watchlist',
      'Doctor in Watchlist/Outlier',
    ],
    Data: [10.1, 9.47, 8.92, 5.07, 4.41, 3.52, 1.98, 0.22, 0.22, 0.22],
  },
  detailOutlierFlag: {
    Categories: [
      'Total outliers',
      'Anaesthetist fee is exceeding Panel fee schedule(Panel Doctor)',
      'Attendance fee over charge(Panel Doctor)',
      'Surgeon fee is exceeding Panel fee(Panel Doctor)',
      'Surgeon fee is exceeding MOH Upper Bound(Non Panel Doctor)',
      'Claim Length of stay exceed LOS benchmark(Panel Doctor)',
      'Claim Length of stay exceed LOS benchmark(Non Panel Doctor)',
      '(P75) ICD & TOSP combination missing(Panel Doctor)',
      '(P75) ICD & TOSP combination missing(Non Panel Doctor)',
      'Total bill amt exceed Internal Benchmark(Panel Doctor)',
      'Total bill amt exceed Internal Benchmark(Non Panel Doctor)',
      'Machine confidence outlier',
      'Exceed STP Threshold Limit',
      'Diagnose is not found in the STP Approve List',
      'Diagnose not fulfill STP Approve duration',
      'Diagnose is not found in the STP Decline List',
      'Diagnose not fulfill STP Decline duration',
      'Policy issue on sub-standard',
      'Claims event is after PTD and pending outstanding premium',
      'Surgery is not match with Diagnose',
      'Servicing Agent under watchlist',
      'Doctor under watchlist',
      'Policy/HRN is bar',
      'Client is bar',
      'Hospital is bar',
      'Policy owner is deceased',
      'Existing pending PMIREQ claim',
      'Data Issue. To escalate to System Team for resolution',
      'Invoice Category not found in STP setup',
      'Submission Type not found in STP setup',
      'Diagnosis related to UW exclusion',
      'Exceed benefit limit',
    ],
    Data: [100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 9.47, 10.13, 1.54, 0, 5.07, 0, 3.52, 6.61, 0, 0.22, 0.22, 0, 0, 0,
      0.22, 0, 0, 0, 0, 0.44, 0],
  },
  totalChartsDetail: {
    totalClaims: 3250,
    totalClaimsApproved: 3200,
    totalAmountApproved: 7000,
    totalClaimsRejected: 50,
    totalAmountRejected: 200,
    totalNumOutliers: 100,
    totalAmountOutliers: 275,
  },
};

export const STP_CHART = {
  previousSTPRate: 96.31,
  stpRateChart: {
    stp: 97.3,
    nonSTP: 2.699997,
  },
  previousSTPAccuracy: 95.92,
  stpAccuracyChart: {
    incorrectPredictions: 1.6,
    correctPredictions: 98.4,
  },
  totalNumberOfClaims: 1093,
  totalNumberOfClaimsWithDecisions: 910,
  numberOfSTPClaims: 749,
  numberOfMatchingIlDecision: 651,
  previousFalseDeclinedAverageDollar: '0.00',
  falseDeclinedAverageDollarValue: '5,541.61',
  previousLeakageAverageDollarValue: '0.00',
  leakageAverageDollarValue: '2,835.63',
  chart: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    series: [
      {
        name: 'False Declined',
        data: [
          47668.42,
          9150,
          2500,
          153353.94,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
      },
      {
        name: 'False Approved',
        data: [
          7128.5599999999995,
          3670,
          1787.39,
          52206.7,
          10365.72,
          0,
          14005.51,
          0,
          0,
          0,
          0,
          38293.12,
        ],
      },
    ],
  },
};

export const RETAIL_CLAIM_BENEFIT_DETAIL = {
  totalbillamt_fb: [{
    kri: 'Total Bill',
    inccured_amt: 10543.33,
    moh_25th_perc: 9950,
    moh_50th_perc: 11450,
    mid_50th_75th_perc: 12050,
    moh_75th_perc: 12650,
    aida_benchmark: 14700,
    panel_fee_schedule: null,
    preauth_charges: 10200,
    result: {
      comparator: 'preauth_charges',
      flag: true,
    },
  }],
  doctor_attendance_fb: [{
    kri: 'Attendance Fee',
    inccured_amt: 1591,
    moh_fb_lower: 1391,
    moh_fb_avg: 1498,
    mid_mohfbavg_mohfbupper: 2247,
    moh_fb_upper: 2621.5,
    aida_benchmark: 2996,
    panel_fee_schedule: 2996,
    preauth_charges: 1498,
    result: {
      comparator: 'preauth_charges',
      flag: true,
    },
  }],
  surgery_fb: [
    {
      ID: 1,
      tospdesc: 'SF823A (4A) - ABDOMINAL WALL, VENTRAL/INCISIONAL/RECURRENT HERNIA, REPAIR (MIS/OPEN)',
      surgeon_name: 'YEUNG PO MAN BALDWIN',
      anaethestist: 'Lim Zhen Wei',
      operation_date: '10/04/2022',
      benchmark: [
        {
          kri: 'Surgeon Fee',
          inccured_amt: 7490,
          moh_fb_lower: 4708,
          moh_fb_avg: 6634,
          mid_mohfbavg_mohfbupper: 7597,
          moh_fb_upper: 8560.5,
          aida_benchmark: 9330,
          panel_fee_schedule: 4708,
          preauth_charges: null,
          result: {
            comparator: 'panel_fee_schedule',
            flag: true,
          },
        },
        {
          kri: 'Anaethestist Fee',
          inccured_amt: 1872.5,
          moh_fb_lower: 1112.8,
          moh_fb_avg: 1439.15,
          mid_mohfbavg_mohfbupper: 1602.33,
          moh_fb_upper: 1765.5,
          aida_benchmark: 2332.5,
          panel_fee_schedule: 1177,
          preauth_charges: null,
          result: {
            comparator: 'panel_fee_schedule',
            flag: true,
          },
        },
      ],
    },
    {
      ID: 2,
      tospdesc: 'SL804R (3C) - Retina, Tears, Cryotherapy or Photocoagulation (laser) (Bilateral)',
      surgeon_name: 'YEUNG PO MAN BALDWIN',
      anaethestist: 'Lim Zhen Wei',
      operation_date: '10/04/2022',
      benchmark: [
        {
          kri: 'Surgeon Fee',
          inccured_amt: 3477.5,
          moh_fb_lower: 1819,
          moh_fb_avg: 2541.25,
          mid_mohfbavg_mohfbupper: 2902.38,
          moh_fb_upper: 3263.5,
          aida_benchmark: 3550,
          panel_fee_schedule: 1819,
          preauth_charges: null,
          result: {
            comparator: 'mid_mohfbavg_mohfbupper',
            flag: true,
          },
        },
        {
          kri: 'Anaethestist Fee',
          inccured_amt: 812.5,
          moh_fb_lower: null,
          moh_fb_avg: null,
          mid_mohfbavg_mohfbupper: null,
          moh_fb_upper: null,
          aida_benchmark: 980,
          panel_fee_schedule: 454.75,
          preauth_charges: null,
          result: {
            comparator: 'aida_benchmark',
            flag: false,
          },
        },
        {
          kri: 'Implants',
          inccured_amt: 2605,
          moh_25th_perc: 1325,
          moh_50th_perc: 1563,
          mid_50th_75th_perc: 1681.5,
          moh_75th_perc: 1800,
          aida_benchmark: 900,
          internal_benchmark: 1750,
          preauth_charges: null,
          result: {
            comparator: 'internal_benchmark',
            flag: true,
          },
        },
      ],
    },
  ],
};

export const CLAIM_LISTING_PREDICTIVE_SUMMARY = {
  claimListingRetail: [
    {
      mainclaimnumber: 'MC-98273423',
      glnumber: 'GL-1234567',
      epaffilled: 'No',
      reg_no: 'R-423341',
      preauthreferencenumber: null,
      hrnkey: 'CR8605240601',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Inpatient',
      totalbillamount: 12204.23,
      claimpanelind: 'NONPANEL',
      providername: 'KPJ Puteri Specialist Hospital',
      providertypedesc: 'Private Hospital',
      dischargewardclasscddesc: 'C',
      principaldoctorname: 'Chua Wei Han',
      medicalofficername: 'Tan Chun Seng',
      specialitycddesc: 'OPHTHALMOLOGY',
      submissiontypecd: 'FS',
      accidentflag: 'No',
      accidentdetails: '',
      emergencyflag: 'No',
      referralsource: null,
      outcometype: 'Patient is discharged',
      sc_receiveddt: '02/06/2022',
      receiveddt: '24/06/2022',
      submissiondt: '02/06/2022',
      admissiondt_admissiontm: '09/01/2022 10:44',
      dischargedt_dischargetm: '10/01/2022 11:18',
      causeofinjury: '',
      diagnosis1: 'H25.59, Senile cataract, unspecified',
      candp1: 'Multiple factors',
      diagnosis2: '',
      candp2: '',
      diagnosis3: '',
      candp3: '',
      non_stp_reason: 'WatchList|Exceed STP Limit',
      non_stp_reason_json: '[{"stage":"Stage 2 - Limit Utilization & Balance","detail":"Limit Exceeded"},{"stage":"Stage 4 - Medical Necessity","detail":"Unusual Length of Admission"},{"stage":"Guideline - Lumps and Bumps","detail":""},{"stage":"Guideline - Top 10 AUC","detail":""}]',
      outlierflag: 'No',
      outlierscore: null,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.91888012,
      main_policynumber: '30540577',
      main_plannm: 'Some Insurance Plan',
      premiumpaidtodt: 5612.35,
      cashvalue: 4321.12,
      graceperiod: '3 months',
      plan_duration: '40 years',
      main_planchangedt: null,
      main_reinsdt: null,
      main_reinsduration: null,
      main_policyinceptiondt: '12/07/2021',
      lastlapsedt: null,
      vitality: 'No',
      gio: null,
      soe: null,
      other_plan: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      deductible: 10923.34,
      deductiblewaive: null,
      potentialpreexisting_json: '[{"diagnosis":"H25.59, Senile cataract, unspecified"}]',
      clnt_preex_cond_json: null,
      main_udwoption: null,
      main_uwexclusion_json: '[{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and any complications thereof.","exclusionEffFromDt":"12/07/2021","exclusionEffToDt":"12/07/2078","relevantInd":""}]',
      specialistrequest_json: '[{"request":"sample"}]',
      cosharepercent: '60%',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: '12/10/2021',
      rider_1_policyduration: '39 years',
      rider_1_generalexclusion: 'No',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: 'No',
      rider_1_uwexclusion_json: '[{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and any complications thereof.","exclusionEffFromDt":"12/10/2021","exclusionEffToDt":"12/07/2078","relevantInd":""}]',
      rider_1_udwoption: 'FMU',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      patientname: 'John Doe',
      identificationno: '800208-01-5260G',
      gender: 'Male',
      age: '41 years 8 months',
      race: 'CHI',
      relationship: 'Spouse',
      ebmembername: 'Jane Doe',
      memberjoindt: '24/08/20',
      memberenddt: '23/08/25',
      specialstatus: '',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'Yes',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      doctorsimilarspecialty: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'Yes',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      claim_under_waiting_period: 'No',
      pec_identified: 'No',
      policy_diagnosis_exclusion: 'No',
      congenital_condition: 'No',
      exclusion_lump_and_bump: 'No',
      top_10_auc_procedure: 'No',
      daycare_procedure: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 124,
    }, {
      mainclaimnumber: '220006350S',
      health_plus_claim_no: '220006351S',
      preauthreferencenumber: null,
      hrnkey: 'CR8605240601',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Inpatient',
      totalbillamount: 12204.23,
      claimpanelind: 'NONPANEL',
      providername: 'Mount Elizabeth Hospital Ltd',
      providertypedesc: 'Private Hospital',
      dischargewardclasscddesc: 'C',
      principaldoctorname: 'Chua Wei Han',
      specialitycddesc: 'OPHTHALMOLOGY',
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: 'Patient is discharged',
      receiveddt: '02/06/2022',
      sc_receiveddt: '24/06/2022',
      submissiondt: '02/06/2022',
      admissiondt_admissiontm: '09/01/2022 10:44',
      dischargedt_dischargetm: '10/01/2022 11:18',
      causeofinjury: '',
      finaldiagnosis: 'H25.59, Senile cataract, unspecified                                                                                                                                                                            ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: 'WatchList|Exceed STP Limit',
      outlierflag: 'No',
      outlierscore: null,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.91888012,
      main_policynumber: '30540577',
      main_plannm: 'Shield Integrated Plan 2',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '12/07/2021',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"remark":"Diabetes mellitus, Obesity, Anaemia, Eye Disorder-since 2010","category":"Pre-existing Conditions","relevantInd":""}]',
      main_udwoption: 'FMU',
      main_uwexclusion_json: '[{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and any complications thereof.","exclusionEffFromDt":"12/07/2021","exclusionEffToDt":"12/07/2078","relevantInd":""}]',
      rider_1_plannm: 'Health Plus Plan 2 (Public Lite)',
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: '12/10/2021',
      rider_1_generalexclusion: 'No',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: 'No',
      rider_1_uwexclusion_json: '[{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and any complications thereof.","exclusionEffFromDt":"12/10/2021","exclusionEffToDt":"12/07/2078","relevantInd":""}]',
      rider_1_udwoption: 'FMU',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Male',
      age: '41 years 8 months',
      race: 'CHI',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'Yes',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 124,
    },
    {
      mainclaimnumber: '220006352S',
      health_plus_claim_no: '220006353S',
      preauthreferencenumber: null,
      hrnkey: 'CR8605240602',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Day surgery',
      totalbillamount: 4545.58,
      claimpanelind: 'MHC',
      providername: 'Mount Elizabeth Novena Hospital',
      providertypedesc: 'Private Hospital',
      dischargewardclasscddesc: null,
      principaldoctorname: 'Foo Shuo Min Jonathan',
      specialitycddesc: 'GENERAL SURGERY',
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: 'Patient is discharged',
      receiveddt: '13/03/2022',
      sc_receiveddt: '24/06/2022',
      submissiondt: '13/03/2022',
      admissiondt_admissiontm: '02/03/2022 12:26',
      dischargedt_dischargetm: '02/03/2022 17:54',
      causeofinjury: '',
      finaldiagnosis: 'K26.63, Duodenal ulcer, acute without haemorrhage or perforation                                                                                                                                                ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: null,
      outlierflag: 'No',
      outlierscore: null,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.90703855,
      main_policynumber: '26150061',
      main_plannm: 'Shield Integrated Plan 1',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '01/09/2013',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '',
      main_udwoption: 'MO',
      main_uwexclusion_json: '',
      rider_1_plannm: 'Health Plus Plan 1 (Private Cover)',
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: '01/09/2013',
      rider_1_generalexclusion: 'No',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: 'No',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: 'MO',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Male',
      age: '48 years 8 months',
      race: 'CHI',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 126,
    },
    {
      mainclaimnumber: '220006354S',
      health_plus_claim_no: '220006355S',
      preauthreferencenumber: 'PreAuth-18062201',
      hrnkey: 'CR8605240603',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Day surgery',
      totalbillamount: 9418.21,
      claimpanelind: 'MHC',
      providername: 'Mount Elizabeth Hospital Ltd',
      providertypedesc: 'Private Hospital',
      dischargewardclasscddesc: null,
      principaldoctorname: 'See Li Shuen Jovina',
      specialitycddesc: 'OPHTHALMOLOGY',
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: 'Patient is discharged',
      receiveddt: '15/03/2022',
      sc_receiveddt: '24/06/2022',
      submissiondt: '15/03/2022',
      admissiondt_admissiontm: '01/03/2022 10:41',
      dischargedt_dischargetm: '01/03/2022 14:30',
      causeofinjury: '',
      finaldiagnosis: 'H26.69, Cataract, unspecified                                                                                                                                                                                   ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: 'Stage 2 - Claim History',
      outlierflag: 'No',
      outlierscore: null,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.93633945,
      main_policynumber: '28960687',
      main_plannm: 'Shield Integrated Plan 3',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '29/05/2019',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '',
      main_udwoption: 'FMU',
      main_uwexclusion_json: '',
      rider_1_plannm: 'Health Plus Plan 3 (Public Lite)',
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: '29/05/2019',
      rider_1_generalexclusion: 'No',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: 'No',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: 'FMU',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'Yes',
      exgratiaind: 'No',
      gender: 'Female',
      age: '69 years 9 months',
      race: 'OTH',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 127,
    },
    {
      mainclaimnumber: '200587654S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: '502019759428J',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Day surgery',
      totalbillamount: 2037.28,
      claimpanelind: 'NONPANEL',
      providername: 'Singapore National Eye Centre',
      providertypedesc: 'Public Hospital',
      dischargewardclasscddesc: 'Day Surgery/Outpatient Private',
      principaldoctorname: 'Looi Lee Geok Audrey',
      specialitycddesc: 'OPHTHALMOLOGY',
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '21/07/2020',
      sc_receiveddt: '22/06/2022',
      submissiondt: '15/03/2019',
      admissiondt_admissiontm: '15/03/2019 08:00',
      dischargedt_dischargetm: '15/03/2019 20:00',
      causeofinjury: '',
      finaldiagnosis: 'H33.33, Retinal breaks without detachment                                                                                                                                                                       ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: null,
      outlierflag: 'No',
      outlierscore: 0,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.94799805,
      main_policynumber: '30113585',
      main_plannm: 'Shield Integrated Plan 1',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '14/05/2018',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"remark":"Hypertension (2022)","category":"Pre-existing Conditions","relevantInd":"No"},{"remark":"Gout, Diabetes Mellitus, Anaemia","category":"Suspected Pre-existing Condition","relevantInd":"No"},{"remark":"Osteoporosis (2000)","category":"Permanently excluded conditions (for MO)","relevantInd":"No"}]',
      main_udwoption: null,
      main_uwexclusion_json: '[{"exclusionDesc":"Any disease or disorder of the cervix and uterus including any complications thereof.","exclusionEffFromDt":"14/05/2019","relevantInd":"No"},{"exclusionDesc":"Hypertension or any disease or disorder of the cardiovascular and cerebrovascular systems including any complications thereof.","exclusionEffFromDt":"18/04/2018","relevantInd":"No"},{"exclusionDesc":"Any disease or disorder or injury to the spine, its intervertebral discs, joints, nerve roots or supporting musculature and ligaments including any neurological or other complications thereof.","exclusionEffFromDt":"14/05/2019","relevantInd":"No"},{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and anycomplications thereof.","exclusionEffFromDt":"14/05/2019","relevantInd":"No"},{"exclusionDesc":"Any disease or disorder of either eye including loss of sight and anycomplications thereof.","exclusionEffFromDt":"14/05/2019","relevantInd":"No"}]',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '59 years 10 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 35,
    },
    {
      mainclaimnumber: '210659670S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: 'H42021100085C',
      pmireq: 'Yes',
      claimlos: 3,
      billcatgdesc: 'Inpatient',
      totalbillamount: 993.6,
      claimpanelind: 'NONPANEL',
      providername: 'Bright Vision Hospital (Inpatient Rehabilitation)',
      providertypedesc: 'Community Hospital',
      dischargewardclasscddesc: 'SUB-ACUTE \\u00a0\\u2013 CLASS C',
      principaldoctorname: 'Yee Wenjun Gabriel Gerard',
      specialitycddesc: null,
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '07/05/2021',
      sc_receiveddt: '21/06/2022',
      submissiondt: '29/03/2021',
      admissiondt_admissiontm: '29/03/2021 08:00',
      dischargedt_dischargetm: '31/03/2021 20:00',
      causeofinjury: '',
      finaldiagnosis: 'B97.72, Coronavirus as the cause of diseases classified to other chapters                                                                                                                                       ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: null,
      outlierflag: 'No',
      outlierscore: 0,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.90740856,
      main_policynumber: '23707989',
      main_plannm: 'Shield Integrated Plan 2',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '01/11/2009',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"category":"Suspected Pre-existing Conditions","relevantInd":"No"},{"category":"grp-claim","relevantInd":"No"},{"category":"grp-member","relevantInd":"No"}]',
      main_udwoption: null,
      main_uwexclusion_json: '[]',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '43 years 9 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 51,
    },
    {
      mainclaimnumber: '200015374S',
      health_plus_claim_no: '',
      preauthreferencenumber: 'PreAuth-20003632',
      hrnkey: '61201600SAMP2',
      pmireq: 'Yes',
      claimlos: 2,
      billcatgdesc: 'Inpatient',
      totalbillamount: 4500,
      claimpanelind: 'MHC',
      providername: 'Mount Alvernia Hospital Ltd',
      providertypedesc: 'Private Hospital',
      dischargewardclasscddesc: 'NORMAL ROOM & BOARD - PRIVATE',
      principaldoctorname: 'Cheng Ching Li',
      specialitycddesc: 'OBSTETRICS',
      submissiontypecd: 'FS',
      referralsource: 'Self',
      outcometype: 'Patient is discharged',
      receiveddt: '30/07/2020',
      sc_receiveddt: '21/06/2022',
      submissiondt: '30/07/2020',
      admissiondt_admissiontm: '23/07/2020 10:40',
      dischargedt_dischargetm: '24/07/2020 11:32',
      causeofinjury: '',
      finaldiagnosis: 'K35.50, Acute appendicitis with generalised peritonitis                                                                                                                                                         ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: 'Stage 3 - Medical Necessity|Stage 4 - Over Charging / Over Servicing|WatchList',
      outlierflag: 'Yes',
      outlierscore: 0,
      outlier_reasons: 'The average per day room charge ($800.00) is higher than the standard rate ($569.00).',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.90740856,
      main_policynumber: '21863915',
      main_plannm: 'MyShield Integrated Plan 2',
      main_planchangedt: null,
      main_reinsdt: '01/07/2014',
      main_policyinceptiondt: '01/07/2008',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"remark":"Pre-existing condition on current plan","category":"Pre-existing Conditions","relevantInd":"No"}]',
      main_udwoption: 'FMU',
      main_uwexclusion_json: '[{"exclusionDesc":"Blood clotting or coagulation disorders or any disease or disorder of the blood including any complications thereof.","exclusionEffFromDt":"01/07/2014","exclusionEffToDt":"10/11/2020","relevantInd":"No"},{"exclusionDesc":"Blood clotting or coagulation disorders or any disease or disorder of the blood including any complications thereof.","exclusionEffFromDt":"01/01/2014","exclusionEffToDt":"10/11/2020","relevantInd":"No"},{"exclusionDesc":"Blood clotting or coagulation disorders or any disease or disorder of the blood including any complications thereof.","exclusionEffFromDt":"01/07/2011","exclusionEffToDt":"10/11/2022","relevantInd":"No"}]',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Male',
      age: '58 years 10 months',
      race: 'CHI',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'Yes',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'Yes',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'Yes',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 33,
    },
    {
      mainclaimnumber: '200574091S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: '672020312955A',
      pmireq: 'Yes',
      claimlos: 58.5,
      billcatgdesc: 'Inpatient',
      totalbillamount: 31666.54,
      claimpanelind: 'NONPANEL',
      providername: 'Singapore General Hospital',
      providertypedesc: 'Public Hospital',
      dischargewardclasscddesc: 'Normal Room & Board \\u2013 C ',
      principaldoctorname: 'Huang Miao\'en Deborah',
      specialitycddesc: null,
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '11/05/2020',
      sc_receiveddt: '21/06/2022',
      submissiondt: '22/02/2020',
      admissiondt_admissiontm: '22/02/2020 14:36',
      dischargedt_dischargetm: '20/04/2020 14:36',
      causeofinjury: '',
      finaldiagnosis: 'M86.616, Other acute osteomyelitis, lower leg                                                                                                                                                                    ',
      otherdiagnosis1: 'M00.026, Other streptococcal arthritis and polyarthritis, lower leg                                                                                                                                              ',
      otherdiagnosis2: 'T81.13, Disruption of operation wound, not elsewhere classified                                                                                                                                                 ',
      non_stp_reason: 'Stage 3 - Medical Necessity|Stage 4 - Over Charging / Over Servicing|Exceed STP Limit',
      outlierflag: 'Yes',
      outlierscore: 0,
      outlier_reasons: 'The occurrence rate of TOSP Code SA855S and ICD Code M8616 is low (2.74%).|The occurrence rate of TOSP Code SA854S and ICD Code M8616 is low (2.74%).|',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.94217492,
      main_policynumber: '30260997',
      main_plannm: 'Shield Integrated Plan 2',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '01/04/2019',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"category":"Suspected Pre-existing Conditions","relevantInd":"No"},{"category":"grp-claim","relevantInd":"No"},{"category":"grp-member","relevantInd":"No"}]',
      main_udwoption: null,
      main_uwexclusion_json: '[]',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '60 years 0 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'Yes',
      unusuallos: 'No',
      multisimilarsurgeries: 'Yes',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 50,
    },
    {
      mainclaimnumber: '190535232S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: '692019036800H',
      pmireq: 'Yes',
      claimlos: 123,
      billcatgdesc: 'Inpatient',
      totalbillamount: 54967.11,
      claimpanelind: 'NONPANEL',
      providername: 'Changi General Hospital Pte Ltd',
      providertypedesc: 'Public Hospital',
      dischargewardclasscddesc: 'G:H',
      principaldoctorname: 'Lim Kok Ren',
      specialitycddesc: null,
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '12/11/2019',
      sc_receiveddt: '20/06/2022',
      submissiondt: '30/05/2019',
      admissiondt_admissiontm: '30/05/2019 14:36',
      dischargedt_dischargetm: '30/09/2019 14:36',
      causeofinjury: '',
      finaldiagnosis: 'C19, Malignant neoplasm of rectosigmoid junction                                                                                                                                                             ',
      otherdiagnosis1: 'C77.72, Secondary and unspecified malignant neoplasm of intra-abdominal lymph nodes                                                                                                                             ',
      otherdiagnosis2: 'C50.09, Malignant neoplasm of breast, unspecified part                                                                                                                                                          ',
      non_stp_reason: 'Exceed STP Limit',
      outlierflag: 'No',
      outlierscore: 0,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.91074511,
      main_policynumber: '23344844',
      main_plannm: null,
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"category":"Suspected Pre-existing Conditions","relevantInd":"No"},{"category":"grp-claim","relevantInd":"No"},{"category":"grp-member","relevantInd":"No"}]',
      main_udwoption: null,
      main_uwexclusion_json: '',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '62 years 3 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 9,
    },
    {
      mainclaimnumber: '190530232S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: 'NQ2019114426A',
      pmireq: 'Yes',
      claimlos: null,
      billcatgdesc: 'Outpatient',
      totalbillamount: 2198.85,
      claimpanelind: 'NONPANEL',
      providername: 'Parkway Cancer Centre @ GH (Level 1)',
      providertypedesc: 'Private Clinic',
      dischargewardclasscddesc: 'A',
      principaldoctorname: 'Khoo Kei Siong',
      specialitycddesc: null,
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '22/10/2019',
      sc_receiveddt: '20/06/2022',
      submissiondt: '21/10/2019',
      admissiondt_admissiontm: '21/10/2019 14:36',
      dischargedt_dischargetm: '21/10/2019 14:36',
      causeofinjury: '',
      finaldiagnosis: 'C50.09, Malignant neoplasm of breast, unspecified part                                                                                                                                                          ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      non_stp_reason: null,
      outlierflag: 'No',
      outlierscore: 0,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.9363,
      main_policynumber: '21409666',
      main_plannm: 'Shield Integrated Plan 1',
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: '01/01/2006',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '[{"category":"Suspected Pre-existing Conditions","relevantInd":"No"},{"category":"grp-claim","relevantInd":"No"},{"category":"grp-member","relevantInd":"No"}]',
      main_udwoption: null,
      main_uwexclusion_json: '[{"relevantInd":"No"}]',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '51 years 0 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 8,
    },
    {
      mainclaimnumber: '210716345S',
      health_plus_claim_no: '',
      preauthreferencenumber: null,
      hrnkey: '782021A01K54Z',
      pmireq: 'Yes',
      claimlos: 1,
      billcatgdesc: 'Day surgery',
      totalbillamount: 1149.47,
      claimpanelind: 'NONPANEL',
      providername: 'National University Hospital (S) Pte Ltd',
      providertypedesc: 'Public Hospital',
      dischargewardclasscddesc: 'B',
      principaldoctorname: 'Ng Jun Jie',
      specialitycddesc: null,
      submissiontypecd: 'FS',
      referralsource: null,
      outcometype: null,
      receiveddt: '22/12/2021',
      sc_receiveddt: '17/06/2022',
      submissiondt: '03/08/2021',
      admissiondt_admissiontm: '03/08/2021 14:36',
      dischargedt_dischargetm: '03/08/2021 14:36',
      causeofinjury: 'Y84.41, Kidney dialysis as the cause of abnormal reaction of the patient, or of later complication, without mention of misadventure at the time of the procedure                                                ',
      finaldiagnosis: 'T82.28, Other complications of cardiac and vascular prosthetic devices, implants and grafts                                                                                                                     ',
      otherdiagnosis1: 'I87.71, Compression of vein                                                                                                                                                                                     ',
      otherdiagnosis2: '',
      non_stp_reason: null,
      outlierflag: 'No',
      outlierscore: 0,
      outlier_reasons: '',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.9480,
      main_policynumber: '21356688',
      main_plannm: null,
      main_planchangedt: null,
      main_reinsdt: null,
      main_policyinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_potentialpreexisting: 'No',
      clnt_preex_cond_json: '',
      main_udwoption: null,
      main_uwexclusion_json: '',
      rider_1_plannm: null,
      rider_1_planchangedt: null,
      rider_1_reinsdt: null,
      rider_1_policyinceptiondt: null,
      rider_1_generalexclusion: '',
      rider_1_generalexclusionreason: '',
      rider_1_potentialpreexisting: '',
      rider_1_uwexclusion_json: '',
      rider_1_udwoption: '',
      rider_2_plannm: null,
      rider_2_planchangedt: null,
      rider_2_reinsdt: null,
      rider_2_policyinceptiondt: null,
      rider_2_generalexclusion: '',
      rider_2_generalexclusionreason: '',
      rider_2_potentialpreexisting: '',
      rider_2_uwexclusion_json: '',
      rider_2_udwoption: '',
      prior_pendingclaim: 'No',
      exgratiaind: 'No',
      gender: 'Female',
      age: '55 years 6 months',
      race: null,
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      overall_generalexclusion_flag: 'No',
      overall_uwexclusion_flag: 'No',
      orchid: 2,
    },
  ],
  claimListingGroup: [
    {
      mainclaimnumber: '190002084-90',
      clientno: '5146696',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Outpatient',
      billcatg: 'OU',
      totalbillamount: 291.58,
      claimpanelind: 'IHP',
      providername: 'Eagle Eye Centre @ Parkway East',
      providertypedesc: 'Specialist',
      wardtypecddesc: 'Single Bedded (Pte)',
      submissiondt: '08/01/2019',
      admissiondt: '08/01/2019',
      dischargedt: '08/01/2019',
      receiveddt: '14/01/2019',
      sc_receiveddt: '23/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'S05, Injury of eye and orbit                                                                                                                                                                                 ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'BMFFR',
      main_policynumber: '3038246',
      main_plannm: null,
      main_launchref: '3',
      main_policyinceptiondt: null,
      memberhiredt: null,
      memberinceptiondt: '01/07/2016',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.90586071,
      non_stp_reason: null,
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'Yes',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '52',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 41,
    },
    {
      mainclaimnumber: '220008797-00',
      clientno: '1755450',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'IP',
      totalbillamount: 34165.37,
      claimpanelind: 'MHC',
      providername: 'Mount Elizabeth Hospital Ltd',
      providertypedesc: 'Private Hospital',
      wardtypecddesc: 'Single Bedded (Pte)',
      submissiondt: null,
      admissiondt: '09/02/2022',
      dischargedt: '09/02/2022',
      receiveddt: '09/06/2022',
      sc_receiveddt: '23/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'K35.51, Acute appendicitis with peritoneal abscess                                                                                                                                                              ',
      otherdiagnosis1: 'K35.59, Acute appendicitis, unspecified                                                                                                                                                                         ',
      otherdiagnosis2: 'K35.50, Acute appendicitis with generalised peritonitis                                                                                                                                                         ',
      contracttype: 'BHS',
      preexchkdt: '01/01/2022',
      productcd: 'BMRDR',
      main_policynumber: '3056948',
      main_plannm: 'RNB: 01 BED',
      main_launchref: '1',
      main_policyinceptiondt: '01/01/2022',
      memberhiredt: '01/01/2022',
      memberinceptiondt: '01/01/2022',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '[]',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.90656603,
      non_stp_reason: 'Stage 2 - Claim History|Stage 3 - Medical Necessity|Stage 4 - Over Charging / Over Servicing|Exceed STP Limit',
      outlier_reasons: 'The occurrence rate of TOSP Code SF417A and ICD Code K35.1 is low (0.57%).|The average per day room charge ($3401.85) is higher than the standard rate ($768.00).',
      outlierflag: 'Yes',
      prior_pendingclaim: 'Yes',
      prior_claims_for_similar_tosp_icd: 'Yes',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '52',
      race: 'Chinese',
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'Yes',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'Yes',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 401,
    },
    {
      mainclaimnumber: '220008790-00',
      clientno: '107925',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'IP',
      totalbillamount: 35965.37,
      claimpanelind: 'NONPANEL',
      providername: 'Mount Elizabeth Hospital Ltd',
      providertypedesc: 'Private Hospital',
      wardtypecddesc: 'Single Bedded (Pte)',
      submissiondt: null,
      admissiondt: '01/04/2011',
      dischargedt: '01/04/2011',
      receiveddt: '10/05/2011',
      sc_receiveddt: '23/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'K35.51, Acute appendicitis with peritoneal abscess                                                                                                                                                              ',
      otherdiagnosis1: 'K35.59, Acute appendicitis, unspecified                                                                                                                                                                         ',
      otherdiagnosis2: '',
      contracttype: 'BHS',
      preexchkdt: null,
      productcd: 'BHSXL',
      main_policynumber: '2012661',
      main_plannm: '01 BED:ALL EES + ELIG DEPS',
      main_launchref: '3',
      main_policyinceptiondt: '01/01/2011',
      memberhiredt: '01/01/2011',
      memberinceptiondt: '01/01/2011',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '[]',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.90963348,
      non_stp_reason: 'Stage 2 - Claim History|Stage 3 - Medical Necessity|Stage 4 - Over Charging / Over Servicing|Exceed STP Limit',
      outlier_reasons: 'The occurrence rate of TOSP Code SF417A and ICD Code K35.1 is low (0.60%).|The average per day room charge ($3401.85) is higher than the standard rate ($768.00).',
      outlierflag: 'Yes',
      prior_pendingclaim: 'Yes',
      prior_claims_for_similar_tosp_icd: 'Yes',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '53',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'Yes',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'Yes',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 400,
    },
    {
      mainclaimnumber: '220053248-03',
      clientno: '610306',
      claimlos: 2,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'IN',
      totalbillamount: 24985.95,
      claimpanelind: 'NONPANEL',
      providername: 'Mount Elizabeth Novena Hospital',
      providertypedesc: 'Private Hospital',
      wardtypecddesc: 'Single Bedded(Pte)',
      submissiondt: null,
      admissiondt: '19/11/2021',
      dischargedt: '20/11/2021',
      receiveddt: '25/11/2021',
      sc_receiveddt: '22/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'D48.80, Neoplasm of uncertain or unknown behaviour of bone and articular cartilage                                                                                                                              ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'MMSTD',
      main_policynumber: '2045795',
      main_plannm: 'RNB: 01 BED PTE - ALL EMPLOYEES & THEIR ELIG DEPS BASED IN SINGAPORE',
      main_launchref: '13',
      main_policyinceptiondt: '01/01/2009',
      memberhiredt: null,
      memberinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '[{"relevantInd":"No"}]',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.92006393,
      non_stp_reason: 'Exceed STP Limit',
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Female',
      age: null,
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 1,
    },
    {
      mainclaimnumber: '200689600-00',
      clientno: '7223753',
      claimlos: 11,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'IN',
      totalbillamount: 4836.45,
      claimpanelind: 'MHC',
      providername: 'Khoo Teck Puat Hospital',
      providertypedesc: 'Restructured / Government Hospital',
      wardtypecddesc: null,
      submissiondt: '13/03/2020',
      admissiondt: '13/03/2020',
      dischargedt: '24/03/2020',
      receiveddt: '29/11/2020',
      sc_receiveddt: '20/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'J34.41, Cyst and mucocele of nose and nasal sinus                                                                                                                                                               ',
      otherdiagnosis1: 'L03.32, Cellulitis of face                                                                                                                                                                                      ',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'MMSTD',
      main_policynumber: '3035231',
      main_plannm: null,
      main_launchref: '5',
      main_policyinceptiondt: null,
      memberhiredt: null,
      memberinceptiondt: '01/01/2016',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.85,
      non_stp_reason: 'Low Machine Confidence',
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'Yes',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '28',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 9,
    },
    {
      mainclaimnumber: '210338887-00',
      clientno: '125248',
      claimlos: 68,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'IN',
      totalbillamount: 28989.98,
      claimpanelind: 'NONPANEL',
      providername: 'Khoo Teck Puat Hospital',
      providertypedesc: 'Restructured / Government Hospital',
      wardtypecddesc: 'Single Bedded (Pte)',
      submissiondt: '24/10/2019',
      admissiondt: '24/10/2019',
      dischargedt: '31/12/2019',
      receiveddt: '29/10/2019',
      sc_receiveddt: '20/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'I49.90, Ventricular fibrillation and flutter                                                                                                                                                                    ',
      otherdiagnosis1: 'G93.31, Anoxic brain damage, not elsewhere classified                                                                                                                                                           ',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'BHDFR',
      main_policynumber: '3001408',
      main_plannm: null,
      main_launchref: '11',
      main_policyinceptiondt: null,
      memberhiredt: null,
      memberinceptiondt: '01/01/2010',
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.8644,
      non_stp_reason: 'Exceed STP Limit',
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '59',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 10,
    },
    {
      mainclaimnumber: '210486361-00',
      clientno: '1745354',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'Inpa',
      totalbillamount: 179.96,
      claimpanelind: 'MHC',
      providername: 'National University Hospital',
      providertypedesc: 'Restructured / Government Hospital',
      wardtypecddesc: null,
      submissiondt: '25/05/2021',
      admissiondt: '25/05/2021',
      dischargedt: '25/05/2021',
      receiveddt: '13/07/2021',
      sc_receiveddt: '19/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'C50, Malignant neoplasm of breast                                                                                                                                                                            ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'MCFFR',
      main_policynumber: '2027004',
      main_plannm: null,
      main_launchref: '12',
      main_policyinceptiondt: '01/07/2004',
      memberhiredt: null,
      memberinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.9312,
      non_stp_reason: null,
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'Yes',
      within_ward_entitlement: null,
      gender: 'Female',
      age: '32',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 2,
    },
    {
      mainclaimnumber: '210667028-00',
      clientno: '100288',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'Inpa',
      totalbillamount: 327.69,
      claimpanelind: 'MHC',
      providername: 'Fullerton Healthcare Group(Reimbursement-Nonpanel)',
      providertypedesc: 'Others',
      wardtypecddesc: null,
      submissiondt: '14/10/2021',
      admissiondt: '14/10/2021',
      dischargedt: '14/10/2021',
      sc_receiveddt: '14/11/2021',
      receiveddt: '19/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'R31, Unspecified haematuria                                                                                                                                                                                  ',
      otherdiagnosis1: '',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'MMSTD',
      main_policynumber: '3052138',
      main_plannm: null,
      main_launchref: '3',
      main_policyinceptiondt: '01/01/2019',
      memberhiredt: null,
      memberinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'APPROVE',
      machineconfidence: 0.9218,
      non_stp_reason: null,
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'Yes',
      within_ward_entitlement: null,
      gender: 'Female',
      age: '66',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 3,
    },
    {
      mainclaimnumber: '210636804-00',
      clientno: '8975517',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'Inpa',
      totalbillamount: 32579.26,
      claimpanelind: 'MHC',
      providername: 'Mount Elizabeth Hospital Ltd',
      providertypedesc: 'Private Hospital',
      wardtypecddesc: 'Single Bedded (Pte)',
      submissiondt: '08/11/2021',
      admissiondt: '08/11/2021',
      dischargedt: '08/11/2021',
      sc_receiveddt: '23/11/2021',
      receiveddt: '19/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'J30.04, Allergic rhinitis, unspecified                                                                                                                                                                          ',
      otherdiagnosis1: 'J35.50, Chronic tonsillitis                                                                                                                                                                                     ',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'BHDFR',
      main_policynumber: '3037075',
      main_plannm: null,
      main_launchref: '5',
      main_policyinceptiondt: '07/05/2016',
      memberhiredt: null,
      memberinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.8455,
      non_stp_reason: 'Exceed STP Limit',
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '40',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 4,
    },
    {
      mainclaimnumber: '220138951-00',
      clientno: '9001184',
      claimlos: 1,
      billno: null,
      billcatgdesc: 'Inpatient',
      billcatg: 'Inpa',
      totalbillamount: 29967.5,
      claimpanelind: 'NONPANEL',
      providername: 'L20 Day Surgery Centre',
      providertypedesc: 'Clinic',
      wardtypecddesc: null,
      submissiondt: '20/11/2021',
      admissiondt: '20/11/2021',
      dischargedt: '20/11/2021',
      sc_receiveddt: '22/03/2022',
      receiveddt: '19/06/2022',
      causeofinjury: '',
      finaldiagnosis: 'J30.04, Allergic rhinitis, unspecified                                                                                                                                                                          ',
      otherdiagnosis1: 'J34.42, Deviated nasal septum                                                                                                                                                                                   ',
      otherdiagnosis2: '',
      contracttype: null,
      preexchkdt: null,
      productcd: 'BHRDR',
      main_policynumber: '3046487',
      main_plannm: null,
      main_launchref: '4',
      main_policyinceptiondt: '01/01/2018',
      memberhiredt: null,
      memberinceptiondt: null,
      main_generalexclusion: 'No',
      main_generalexclusionreason: '',
      main_uwexclusion_flag: 'No',
      main_uwexclusion: '',
      main_special_condition_flag: 'No',
      main_special_condition_info: '',
      main_capping_exclusion_flag: 'No',
      main_nondisclosure: 'No',
      main_takeover: 'No',
      aidecision: 'APPROVE',
      finaldecision: 'REVIEW',
      machineconfidence: 0.8445,
      non_stp_reason: 'Exceed STP Limit',
      outlier_reasons: '',
      outlierflag: 'No',
      prior_pendingclaim: 'No',
      prior_claims_for_similar_tosp_icd: 'No',
      within_approval_limit: 'No',
      within_ward_entitlement: null,
      gender: 'Male',
      age: '14',
      race: null,
      ictype: 'NRIC',
      lowoccexpensecat: 'No',
      lowoccicdage: 'No',
      lowoccicdgender: 'No',
      lowoccicdtosp: 'No',
      unusuallos: 'No',
      multisimilarsurgeries: 'No',
      highavgdailyrnb: 'No',
      highclaimcost: 'No',
      highexpensecatamt: 'No',
      high_frequency_readmissions: 'No',
      interval_of_readmissions_flag: 'No',
      agent_in_watchlist: 'No',
      client_in_watchlist: 'No',
      doctor_in_watchlist: 'No',
      provider_in_watchlist: 'No',
      policy_in_watchlist: 'No',
      icd_for_review_list_approve: 'No',
      icd_for_review_list_decline: 'No',
      orchid: 5,
    },
  ],
};

export const CLAIM_DETAIL_PREEX_TABLE = [
  {
    diagnosis: 'K35',
    earlyClaimThresholdMonth: null,
    earlyClaimIndicator: null,
  },
  {
    diagnosis: 'H26',
    earlyClaimThresholdMonth: 24,
    earlyClaimIndicator: 'Yes',
  },
];
