import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {MdPersonAdd} from 'react-icons/md';
import {AILoading, Button, BUTTON_TYPE, Card, Dropdown, Pagination, toast, useAuthentication} from 'aida-components';

import useURL from 'hooks/useURL';
import Table, {Cell} from 'components/table';
import AddUser from './add-user.jsx';
import User from './user.jsx';
import useRequest from 'hooks/useRequest';
import {getEditPermission, VIEWS} from 'helper/routers';
import styles from './management.module.scss';

const ITEMPERPAGE = 50;

const UserManagement = () => {
  const API_URL = useURL();
  const [isNewUserOpen, setIsNewUserOpen] = useState(false);
  const [page, setPage] = useState(0);
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(authUserInfo.role, VIEWS.USER_MANAGEMENT.key);

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] = useRequest(API_URL.USERS_LIST_URL, {
    config: {
      params: {
        page_no: page + 1,
        item_per_page: ITEMPERPAGE,
      },
    },
  });

  useEffect(() => {
    makeRequest();
  }, [page]);

  useEffect(() => {
    if (status === ERROR && response.data) {
      toast.error(response.data.error);
    }
  }, [status]);

  const handleAddUserSuccess = () => {
    setIsNewUserOpen(false);
    makeRequest();
  };
  const handelCancel = () => setIsNewUserOpen(false);

  return (
    <Container fluid>
      <Row align='center'>
        <Col md={10} offset={{md: 1}}>
          <h2 className={styles.title}>User Management</h2>
          {/* {*/}
          {/*  canEdit && <div className={styles.btnWrapper}>*/}
          {/*    <Dropdown*/}
          {/*      isOpen={isNewUserOpen}*/}
          {/*      onTrigger={setIsNewUserOpen}*/}
          {/*      trigger={*/}
          {/*        <Button*/}
          {/*          type={BUTTON_TYPE.LINK}*/}
          {/*          text='Add a user'*/}
          {/*          iconPosition='left'*/}
          {/*          icon={<MdPersonAdd size={25} />}*/}
          {/*        />*/}
          {/*      }*/}
          {/*      dropdown={<AddUser handleSuccess={handleAddUserSuccess} handelCancel={handelCancel} />}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/* }*/}

          <Card>
            <Table
              head={
                <tr>
                  <Cell>Email</Cell>
                  <Cell>Role</Cell>
                  <Cell>Created by</Cell>
                  <Cell align='center'>Status</Cell>
                  {/* {*/}
                  {/*  canEdit && <Cell align='right'>Action</Cell>*/}
                  {/* }*/}
                </tr>
              }
            >
              {status === FETCHING && (
                <tr>
                  <Cell align='center' colSpan={canEdit ? 5 : 4}>
                    <AILoading />
                  </Cell>
                </tr>
              )}
              {status === SUCCESS && response.data && (
                <Fragment>
                  {response.data.list.length > 0 &&
                    response.data.list.map((row, index) => (
                      <User key={`${row.lanId}-${index}`} user={row} handleSuccess={makeRequest} canEdit={canEdit} />
                    ))}
                  {response.data.list.length < 1 && (
                    <tr>
                      <Cell align='center' colSpan={canEdit ? 5 : 4}>
                          No results found
                      </Cell>
                    </tr>
                  )}
                </Fragment>
              )}
            </Table>
            {response && response.data && response.data.total > 0 && (
              <Pagination total={response.data.total} currentPage={page} itemPerPage={ITEMPERPAGE} onChange={setPage} />
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserManagement;
