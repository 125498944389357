import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import {AILoading} from 'aida-components';
import Empty from 'components/empty';

const colors = ['#00B8D9', '#36B37E', '#FF8B00'];
const titles = {
  modelStatistics: {
    title: 'Model Statistics / Accuracy',
    x: 'Machine Confidence',
    y: 'Number of Records',
    chartName: 'Accuracy',
  },
  declineReasons: {
    title: 'Top 10 Reject Reasons Code',
    x: 'Reject Reasons Code',
    y: 'Number of Records',
    chartName: 'Top10Decline',
  },
  outlierFlag: {
    title: 'Top 10 Outlier Flag',
    x: 'Outlier Flag',
    y: 'Percentage of Records (%)',
    chartName: 'Top10Outlier',
  },
  detailOutlierFlag: {
    title: 'Outlier Flag',
    x: 'Outlier Flag',
    y: 'Percentage of Records (%)',
    chartName: 'DetailOutlier',
  },
  nercReasons: {
    title: 'Top 10 NERC Reasons Code',
    x: 'NERC Reasons Code',
    y: 'Percentage of Records (%)',
    chartName: 'Top10Nerc',
  },
};

const Column = ({data, isLoading, title, filters}) => {
  // const API_URL = useURL();
  const {aiDecisions, outlierFlag, doctorFlag, ...restOfFilters} = filters;
  const params = _.pickBy(
    {
      ...restOfFilters,
      model1Decisions: aiDecisions && aiDecisions.join(','),
      outlierFlag: outlierFlag && outlierFlag.join(','),
      doctorFlag: doctorFlag && doctorFlag.join(','),
      chartName: titles[title].chartName,
    },
    _.identity,
  );

  // const [{status, response}, makeRequest, {SUCCESS, ERROR}] = useRequest(
  //   API_URL.PREDICTIVE_MODEL_SUMMARY_CHART_DOWNLOAD_URL,
  //   {
  //     config: {
  //       params,
  //     },
  //   },
  // );

  // useEffect(() => {
  //   const time = moment().format('YYYYMMDD_HHmmss');
  //   if (status === SUCCESS && response) {
  //     fileDownload(response, `${titles[title].title}-${time}.csv`);
  //   } else if (status === ERROR && response.header) {
  //     toast.error(response.header.message);
  //   }
  // }, [response, status]);

  const getChartOpts = () => {
    const {Categories, Description, ...rest} = data;

    const seriesWithColor = Object.keys(rest).map((key, i) => ({
      name: key,
      data: rest[key],
      color: colors[i],
      dataLabels: {
        enabled: true,
        format:
          title === 'outlierFlag' ||
          title === 'detailOutlierFlag' ||
          title === 'nercReasons' ?
            '{y}%' :
            '{y}',
      },
    }));

    return {
      chart: {
        type: 'column',
      },
      title: {
        text: titles[title].title,
      },
      xAxis: {
        categories: Categories,
        crosshair: true,
        title: {
          text: titles[title].x,
        },
      },
      yAxis: {
        title: {
          text: titles[title].y,
        },
        labels: {
          format:
            title === 'outlierFlag' ||
            title === 'detailOutlierFlag' ||
            title === 'nercReasons' ?
              '{value} %' :
              '{value}',
        },
      },
      plotOptions: {
        column: {
          point: {
            events: {
              click: function() {
                params.label = this.category
                  .replace(/\%|\/|\&/gi, '') //eslint-disable-line
                  .replace(/ +(?= )/g, '');
                if (title === 'modelStatistics') {
                  params.accuracyType =
                    this.series.name === 'Total' ?
                      1 :
                      this.series.name === 'Match' ?
                        2 :
                        this.series.name === 'Mismatch' ?
                          3 :
                          4;
                }
                // makeRequest();
              },
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function() {
          let result = '<b>' + this.points[0].x + '</b><br/>';
          this.points.map((point) => {
            if (Description && Description.length) {
              result += `<b style="color:${point.color}">${
                point.series.name
              }</b>: ${point.y} (${
                Description[Categories.indexOf(point.x)]
              })<br/>`;
            } else {
              if (
                title === 'outlierFlag' ||
                title === 'detailOutlierFlag' ||
                title === 'nercReasons'
              ) {
                result += `<b style="color:${point.color}">${point.series.name}</b>: ${point.y}% <br/>`;
              } else {
                result += `<b style="color:${point.color}">${point.series.name}</b>: ${point.y}<br/>`;
              }
            }
          });
          return result;
        },
        shared: true,
        useHTML: true,
      },
      series: seriesWithColor,
    };
  };

  if (isLoading) {
    return <AILoading />;
  } else if (_.isEmpty(data)) {
    return <Empty text='No chart data found.' />;
  }

  return <HighchartsReact options={getChartOpts()} highcharts={Highcharts} />;
};

export default Column;
