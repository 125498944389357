import {Grid, GridItem} from 'aida-components';
import {Fragment, useRef, useState} from 'react';
import StpSettings from 'views/stp-rate-calculator/stp-settings';
import StpResults from 'views/stp-rate-calculator/stp-results';

const StpRateCalculator = () => {
  const downloadView = useRef();
  const [filters, setFilters] = useState({});
  const onFilterChange = (filters) => setFilters(filters);

  return (
    <Fragment>
      <div ref={downloadView}>
        <Grid gridArea={['c1 c1 c1 c1 c2 c2 c2 c2 c2 c2 c2 c2']}>
          <GridItem
            name='c1'
            component={
              <div>
                <StpSettings onFilterChange={onFilterChange}/>
              </div>
            }
          />
          <GridItem
            name='c2'
            component={
              <div>
                <StpResults filters={filters}/>
              </div>
            }
          />
        </Grid>
      </div>
    </Fragment>
  );
};

export default StpRateCalculator;
