import {jss} from 'react-jss';
import BebasNeueTTF from './BebasNeue-Regular.ttf';
import RobotoTFF from './Roboto-Regular.ttf';
import RobotoBoldTTF from './Roboto-Bold.ttf';
import RobotoLightTTF from './Roboto-Light.ttf';
import RobotoMediumTTF from './Roboto-Medium.ttf';
import RobotoCondensedTTF from './RobotoCondensed-Regular.ttf';
import RobotoCondensedBoldTTF from './RobotoCondensed-Bold.ttf';
import RobotoCondensedLightTTF from './RobotoCondensed-Light.ttf';

jss.createStyleSheet({
  '@global': {
    '@font-face': [
      {
        fontFamily: 'BebasNeue',
        src:
          `url(${BebasNeueTTF}) format("truetype")`,
        fontWeight: '400',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'Roboto',
        src:
          `url(${RobotoTFF}) format("truetype")`,
        fontWeight: '400',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'Roboto',
        src:
          `url(${RobotoMediumTTF}) format("truetype")`,
        fontWeight: '500',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'Roboto',
        src:
          `url(${RobotoBoldTTF}) format("truetype")`,
        fontWeight: '700',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'Roboto',
        src:
          `url(${RobotoLightTTF}) format("truetype")`,
        fontWeight: '300',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'RobotoCondensed',
        src:
          `url(${RobotoCondensedTTF}) format("truetype")`,
        fontWeight: '400',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'RobotoCondensed',
        src:
          `url(${RobotoCondensedBoldTTF}) format("truetype")`,
        fontWeight: '700',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      }, {
        fontFamily: 'RobotoCondensed',
        src:
          `url(${RobotoCondensedLightTTF}) format("truetype")`,
        fontWeight: '300',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      },
    ],
  },
}).attach();
