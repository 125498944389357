import {useState, useEffect} from 'react';
import _ from 'lodash';

import {
  Button,
  BUTTON_TYPE,
  Card,
  Checkbox,
  CheckboxGroup,
  createUseStyles,
  DateRangePicker,
  Input,
  INPUT_TYPE,
  useTheme,
  toast,
  useRequest,
} from 'aida-components';

import TwoColumns from 'components/2columns';
import ThreeColumns from 'components/3columns';

import styles from './predictive-model-summary.module.scss';
import SelectInput, {SELECT_OPTIONS_TYPE} from 'components/select-input';
import {useAppStyles} from 'helper/app-styles';
import {UserTypeConfig} from 'helper/user-type-config';
import useURL from 'hooks/useURL';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  inpLi: {
    '& >div': {
      marginTop: 10,
    },
  },
  inpLabel: ({theme}) => ({
    ...theme.typography.body,
  }),
  item6: {
    display: 'flex !important',
    alignItems: 'center !important',
    verticalAlign: 'middle',
    '&>div': {
      paddingBottom: 0,
    },
  },
});
const Filter = ({filters, onFilterChange, onCancel}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const API_URLs = useURL();

  const [fromDate, setFromDate] = useState(filters.from_date);
  const [toDate, setToDate] = useState(filters.to_date);
  const [invoiceNumber, setInvoiceNumber] = useState(filters.policy_number);
  const [lineItemDesc, setLineItemDesc] = useState(filters.line_item_desc);
  const [providerName, setProviderName] = useState(filters.provider_name);
  const [indicatorValue, setIndicatorValue] = useState(filters.indicator_value);

  const applyFilter = () =>{
    const newFilter = {
      from_date: fromDate,
      to_date: toDate,
      bill_number: invoiceNumber,
      provider_name: providerName,
      npi_indicator: indicatorValue,
      lineitem_desc: lineItemDesc,
    };
    onFilterChange &&
    onFilterChange(_.pickBy(newFilter, (item)=> !_.isEmpty(item)));
    console.log('newFilter', newFilter);
  };
  const onDateRangeChange = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };
  const indicatorOptions = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ];
  const onProviderNameeChange = (type) => setProviderName(type && type.value);
  const onNPIIndicatorChange = (type) => setIndicatorValue(type && type.value);
  const onInvoiceNumberChange = (value) => setInvoiceNumber(value);
  const onLineItemDescChange = (value) => setLineItemDesc(value);

  return (
    <Card>
      <ul className={classes.listWrapper}>
        <li className={classes.inpLi}>
          <label className={classes.inpLabel}>Invoice Period</label>
          <DateRangePicker
            fromDate={fromDate}
            toDate={toDate}
            onChange={onDateRangeChange}
          />
        </li>
      </ul>
      <TwoColumns className={classes.listWrapper}>
        <li>
          <Input
            label='Invoice Number'
            type={INPUT_TYPE.TEXT}
            placeholder='Enter Invoice Number'
            onChange={onInvoiceNumberChange}
            value={invoiceNumber}
          />
        </li>
        <li>
          <Input
            label='Line Item Desc'
            type={INPUT_TYPE.TEXT}
            placeholder='Enter Line Item Description'
            onChange={onLineItemDescChange}
            value={lineItemDesc}
          />
        </li>
        <li>
          <Input
            label='Provider Name'
            type={INPUT_TYPE.ASYNC}
            url={API_URLs.PROVIDERLIST}
            placeholder='Enter Provider Name'
            value={providerName ? {
              label: providerName,
              value: providerName,
            } : null}
            onChange={onProviderNameeChange}
            optionsFilter={(data) => data}
          />
        </li>
        <li>
          <Input
            label='NPI Indicator'
            type={INPUT_TYPE.SELECT}
            options={indicatorOptions}
            placeholder='NPI Indicator'
            value={indicatorValue ? {
              label: indicatorValue,
              value: indicatorValue,
            } : null}
            onChange={onNPIIndicatorChange}
            optionsFilter={(data) => data}
          />
        </li>
      </TwoColumns>
      <div className={styles.popupBtnWrapper}>
        <Button text='Cancel' type={BUTTON_TYPE.LINK} onClick={onCancel} />
        <Button text='Apply' type={BUTTON_TYPE.PRIMARY} onClick={applyFilter} />
      </div>
    </Card>
  );
};

export default Filter;
