import React, {useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import {MdZoomOutMap} from 'react-icons/md';

import {AILoading, Button, BUTTON_SIZE, BUTTON_TYPE, createUseStyles, Modal, useTheme} from 'aida-components';
import Empty from 'components/empty';

import {useAppStyles} from 'helper/app-styles';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  zoomWrapper: {
    position: 'relative',
  },
  zoomBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
  modal: {
    width: '90%',
  },
});

const colors = ['#36B37E', '#FF8B00'];

const Column = ({data, isLoading, title}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const [isOpenModal, setIsOpenModal] = useState(false);

  const getChartOpts = () => {
    const seriesWithColor = data.series.map((item, i) => ({
      ...item,
      color: colors[i],
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (this.y > 1000000) {
            return Highcharts.numberFormat(this.y / 1000000, 1) + 'M';
          } else if (this.y > 1000) {
            return Highcharts.numberFormat(this.y / 1000, 0) + 'K';
          } else {
            return this.y;
          }
        },
      },
    }));
    return {
      chart: {
        type: 'column',
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: data.categories,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost per Month',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: seriesWithColor,
    };
  };
  const onZoomChart = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);

  if (isLoading) {
    return <AILoading />;
  } else if (_.isEmpty(data)) {
    return <Empty text='No chart data found.' />;
  }

  return (
    <div className={classes.zoomWrapper}>
      <Button
        className={classes.zoomBtn}
        type={BUTTON_TYPE.ICON}
        size={BUTTON_SIZE.SMALL}
        icon={<MdZoomOutMap size={25} />}
        onClick={onZoomChart}
      />
      <HighchartsReact options={getChartOpts()} highcharts={Highcharts} />
      {isOpenModal && (
        <Modal className={classes.modal}>
          <HighchartsReact options={getChartOpts()} highcharts={Highcharts} />
          <div className={classes.btnWrapper}>
            <Button text='Close' type={BUTTON_TYPE.PRIMARY} size={BUTTON_SIZE.SMALL} onClick={onCloseModal} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Column;
