import {useAuthentication} from 'aida-components';

const prefix = '/api/v1';
export const LOGIN_URL = `${prefix}/auth/login`;
export const REFRESH_USER_END_POINT = `${prefix}/auth/refresh-session`;
export const LOGOUT_URL = `${prefix}/auth/logout`;

const useURL = () => {
  let currentUserType;
  const {authUserInfo} = useAuthentication();
  try {
    currentUserType = authUserInfo.userType;
  } catch (e) {
    currentUserType = 'NOT_AUTH';
  }

  return {
    /* ---- AUTHENTICATION ---- */
    LOGIN: `${prefix}/login`,
    LOGOUT: `${prefix}/logout`,

    /* ---- CLAIMS ---- */
    // CLAIMS_LISTING: `${prefix}/smart-claims/claims/get-listing`,
    CLAIMS_LISTING: '/pre-auth-list',
    CLAIM_DETAILS: `${prefix}/smart-claims/claims/get-claim-details`,
    UPDATE_LINEITEM_LIST: `${prefix}/smart-claims/claims/request-update-lineitem-list`,
    PAST_CLAIM_HISTORY: `${prefix}/smart-claims/claims/get-past-claims-history`,
    UPDATE_CLAIM_DECISION: `${prefix}/smart-claims/claims/update-assessor-decision`,
    FILTERS: `${prefix}/smart-claims/claims/get-filter`,
    HOSPITAL_TYPES: `${prefix}/smart-claims/master/hospital-types-list`,
    HOSPITAL_TIER: `${prefix}/smart-claims/master/hospital-tier-list`,
    VISIT_TYPES: `${prefix}/smart-claims/master/visit-types-list`,
    PROVIDERLIST: `${prefix}/smart-claims/master/provider-name-list`,
    REQUEST_TYPES: `${prefix}/smart-claims/master/request-types-list`,

    /* ---- STP Calculator ---- */
    STP_CALCULATOR_PDF_URL: `${prefix}/smart-claims/stp-calculator/export-file`,
    STP_CALCULATOR_CONFIG_URL: `${prefix}/smart-claims/stp-calculator/config`,
    STP_CALCULATOR_TEXT_URL: `${prefix}/smart-claims/stp-calculator/calculate`,
    STP_CALCULATOR_CHART_URL: `${prefix}/smart-claims/stp-calculator/chart-data`,
    STP_CALCULATOR_EXPORT_URL: `${prefix}/smart-claims/stp-calculator/export-calculate`,

    SYSTEM_LOGS_URL: `${prefix}/smart-claims/system_logs/get-system-logs`,
    EXPORT_SYSTEM_LOGS_URL: `${prefix}/smart-claims/system_logs/export-system-logs`,

    SYSTEM_NOTIFICATION_URL: `${prefix}/smart-claims/system_notifications/get-system-notifications`,
    USERS_LIST_URL: `${prefix}/smart-claims/user-management/get-user-list`,
    USERS_CREATE_URL: `${prefix}/smart-claims/user-management/create-user`,
    USERS_DELETE_URL: `${prefix}/smart-claims/user-management/delete-user`,
    USERS_UPDATE_URL: `${prefix}/smart-claims/user-management/update-user`,

    FILE_UPLOAD_URL: `${prefix}/smart-claims/file_upload/request-upload`,
    LIST_UPLOADED_FILES_URL: `${prefix}/smart-claims/file_upload/get-file-list`,
    MANAGER_LIST_URL: `${prefix}/smart-claims/file_upload/get-approver-list`,
    USER_NOTIFICATION_URL: `${prefix}/smart-claims/system_notifications/get-user-notifications`,

    APPROVE_FILE_UPLOAD_URL: `${prefix}/smart-claims/file_upload/approve-file-upload`,
    DELETE_FILE_URL: `${prefix}/smart-claims/file_upload/delete-file`,
    FILE_CONTENT_URL: `${prefix}/smart-claims/file_upload/download-file`,

    GET_STP_CONDITION_URL: `${prefix}/smart-claims/stp_conditions/get-stp-setting`,
    SAVE_STP_CONDITION_URL: `${prefix}/smart-claims/stp_conditions/request-save-stp-setting`,
    APPROVE_STP_CONDITION_URL: `${prefix}/smart-claims/stp_conditions/approve-save-stp-setting`,

    GET_BENCHMARK_SETTING_URL: `${prefix}/smart-claims/stp_conditions/get-fb-setting`,
    SAVE_BENCHMARK_SETTING_URL: `${prefix}/smart-claims/stp_conditions/request-save-fb-setting`,
    APPROVE_BENCHMARK_SETTING_URL: `${prefix}/smart-claims/stp_conditions/approve-save-fb-setting`,

    MODEL_HISTORY_URL: `${prefix}/smart-claims/model-management/get-model-history`,
    MODEL_SELECT_URL: `${prefix}/smart-claims/model-management/request-model-switch`,
    MODEL_APPROVE_URL: `${prefix}/smart-claims/model-management/approve-model-switch`,
    EXPORT_ML_REPORT_URL: `${prefix}/smart-claims/model-management/export-ml-report`,

    EXPORT_CLAIM_LIST_URL: `${prefix}/smart-claims/claims/export-claims-list`,

    SWITCH_TYPE_URL: `${prefix}/switch_type`,

    STATUS_LIST: `${prefix}/master/workflow-status`,
    REJECT_CODE_LIST: `${prefix}/smart-claims/claims/get-reject-list`,

    /* ---- Predictive Modelling ---- */
    PREDICTIVE_MODEL_SUMMARY_CHART_URL: `${prefix}/smart-claims/predictive-modelling/get-charts`,
    PREDICTIVE_MODEL_CHARTS_EXPORT_URL: `${prefix}/smart-claims/predictive-modelling/export-charts`,
  };
};

export default useURL;
