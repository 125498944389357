import {Input, INPUT_TYPE} from 'aida-components';

import styles from './input.module.scss';

export const SELECT_OPTIONS_TYPE = {
  SYMBOL_TYPE: [
    {value: 'lesser', label: '≤'},
    {value: 'greater', label: '≥'},
  ],
};

const SelectInput = ({
  label,
  selectValue,
  selectOptions,
  defaultSelectValue,
  defaultInputValue,
  inputValue,
  placeholder,
  error,
  onSelectChange,
  onInputChange,
}) => {
  return (
    <div className={styles.selectAndInput}>
      {label && <label className={styles.label}>{label}</label>}
      <ul>
        <li>
          <Input
            type={INPUT_TYPE.SELECT}
            defaultValue={defaultSelectValue || {value: '0', label: '≤'}}
            value={selectValue}
            onChange={onSelectChange}
            options={selectOptions}
            isClearable={false}
          />
        </li>
        <li>
          <Input
            type={INPUT_TYPE.TEXT}
            placeholder={placeholder}
            onChange={onInputChange}
            value={inputValue}
            error={error}
            defaultValue={defaultInputValue}
          />
        </li>
      </ul>
    </div>
  );
};

export default SelectInput;
