import {Card, createUseStyles, Grid, GridItem, useTheme} from 'aida-components';
import {Fragment} from 'react';
import {UserTypeConfig} from 'helper/user-type-config';
import LabelValue from 'components/label-value';
import {useStyles} from 'views/claims/styles';
import {convertDateString, DBDateFormat, UIOutputFormat} from 'helper/utils';

const useLocalStyles = createUseStyles({
  ...useStyles,
});

const leftItemsList = ({userType, businessType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const businessTypeList = {
    IB: [
      {
        label: 'Gender',
        id: CLAIM_360_IDS.GENDER,
      }, {
        label: 'Special Status',
        id: CLAIM_360_IDS.SPECIAL,
      },
    ],
    EB: [
      {
        label: 'Gender',
        id: CLAIM_360_IDS.GENDER,
      }, {
        label: 'Special Status',
        id: CLAIM_360_IDS.SPECIAL,
      }, {
        label: 'Member Join Date',
        id: CLAIM_360_IDS.MEMBER_JOIN_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.MEMBER_JOIN_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      },
    ],
  };

  return businessTypeList[businessType];
};

const LeftItems = ({data, businessType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const userType = 'retail';

  const lListItems = leftItemsList({userType, businessType, classes});

  return (
    <Fragment>
      <ul className={classes.listWrapper}>
        {lListItems.map((i, index) => <LabelValue
          key={`${i.label}-key-${index}`}
          item={i}
          data={data}
        />)}
      </ul>
    </Fragment>
  );
};

const rightItemsList = ({userType, businessType, classes}) => {
  const CLAIM_360_IDS = UserTypeConfig[userType.toUpperCase()]['DATA_IDS']['CLAIMS_360'];

  const businessTypeList = {
    IB: [
      {
        label: 'Age',
        id: CLAIM_360_IDS.AGE,
      }, {
        label: 'Relationship',
        id: CLAIM_360_IDS.RELATIONSHIP,
      },
    ],
    EB: [
      {
        label: 'Age',
        id: CLAIM_360_IDS.AGE,
      }, {
        label: 'Relationship',
        id: CLAIM_360_IDS.RELATIONSHIP,
      }, {
        label: 'Termination Date',
        id: CLAIM_360_IDS.MEMBER_END_DATE,
        valueDisplay: (data) => <span className={classes.customDisplayValue}>{convertDateString(
          data[CLAIM_360_IDS.MEMBER_END_DATE],
          DBDateFormat,
          UIOutputFormat,
        )}</span>,
      },
    ],
  };

  return businessTypeList[businessType];
};

const RightItems = ({data, businessType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const userType = 'retail';

  const rListItems = rightItemsList({userType, businessType, classes});

  return (
    <Fragment>
      <ul className={classes.listWrapper}>
        {rListItems.map((i, index) => <LabelValue
          key={`${i.label}-key-${index}`}
          item={i}
          data={data}
        />)}
      </ul>
    </Fragment>
  );
};

const ClientDetails = ({claimDetails, businessType}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  return (
    <Card className={classes.space}>
      <h3 className={classes.cardTitle}>Client Details</h3>
      <div className={classes.policyAndClaimDetails}>
        <Grid gridArea={['c1 c1 c1 c1 c1 c1 c2 c2 c2 c2 c2 c2']}>
          <GridItem
            name='c1'
            component={<div><LeftItems data={claimDetails} businessType={businessType}/></div>}
          />
          <GridItem
            name='c2'
            component={<div><RightItems data={claimDetails} businessType={businessType}/></div>}
          />
        </Grid>
      </div>
    </Card>
  );
};

export default ClientDetails;
