import {MdErrorOutline} from 'react-icons/md';
import {createUseStyles, useTheme} from 'aida-components';

const useLocalStyles = createUseStyles({
  empty: ({theme}) => ({
    width: '100%',
    padding: '50px',
    textAlign: 'center',

    '& >h3': {
      fontWeight: 300,
      marginTop: '10px',
    },

    svg: {
      color: theme.default.color.disabled,
    },
  }),
});

const Empty = ({text}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  return (
    <div className={classes.empty}>
      <MdErrorOutline size={50} />
      <h3>{text}</h3>
    </div>
  );
};

export default Empty;
