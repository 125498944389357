import {Fragment, useEffect, useState} from 'react';
import {Col, Row} from 'react-grid-system';

import {MdCached, MdCheckCircle} from 'react-icons/md';
import {
  AILoading,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Card,
  Dropdown,
  classNames,
  IconNames,
  toast,
  useTheme,
  createUseStyles,
  useAuthentication,
  Icon,
  Input, moment, INPUT_TYPE,
} from 'aida-components';

import Table, {Cell} from 'components/table';
import {getEditPermission, getExportPermission, VIEWS} from 'helper/routers';
import useRequest from 'hooks/useRequest';
import ApprovalList from 'components/approval-list';
import Modal from 'components/modal';
import useURL from 'hooks/useURL';

import styles from 'views/model-control/model-control.module.scss';
import {useAppStyles} from 'helper/app-styles';
import {roundNumber} from 'helper/utils';
import fileDownload from 'js-file-download';
import {MODEL_HISTORY} from 'views/temp-data';
import {UserTypeConfig} from 'helper/user-type-config';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  functionsModel: {
    display: 'flex',
    justifyContent: 'space-between',
    listStyle: 'none',
    position: 'relative',
    marginBottom: 20,
    zIndex: 2,
    '&> ul': {
      display: 'flex',
      '&> li': {
        minWidth: 300,
        marginRight: 20,
      },
    },
    '&> li': {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 20,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '&>div': {
      marginBottom: 10,
    },
  },
  topContainer: {
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const ButtonSwitchApproval = ({modelVersion, lob, workflowType}) => {
  const API_URL = useURL();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [manager, setManager] = useState();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
      useRequest(API_URL.MODEL_SELECT_URL, {
        verb: 'post',
        params: {
          selected_model: modelVersion,
          lob: lob,
          workflow_type: workflowType && workflowType.toLowerCase(),
          adm_type: 'RETAIL',
        },
        config: {
          headers: {
            approver_id: manager,
          },
        },
      });

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success(`Chosen model is sent successful for approval to ${manager}.`);
      setIsOpenModal(false);
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t sent the chosen model. Please try again.',
      );
    }
  }, [status, response]);

  const onOpenModal = () => {
    setIsOpenModal(true);
    setManager();
  };
  const onCloseModal = () => setIsOpenModal(false);
  const onTriggerModel = () => {
    makeRequest();
    setIsOpenModal(false);
  };
  const onSelectManager = ({value}) => setManager(value);

  const getButtonState = () => {
    return (
      <Button
        className={styles.btnSelect}
        type={BUTTON_TYPE.PRIMARY}
        size={BUTTON_SIZE.SMALL}
        text='SELECT'
        onClick={onOpenModal}
        iconPosition='left'
        disabled={status === FETCHING}
        icon={
          status === FETCHING && <MdCached className={styles.spin} size={20}/>
        }
      />
    );
  };

  return (
    <Fragment>
      {getButtonState()}
      {isOpenModal && (
        <Modal>
          <Card>
            <h3 className={styles.modalTitle}>Warning!</h3>
            <p className={styles.modalContent}>
                  Model <strong>{modelVersion}</strong> Selection has been initiated.
            </p>
            <p className={styles.modalContent}>
                  Requires further approval to activate from
            </p>
            <ApprovalList onSelect={onSelectManager} screen='model_control'/>
            <div className={styles.btnWrapper}>
              <Button
                text='Request'
                disabled={!manager}
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.SMALL}
                onClick={onTriggerModel}
              />
              <Button
                text='Cancel'
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.SMALL}
                onClick={onCloseModal}
              />
            </div>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};


const ButtonRetrainApproval = ({isModelTrainInProgress}) => {
  const API_URL = useURL();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [manager, setManager] = useState();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
      useRequest(API_URL.MODEL_RETRAIN_REQUEST_URL, {
        verb: 'post',
        config: {
          headers: {
            approver_id: manager,
          },
        },
      });

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success(`Model retraining request is sent successful for approval to ${manager}.`);
      setIsOpenModal(false);
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t request for model retraining. Please try again.',
      );
    }
  }, [status, response]);

  const onOpenModal = () => {
    setIsOpenModal(true);
    setManager();
  };
  const onCloseModal = () => setIsOpenModal(false);
  const onTriggerModel = () => {
    makeRequest();
    setIsOpenModal(false);
  };
  const onSelectManager = ({value}) => setManager(value);

  const getButtonState = () => {
    return (
      <Button
        className={styles.btnSelect}
        type={BUTTON_TYPE.PRIMARY}
        size={BUTTON_SIZE.SMALL}
        text='Model Retrain'
        onClick={onOpenModal}
        iconPosition='left'
        disabled={status === FETCHING || isModelTrainInProgress}
        icon={
          status === FETCHING && <MdCached className={styles.spin} size={20}/>
        }
      />
    );
  };

  return (
    <Fragment>
      {getButtonState()}
      {isOpenModal && (
        <Modal>
          <Card>
            <h3 className={styles.modalTitle}>Warning!</h3>
            <p className={styles.modalContent}>
                  Model Retraining Request has been initiated.
            </p>
            <p className={styles.modalContent}>
                  Requires further approval to trigger retraining from
            </p>
            <ApprovalList onSelect={onSelectManager} screen='model_control'/>
            <div className={styles.btnWrapper}>
              <Button
                text='Request'
                disabled={!manager}
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.SMALL}
                onClick={onTriggerModel}
              />
              <Button
                text='Cancel'
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.SMALL}
                onClick={onCloseModal}
              />
            </div>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};


const ButtonDelete = ({modelVersion, lob, workflowType}) => {
  const API_URL = useURL();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [manager, setManager] = useState();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.MODEL_DELETE_URL, {
      verb: 'post',
      params: {
        selected_model: modelVersion,
        lob: lob,
        workflow_type: workflowType,
        adm_type: 'EFILE',
      },
      config: {
        headers: {
          approver_id: manager,
        },
      },
    });

  useEffect(() => {
    if (status === SUCCESS) {
      toast.success('Chosen model is sent successful.');
      setIsOpenModal(false);
    } else if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
        'Can\'t sent the chosen model. Please try again.',
      );
    }
  }, [status, response]);

  const onOpenModal = () => {
    setIsOpenModal(true);
    setManager();
  };
  const onCloseModal = () => setIsOpenModal(false);
  const onTriggerModel = () => {
    // makeRequest();
    setIsOpenModal(false);
    // TODO: Remove after integrating with BE API
    toast.success(`Model Removal is sent for approval to ${manager} successfully.`);
  };
  const onSelectManager = ({value}) => setManager(value);

  const getButtonState = () => {
    return (
      <Button
        className={styles.btnDelete}
        type={BUTTON_TYPE.ICON}
        size={BUTTON_SIZE.SMALL}
        icon={<Icon name={IconNames.RecycleBin}/>}
        text = "Delete"
        onClick={onOpenModal}
        iconPosition='left'
      />
    );
  };

  return (
    <Fragment>
      {getButtonState()}
      {isOpenModal && (
        <Modal>
          <Card>
            <h3 className={styles.modalTitleRed}>Warning!</h3>
            <p className={styles.modalContent}>
                  Model <strong>{modelVersion}</strong> Removal has been initiated.
            </p>
            <p className={styles.modalContent}>
                  Requires further approval to remove selected model from
            </p>
            <ApprovalList onSelect={onSelectManager} screen='model_control'/>
            <div className={styles.btnWrapper}>
              <Button
                text='Request'
                disabled={!manager}
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.SMALL}
                onClick={onTriggerModel}
              />
              <Button
                text='Cancel'
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.SMALL}
                onClick={onCloseModal}
              />
            </div>
          </Card>
        </Modal>
      )}
    </Fragment>
  );
};

const ReportButton = ({text, url, reportName, reportType}) => {
  const [{status, response}, makeRequest, {SUCCESS, FETCHING, ERROR}] =
      useRequest(url, {
        config: {
          responseType: 'blob',
          params: {
            report_type: reportType,
            report_name: reportName,
          },
        },
      });

  useEffect(() => {
    const time = moment().format('YYYYMMDD_HHmmss');
    if (status === SUCCESS && response) {
      if (reportName) {
        fileDownload(response.data, reportName);
      } else {
        fileDownload(response.data, reportType === 'retraining' ?
          'retraining_report.xlsx' : 'fairness_report.xlsx');
      }
    } else if (status === ERROR) {
      toast.error('Can\'t download this file. Please try again.');
    }
  }, [response, status]);

  return (
    <div>
      <Button
        type={BUTTON_TYPE.LINK}
        text={text}
        onClick={makeRequest}
      />
    </div>
  );
};

const ButtonDownload = ({retrainingReport, fairnessReport}) => {
  const API_URLs = useURL();
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  return (
    <Dropdown
      isOpen={isDownloadOpen}
      onTrigger={setIsDownloadOpen}
      trigger={
        <Button
          type={BUTTON_TYPE.ICON}
          size={BUTTON_SIZE.SMALL}
          icon={<Icon name={IconNames.Download}/>}
          text="Download Reports"
        />
      }
      dropdown={
        <Card>
          <div className={classes.search}>
            <ReportButton
              url={API_URLs.EXPORT_ML_REPORT_URL}
              text="Retraining Report"
              reportName={retrainingReport}
              reportType={'retraining'}
            />
            <ReportButton
              url={API_URLs.EXPORT_ML_REPORT_URL}
              text="Fairness Report"
              reportName={fairnessReport}
              reportType={'fairness'}
            />
          </div>
        </Card>
      }
    />
  );
};


const ButtonRetrain = ({isModelTrainInProgress}) => {
  return (
    <div>
      <ButtonRetrainApproval
        isModelTrainInProgress={isModelTrainInProgress}
      />
    </div>
  );
};

const ModelHistory = () => {
  console.log('1');
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const API_URL = useURL();
  const {authUserInfo} = useAuthentication();
  const canEdit = getEditPermission(
    authUserInfo.role,
    VIEWS.MODEL_CONTROL.key,
  );
  const canExport = getExportPermission(
    authUserInfo.role,
    VIEWS.MODEL_CONTROL.key,
  ) || canEdit;

  const [lob, setLob] = useState('IB');
  const [workflowType, setWorkflowType] = useState('Claim');

  const onLobChange = (type) => setLob(type && type.value);
  const onWorkflowTypeChange = (type) => setWorkflowType(type && type.value);

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
      useRequest(API_URL.MODEL_HISTORY_URL, {
        config: {
          params: {
            lob: lob,
            adm_type: 'EFILE',
            workflow_type: workflowType && workflowType.toLowerCase(),
          },
        },
      });

  useEffect(() => {
    makeRequest();
  }, [lob, workflowType]);

  useEffect(() => {
    if (status === ERROR) {
      toast.error(
        (response.data && response.data.detail) ||
          'Can\'t get the model list. Please try again.');
    }
  }, [status]);

  return (
    <Card>
      <ul className={classes.functionsModel}>
        {<ul>
          <li>
            <Input
              label='LOB'
              type={INPUT_TYPE.SELECT}
              placeholder='Choose LOB'
              value={lob ? {value: lob, label: lob} : null}
              options={UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES.BUSINESS_LINE}
              onChange={onLobChange}
            />
          </li>
          <li>
            <Input
              label='Workflow Type'
              type={INPUT_TYPE.SELECT}
              placeholder='Choose Workflow Type'
              value={workflowType ? {value: workflowType, label: workflowType} : null}
              options={UserTypeConfig['RETAIL'].SELECT_OPTION_TYPES.WORKFLOW_TYPE}
              onChange={onWorkflowTypeChange}
            />
          </li>
        </ul>}
        {canEdit && (<li>
          <ButtonRetrain
            isModelTrainInProgress={
              status === SUCCESS && response && response.data ?
                response.data.isModelTrainInProgress : true
            }
          />
        </li>)}
      </ul>
      <h3 className={classes.cardTitle}>Model History</h3>
      <Table
        className={styles.tableStyle}
        head={
          <tr>
            <Cell align='center'>Model Version</Cell>
            <Cell>Generated By</Cell>
            <Cell align='center'>Overall Model Accuracy</Cell>
            <Cell></Cell>
            <Cell></Cell>
            <Cell></Cell>
          </tr>
        }>
        {status === FETCHING && (
          <tr>
            <Cell align='center' colSpan={4}>
              <AILoading/>
            </Cell>
          </tr>
        )}

        {status === SUCCESS && response.data.modelMLList.map((model) => (
          <HistoryRow
            key={model.modelVersion}
            lob={lob}
            workflowType={workflowType}
            canEdit={canEdit}
            canExport={canExport}
            {...model}
            lastModelSelectInformation={
              response.data.lastModelSelectInformation
            }
          />
        ))}
      </Table>
    </Card>
  );
};

const HistoryRow = ({
  lob,
  workflowType,
  canEdit,
  canExport,
  modelVersion,
  modelUser,
  modelAccuracy,
  modelRetrainingReport,
  modelFairnessReport,
  selectedModel,
  isSelected,
  lastModelSelectInformation,
}) => {
  const isRejected = () =>
    lastModelSelectInformation &&
      lastModelSelectInformation.currentStatus === 'CANCEL';

  return (
    <tr className={classNames({[styles.rejectedRow]: isRejected()})}>
      <Cell align='center'>
        {modelVersion}
      </Cell>
      <Cell>{modelUser}</Cell>
      <Cell align='center'>{roundNumber({
        number: modelAccuracy,
        suffix: '%',
        isMultiplyBy100: true,
      })}</Cell>
      <Cell style={{width: '60px'}}>
        {isSelected === 1 && (
          <span className={styles.active}>
            <MdCheckCircle size={20}/> SELECTED
          </span>
        )}
        {isSelected !== 1 && (
          <Fragment>
            {!lastModelSelectInformation && (
              <Fragment>
                {canEdit && !selectedModel && (
                  <ButtonSwitchApproval
                    modelVersion={modelVersion}
                    lob={lob}
                    workflowType={workflowType}
                  />
                )}
              </Fragment>
            )}

            {lastModelSelectInformation && (
              <Fragment>
                {(canEdit &&
                          (lastModelSelectInformation.selectedModel !== modelVersion ||
                              lastModelSelectInformation.currentStatus === 'CANCEL' ||
                              lastModelSelectInformation.currentStatus ===
                              'PENDING START')) && (
                  <ButtonSwitchApproval
                    modelVersion={modelVersion}
                    lob={lob}
                    workflowType={workflowType}
                  />
                )}

                {lastModelSelectInformation.currentStatus === 'CANCEL' && (
                  <Fragment>
                    {canEdit && !selectedModel && (
                      <ButtonSwitchApproval
                        modelVersion={modelVersion}
                        lob={lob}
                        workflowType={workflowType}
                      />
                    )}
                    {lastModelSelectInformation.selectedModel &&
                                lastModelSelectInformation.selectedModel ===
                                modelVersion && <span>REJECTED</span>}
                  </Fragment>
                )}
                {lastModelSelectInformation.selectedModel &&
                          lastModelSelectInformation.selectedModel === modelVersion &&
                          lastModelSelectInformation.currentStatus !== 'CANCEL' &&
                          lastModelSelectInformation.currentStatus !==
                          'PENDING START' && (
                  <span>{lastModelSelectInformation.currentStatus}</span>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Cell>
      <Cell style={{width: '30px'}}>
        {isSelected !== 1 && canEdit && (
          <Fragment>
            <ButtonDelete modelVersion={modelVersion} lob={lob} workflowType={workflowType}/>
          </Fragment>
        )}
      </Cell>
      <Cell style={{width: '30px'}}>
        {canExport && (<ButtonDownload
          retrainingReport={modelRetrainingReport}
          fairnessReport={modelFairnessReport}
        />)}
      </Cell>
    </tr>
  );
};

export default ModelHistory;
