import {Timeline as TL, TimelineEvent as TE} from 'react-event-timeline';

export const Timeline = ({children, ...rest}) => {
  return (
    <TL lineColor='#dddddd' {...rest}>
      {children}
    </TL>
  );
};

export const TimelineEvent = ({children, ...rest}) => (
  <TE
    titleStyle={{
      color: '#333333',
      fontFamily: '"Roboto Condensed", sans-serif',
      fontSize: '18px',
      fontWeight: '600',
      marginTop: '10px',
    }}
    subtitleStyle={{
      background: '#dddddd',
      display: 'inline-block',
      padding: '5px 10px',
      borderRadius: '3px',
      fontSize: '14px',
    }}
    bubbleStyle={{
      top: '-9px',
      border: 'none',
    }}
    contentStyle={{
      boxShadow: 'none',
      margin: '0 0 10px 0',
      padding: '10px 0',
      borderBottom: '1px dashed #cccccc',
      fontSize: '16px',
    }}
    {...rest}
  >
    {children}
  </TE>
);
