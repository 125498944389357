import {Fragment, useEffect, useState} from 'react';
import {
  AILoading,
  Button,
  BUTTON_TYPE,
  Card,
  CheckboxGroup,
  classNames,
  createUseStyles,
  DateRangePicker,
  Input,
  INPUT_TYPE, toast, useRequest,
  useTheme,
} from 'aida-components';
import {FaGreaterThanEqual, FaLessThanEqual} from 'react-icons/fa';
import {useAppStyles} from 'helper/app-styles';
import {UserTypeConfig} from 'helper/user-type-config';
import {OPTIONS_TYPE} from 'helper/constants';
import useURL from 'hooks/useURL';
import SliderInput from 'components/slider-input';

const useLocalStyles = createUseStyles({
  ...useAppStyles,
  cardHeight: {
  },
  fieldLabel: ({theme}) => ({
    ...theme.typography.title,
    marginBottom: 10,
  }),
  settingsContainer: {
    '& >li': {
      marginTop: 20,
    },
  },
  datePickerWrapper: {
    marginBottom: 20,
    '& >li': {
      width: 'calc(50% - 10px)',
      textAlign: 'center',
    },
  },
  twoColumn: {
    padding: '20px 0',
    '& >li': {
      display: 'inline-block',
      verticalAlign: 'top',
      width: '49%',
    },
  },
  buttons: {
    marginTop: 20,
    textAlign: 'right',

    '& >button:first-child': {
      marginRight: 20,
    },
  },
});


const Form = ({classes, response, onFilterChange, onReset}) => {
  const userType = 'retail';
  const API_URLs = useURL();

  const [fromDate, setFromDate] = useState(response && response.fromDate);
  const [toDate, setToDate] = useState(response && response.toDate);
  const [submissionType, setSubmissionType] = useState(
    response && response.submissionTypes && response.submissionTypes.length > 0 && response.submissionTypes[0],
  );

  const [visitType, setVisitType] = useState(
    response && response.visitTypes && response.visitTypes.length > 0 && response.visitTypes[0],
  );
  const [hospitalTier, setHospitalTier] = useState(
    response && response.hospitalTiers && response.hospitalTiers.length > 0 && response.hospitalTiers[0],
  );
  const [businessLine, setBusinessLine] = useState(
    response && response.businessLines && response.businessLines.length > 0 && response.businessLines[0],
  );
  const [insurancePlan, setInsurancePlan] = useState(
    response && response.insurancePlans && response.insurancePlans.length > 0 && response.insurancePlans[0],
  );
  const [requestType, setRequestType] = useState(
    response && response.requestTypes && response.requestTypes.length > 0 && response.requestTypes[0],
  );

  const [claimAmount, setClaimAmount] = useState(response && response.claimAmount);
  const [machineConfidence, setMachineConfidence] = useState(response && response.machineConfidence);
  const [outlierScore, setOutlierScore] = useState(response && response.outlierScore);
  const [aiDecisions, setAiDecisions] = useState(
    response && response.aiDecisions ? response.aiDecisions : ['APPROVE', 'REJECT', 'REVIEW'],
  );
  const [doctorFlag, setDoctorFlag] = useState(response && response.doctorFlag ?
    response.doctorFlag : ['PANEL', 'NONPANEL']);
  const [outlierFlag, setOutlierFlag] = useState(response && response.outlierFlag ?
    response.outlierFlag : ['Yes', 'No']);

  const onDateRangeChange = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };
  const onSubmissionTypeChange = (type) => setSubmissionType(type && type.value);
  const onVisitTypeChange = (type) => setVisitType(type && type.value);
  const onHospitalTierChange = (type) => setHospitalTier(type && type.value);
  const onBusinessLineChange = (type) => setBusinessLine(type && type.value);
  const onInsurancePlanChange = (type) => setInsurancePlan(type && type.value);
  const onRequestTypeChange = (type) => setRequestType(type && type.value);
  const onMachineConfidenceChange = (value) => setMachineConfidence(value);
  const onOutlierScoreChange = (value) => setOutlierScore(value);
  const onClaimAmountChange = (e) => setClaimAmount(e);
  const onAiDecisionsChange = (values) => setAiDecisions([...values]);
  const onDoctorFlagChange = (values) => setDoctorFlag([...values]);
  const onOutlierFlagChange = (values) => setOutlierFlag([...values]);

  const applyFilter = (filters) =>{
    onFilterChange &&
    onFilterChange(filters);
  };

  const applyCalFilter = () => {
    onFilterChange &&
    onFilterChange({
      fromDate,
      toDate,
      // submissionType,
      visitType,
      // providerType,
      hospitalTier,
      businessLine,
      insurancePlan,
      requestType,
      claimAmount,
      machineConfidence,
      outlierScore,
      aiDecisions,
      // doctorFlag,
      outlierFlag,
    });
  };

  useEffect(() => applyFilter(response), []);

  return (
    <Fragment>
      <li>
        <DateRangePicker
          label={'Period'}
          from={fromDate}
          to={toDate}
          onChange={onDateRangeChange}
          className={classes.datePickerWrapper}
        />
      </li>
      <li>
        <Input
          label='LOB'
          type={INPUT_TYPE.SELECT}
          placeholder='Choose LOB'
          value={businessLine ? {value: businessLine, label: businessLine} : null}
          options={UserTypeConfig[userType.toUpperCase()].SELECT_OPTION_TYPES.BUSINESS_LINE}
          onChange={onBusinessLineChange}
          isClearable
        />
      </li>
      <li>
        <Input
          label='Hospital Tier'
          type={INPUT_TYPE.ASYNC}
          url={API_URLs.HOSPITAL_TIER}
          placeholder='Enter Hospital Tier'
          value={hospitalTier ? {
            label: hospitalTier,
            value: hospitalTier,
          } : ''}
          onChange={onHospitalTierChange}
          optionsFilter={(data) => data}
          isClearable
        />
      </li>
      <li>
        <Input
          label='Visit Type'
          type={INPUT_TYPE.ASYNC}
          url={API_URLs.VISIT_TYPES}
          placeholder='Enter Visit Type'
          value={visitType ? {
            label: visitType,
            value: visitType,
          } : null}
          onChange={onVisitTypeChange}
          optionsFilter={(data) => data}
          isClearable
        />
      </li>
      <li>
        <Input
          label='Insurance Plan Type'
          type={INPUT_TYPE.SELECT}
          placeholder='Choose insurance plan'
          value={insurancePlan ? {value: insurancePlan, label: insurancePlan} : null}
          options={UserTypeConfig[userType.toUpperCase()].SELECT_OPTION_TYPES.INSURANCE_TYPE}
          onChange={onInsurancePlanChange}
          isClearable
        />
      </li>
      <li>
        <Input
          label='CRM Request Type'
          type={INPUT_TYPE.ASYNC}
          url={API_URLs.REQUEST_TYPES}
          placeholder='Enter Request Type'
          value={requestType ? {
            label: requestType,
            value: requestType,
          } : null}
          onChange={onRequestTypeChange}
          optionsFilter={(data) => data}
          isClearable
        />
      </li>
      <li>
        <Input
          // className={styles.faIcon}
          label='Claim Amount'
          type={INPUT_TYPE.TEXT}
          placeholder='S$'
          icon={<FaLessThanEqual size={20} />}
          value={claimAmount || ''}
          onChange={onClaimAmountChange}
        />
      </li>
      <li>
        <SliderInput
          // className={styles.faIcon}
          label='Minimum Machine Confidence'
          type={INPUT_TYPE.SLIDER}
          min={0}
          max={100}
          marks={{0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%'}}
          step={1}
          icon={<FaGreaterThanEqual size={20} />}
          value={machineConfidence || 0}
          onChange={onMachineConfidenceChange}
        />
      </li>
      <li>
        <SliderInput
          // className={styles.faIcon}
          label='Maximum Outlier Score'
          type={INPUT_TYPE.SLIDER}
          min={0}
          max={1}
          marks={{0: '0', 0.5: '0.5', 1: '1'}}
          step={0.1}
          value={(outlierScore !== undefined) ? outlierScore : 1}
          onChange={onOutlierScoreChange}
          allowIntegerOnly={false}
        />
      </li>
      <li>
        <ul className={classes.twoColumn}>
          <li>
            <label className={classes.fieldLabel}>Outlier Flag</label>
            <CheckboxGroup
              items={[
                {
                  value: 'Yes',
                  label: 'YES',
                },
                {
                  value: 'No',
                  label: 'NO',
                },
              ]}
              values={outlierFlag}
              onChange={onOutlierFlagChange}
            />
          </li>
        </ul>
      </li>
      <li>
        <label className={classes.fieldLabel}>Decision</label>
        <CheckboxGroup
          items={[
            {
              value: 'APPROVE',
              label: 'APPROVE',
            },
            {
              value: 'REJECT',
              label: 'REJECT',
            },
            {
              value: 'REVIEW',
              label: 'REVIEW',
            },
          ]}
          values={aiDecisions}
          onChange={onAiDecisionsChange}
        />
      </li>
      <li className={classes.buttons}>
        <Button type={BUTTON_TYPE.LINK} text='Reset' onClick={onReset} />
        <Button type={BUTTON_TYPE.PRIMARY} text='Calculate' onClick={applyCalFilter} />
      </li>
    </Fragment>
  );
};


const StpSettings = ({onFilterChange}) => {
  const theme = useTheme();
  const classes = useLocalStyles({theme});

  const API_URLs = useURL();
  const [{status, response}, makeRequest, {SUCCESS, FETCHING, ERROR}, source] = useRequest(
    API_URLs.STP_CALCULATOR_CONFIG_URL,
  );

  const [toggleReset, setToggleReset] = useState(false);

  const onReset = () => setToggleReset(!toggleReset);

  useEffect(() => {
    makeRequest();
    return () => {
      source && source.cancel();
    };
  }, [toggleReset]);
  useEffect(() => {
    if (status === ERROR && response.header) {
      toast.error(response.header.message);
    }
  }, [status]);

  return (
    <Card className={classNames(classes.space, classes.cardHeight)}>
      <h3 className={classes.cardTitle}>Auto Adjudication Settings</h3>
      <ul className={classes.settingsContainer}>
        {status === FETCHING && (
          <li>
            <AILoading />
          </li>
        )}
        {status === SUCCESS && <Form
          classes={classes}
          response={response && response.data}
          onFilterChange={onFilterChange}
          onReset={onReset}
        />}
      </ul>
    </Card>
  );
};

export default StpSettings;
