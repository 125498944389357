import React, {useEffect, useState} from 'react';
import {MdCancel, MdDelete} from 'react-icons/md';
import {Button, BUTTON_SIZE, BUTTON_TYPE, Card, Dropdown, Icon, IconNames, toast} from 'aida-components';

import {Cell} from 'components/table';
import EditUser from './edit-user.jsx';
import useRequest from 'hooks/useRequest';

import useURL from 'hooks/useURL';

import styles from './management.module.scss';
import {roles} from 'helper/routers';

const User = ({user, handleSuccess, canEdit}) => {
  const API_URL = useURL();
  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] = useRequest(
    API_URL.USERS_DELETE_URL, {
      verb: 'delete',
      config: {
        params: {
          lan_id: user.lan_id,
        },
      },
    },
  );

  const [editState, setEditState] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);

  const onDeleteCancel = () => setIsDeleteUserOpen(false);
  const toogleEditState = () => setEditState(!editState);
  const handleEditCancel = () => setEditState(false);

  useEffect(() => {
    if (status === ERROR) {
      toast.error((response.data && response.data.detail) || `Can't remove "${user.lan_id}". Please try again.`);
    }
    if (status === SUCCESS) {
      if (handleSuccess) {
        handleSuccess();
      }
      setIsDeleteUserOpen(false);
      toast.success(`"${user.lan_id}" was removed successfully`);
    }
  }, [status]);

  if (editState) {
    return (
      <EditUser
        user={user}
        handleSuccess={handleSuccess}
        handleCancel={handleEditCancel}
      />
    );
  }

  return (
    <tr>
      <Cell>{user.lan_id}</Cell>
      <Cell>{roles.find((role) => role.id === user.role).text}</Cell>
      <Cell>{user.created_by}</Cell>
      <Cell align='center'>
        <span className={styles[user.status]}>{user.status}</span>
      </Cell>
      {/* {*/}
      {/*  canEdit && <Cell align='right'>*/}
      {/*    <Button*/}
      {/*      className={styles.editBtn}*/}
      {/*      type={BUTTON_TYPE.LINK}*/}
      {/*      text='Edit'*/}
      {/*      size={BUTTON_SIZE.SMALL}*/}
      {/*      onClick={toogleEditState}*/}
      {/*      icon={<Icon name={IconNames.Edit} />}*/}
      {/*    />*/}
      {/*    <Dropdown*/}
      {/*      isOpen={isDeleteUserOpen}*/}
      {/*      onTrigger={setIsDeleteUserOpen}*/}
      {/*      trigger={*/}
      {/*        <Button*/}
      {/*          className={styles.deleteBtn}*/}
      {/*          text='Delete'*/}
      {/*          type={BUTTON_TYPE.LINK}*/}
      {/*          size={BUTTON_SIZE.SMALL}*/}
      {/*          icon={<Icon name={IconNames.RecycleBin} />}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      dropdown={*/}
      {/*        <Card className={styles.form}>*/}
      {/*          <p>{`Are you sure want to delete "${user.lan_id}?"`}</p>*/}
      {/*          <div className={styles.btnWrapper}>*/}
      {/*            <Button*/}
      {/*              className={styles.realDeleteBtn}*/}
      {/*              type={BUTTON_TYPE.LINK}*/}
      {/*              text='Delete'*/}
      {/*              size={BUTTON_SIZE.SMALL}*/}
      {/*              iconPosition='left'*/}
      {/*              icon={<MdDelete size={20} />}*/}
      {/*              onClick={makeRequest}*/}
      {/*              disabled={status === FETCHING}*/}
      {/*            />*/}
      {/*            <Button*/}
      {/*              className={styles.cancelBtn}*/}
      {/*              type={BUTTON_TYPE.LINK}*/}
      {/*              text='Cancel'*/}
      {/*              size={BUTTON_SIZE.SMALL}*/}
      {/*              iconPosition='left'*/}
      {/*              icon={<MdCancel size={20} />}*/}
      {/*              onClick={onDeleteCancel}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </Card>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </Cell>*/}
      {/* }*/}
    </tr>
  );
};

export default User;
