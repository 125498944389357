import {useEffect} from 'react';
import {DotLoading, Input, INPUT_TYPE, toast, useAuthentication, useRequest} from 'aida-components';

import useURL from 'hooks/useURL';
import styles from './approval-list.module.scss';

// const createOpts = (users, authUserInfo) =>
//   filter(users, (user) => user.LANID !== authUserInfo.email).map((user) => ({
//     value: user.LANID,
//     label: user.LANID,
//   }));

const createOpts = (users, authUserInfo) =>
  users.map((user) => ({
    value: user.LANID,
    label: user.LANID,
  }));

const ApprovalList = ({onSelect, screen}) => {
  const API_URL = useURL();
  const {authUserInfo} = useAuthentication();

  const [{status, response}, makeRequest, {FETCHING, SUCCESS, ERROR}] =
    useRequest(API_URL.MANAGER_LIST_URL, {
      config: {
        params: {
          role: authUserInfo.role,
          screen,
        },
      },
    });

  useEffect(() => {
    makeRequest();
  }, []);

  useEffect(() => {
    if (status === ERROR) {
      toast.error('Can\'t get the manager list. Please try again.');
    }
  }, [status]);

  return (
    <div className={styles.pickerWrapper}>
      {status === FETCHING && <DotLoading />}
      {status === SUCCESS && response.data && (
        <Input
          type={INPUT_TYPE.SELECT}
          placeholder='Choose a manager'
          options={createOpts(response.data, authUserInfo)}
          onChange={onSelect}
        />
      )}
      {status === SUCCESS && (!response.data || response.data.length < 1) && (
        <span className={styles.noManager}>
          No managers found to make an approval.
        </span>
      )}
    </div>
  );
};

export default ApprovalList;
