import {Fragment, useEffect} from 'react';
import {toast, useAuthentication, useHistory} from 'aida-components';

import {LOGIN_URL} from 'hooks/useURL';
import {CRM_ROLE_TO_OCR_ROLE_MAPPING, getDefaultView} from 'helper/routers';
import useRequest from 'hooks/useRequest';
import useQuery from 'hooks/useQuery';
import {isBlank, parseLoginQueryString} from 'helper/utils';


const Login = () => {
  const {authUserInfo, setAuthUserInfo} = useAuthentication();
  const query = useQuery();
  const {userName, userTypeCode, token} = parseLoginQueryString(query);

  const history = useHistory();

  const [{status, response}, makeRequest, {SUCCESS, ERROR}] = useRequest(
    LOGIN_URL,
    {
      verb: 'post',
      params: {
        userName,
        userTypeCode,
        token,
      },
    },
  );

  useEffect(() => {
    if ((!authUserInfo || !authUserInfo.isTestLogin) &&
      (!isBlank(userName) && !isBlank(userTypeCode) && !isBlank(token))
    ) {
      makeRequest();
    }
  }, [authUserInfo]);

  useEffect(() => {
    if (status === SUCCESS && response && response.data) {
      const role = CRM_ROLE_TO_OCR_ROLE_MAPPING[response.data.userTypeCode];
      if (!role) {
        setAuthUserInfo({
          isTestLogin: true,
        });
        toast.error((response.data && response.data.detail) ||
          'Invalid Role. Please ensure you are assigned to appropriate role associated with the sytem.');
        history.push('/failure');
      } else {
        toast.success(`Welcome ${response.data.fullname}`);
        setAuthUserInfo({
          isTestLogin: true,
          userId: response.data.userId,
          role: role,
          userName: response.data.username,
          fullName: response.data.fullname,
          email: response.data.username,
          token: response.data.token,
        });
        const oldPath = sessionStorage.getItem('oldPath');

        if (oldPath && oldPath !== '/') {
          history.push(oldPath);
          sessionStorage.setItem('oldPath', '');
        } else {
          const view = getDefaultView(role);
          history.push(view.link);
        }
      }
    }
    if (status === ERROR && response.data) {
      setAuthUserInfo({
        isTestLogin: true,
      });
      toast.error((response.data && response.data.detail) ||
        'Invalid User SSO Session. Please ensure you are logged into your SSO.');
      history.push('/failure');
    }
  }, [status, response]);

  if (!userName || !userTypeCode || !token) {
    history.push('/failure');
  }

  return (
    <Fragment />
  );
};
export default Login;
